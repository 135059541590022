import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Row, Col } from 'antd'
import { Modal } from 'react-bootstrap'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation } from '@apollo/react-hooks'

import { UPDATE_OPERATOR_STAUS } from '../../../api/mutation/updateOperatorStatus'
import { operatorStatusAction } from '../../../Store/Actions/Operator/index'
import { save } from '../../../utils/localStorage'

const Status = props => {
  const { logout } = useAuth0()
  const dispatch = useDispatch()
  let history = useHistory()

  const [status1, setStatus1] = useState('Toilet')
  const [status2, setStatus2] = useState('Break')
  const [status3, setStatus3] = useState('Logout')

  const { selectedOperator } = useSelector(state => state)

  const [postStatus] = useMutation(UPDATE_OPERATOR_STAUS)

  const submitStatus = async val => {
    // console.log(selectedOperator,'selectedOperatorselectedOperator');
    if (val === 'OFFLINE') {
      await localStorage.setItem('token', '')
      const token = localStorage.getItem('token')

      if (!token) {
        try {
          const response = await postStatus({
            variables: {
              data: {
                operatorId: selectedOperator?.id,
                status: val,
              },
            },
          })

          if (response?.data?.updateOperatorStatus) {
            save('pause', null)
            logout({
              returnTo: window.location.origin,
            })
            // onClose()
          } else {
            submitStatus('OFFLINE')
          }
        } catch (err) {
          console.log('status: ', err)
        }
        setStatus3('Please wait!')
      } else {
        window.location.reload()
      }
    } else {
      if (val === 'PAUSE') {
        try {
          postStatus({
            variables: {
              data: {
                operatorId: selectedOperator?.id,
                status: val,
              },
            },
          })
        } catch (err) {
          console.log('status: ', err)
        }

        setTimeout(() => {
          dispatch(operatorStatusAction(val))
          save('opst', val)
          history.push('/lock-screen')
        }, 1000)
      } else {
        if (val === 'BE_RIGHT_BACK') {
          setStatus1('Please wait!')
        } else {
          setStatus2('Please wait!')
        }

        try {
          postStatus({
            variables: {
              data: {
                operatorId: selectedOperator?.id,
                status: val,
              },
            },
          })
        } catch (err) {
          console.log('status: ', err)
        }

        setTimeout(() => {
          dispatch(operatorStatusAction(val))
          save('opst', val)
          history.push('/lock-screen')
        }, 1000)
      }
    }
  }

  return (
    <>
       <Modal show={props.show} onHide={() => props.setShow(false)} centered>
        <Modal.Body style={{paddingLeft: 50}}>
          <h2 className="text-center pb-3 text-secondary">Update status?</h2>

        <Row  >
          <Col lg={3} style={{paddingRight:'6.5rem'}}>
              <a href="#"  className="setTextHover" onClick={() => submitStatus('PAUSE')}>
                <div className="setOptionStatus" >
                  {/* <i className={'fa fa-pause TextOptionStatus' } /> */}
                  <img src="/icon/ic_status_01.png" className={'TextOptionStatus' }/>
                </div>
                {/* <img src="/icon/ic_status_01.png"/> */}
                <div className="text-center setOptionStatusText">
                  <b>Pause</b>
                </div>
              </a>
            </Col>
            <Col lg={3} style={{paddingRight:'6.5rem'}}>
              <a href="#" className="setTextHover" onClick={() => submitStatus('BE_RIGHT_BACK')}>
                <div className="setOptionStatus"  >
                <img src="/icon/ic_status_02.png" className={'TextOptionStatus' }/>
                  {/* <i className={'fa fa-male TextOptionStatus' } style={{fontSize:25}} > |</i><i className={'fa fa-female TextOptionStatus' } style={{fontSize:25}}></i> */}
                </div>
                <div className="text-center setOptionStatusText" style={{paddingTop:1}}>
                    <b>{status1}</b>
                </div>
              </a>
            </Col>
            <Col lg={3} style={{paddingRight:'6.5rem'}}>
              <a href="#" className="setTextHover" onClick={() => submitStatus('BREAK')}>
                <div className="setOptionStatus" >
                <img src="/icon/ic_status_03.png" className={'TextOptionStatus' }/>
                  {/* <i className={'fa fa-cutlery TextOptionStatus' }/> */}
                </div>
                <div className="text-center setOptionStatusText">
                      <b>{status2}</b>
                </div>
              </a>
            </Col>
            <Col lg={3} style={{paddingRight:'6.5rem'}}>
              <a href="#" className="setTextHover"  onClick={() => submitStatus('OFFLINE')}>
                <div className="setOptionStatus" >
                <img src="/icon/ic_status_04.png" className={'TextOptionStatus' }/>
                  {/* <i className={'fa fa-sign-out TextOptionStatus' }/> */}
                </div>
                <div className="text-center setOptionStatusText">
                      <b>{status3}</b>
                </div>
              </a>
            </Col>
          </Row>

        </Modal.Body>
      </Modal>
    </>
  )
}

export default Status
