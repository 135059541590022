export const memberIdAction = id => ({
  type: 'MEMBER_ID',
  id,
})

export const myCase = list => ({
  type: 'MY_CASE',
  list,
})
export const memberNewListAction = list => ({
  type: 'MEMBER_NEW_LIST',
  list,
})

export const memberListDoneAction = list => ({
  type: 'MEMBER_LIST_DONE',
  list,
})

export const selectedMemberToChatAction = chat => ({
  type: 'SELECTED_CHAT',
  chat,
})

export const selectedProfileAction = profile => ({
  type: 'PROFILE_NAME',
  profile,
})
