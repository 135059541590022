import React, { useState,useEffect } from 'react'
import {  useSelector } from 'react-redux'
import moment from 'moment'
import { Row, Col } from 'react-bootstrap'
import { Table, Tag } from 'antd'
import { useQuery } from '@apollo/client'

import { OPERATORS,ConditionOperatorCount,Operator } from '../../../../api/query/operators'

function ChannelTable(props) {
  const [state] = useState({
    top: 'bottomCenter',
    bottom: 'bottomCenter',
  })
  const {selectedOperator } = useSelector(state => state)
  const [data, setData] = useState([])
  const { getOperator, data: Operators, loading: loadingOps } = Operator();
  const { getConditionOperatorCount, data: ConditionOperatorCounts, loading: loadingConditionOperatorCount } = ConditionOperatorCount();
  const [pageDetail, setPage] = useState({
    pageNumber: 1,
    totalPage: 0,
  })

  // const { loading } = useQuery(OPERATORS, {
  //   fetchPolicy: 'no-cache',
  //   variables: {
  //     take: 5,
  //     skip: 0,
  //   },
  //   onCompleted: data => {
  //     const res = data.operators
  //     setData(res)
  //   },
  // })

  const columns = [
    // {
    //   title: 'No.',
    //   key: 'key',
    //   render: (value, row, index) => <span>{index + 1}</span>,
    //   align: 'center',
    // },
    {
      title: 'No.',
      key: 'key',
      align: 'center',
      render: (value, row, index) => (
        <span>
          {(pageDetail.pageNumber == 1
            ? pageDetail.pageNumber * 1 - 1
            : (pageDetail.pageNumber * 1 - 1) * 10) +
            (index + 1)}
        </span>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Organization',
      dataIndex: 'contact',
      key: 'contact',
      align: 'center',
      render: contact => <span> {contact.organisation.name}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      render: isActive => {
        let color = isActive ? 'green' : 'yellow'
        let status = isActive ? 'Active' : 'Deactive'
        return <Tag color={color}>{status}</Tag>
      },
    },
    {
      title: 'Points',
      dataIndex: 'points',
      key: 'points',
      align: 'center',
    },
    {
      title: 'Last update',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      responsive: ['sm'],
      align: 'center',
      render: updatedAt => {
        return <span>{moment(updatedAt).format('DD/MM/YYYY')}</span>
      },
    },
    {
      title: 'Actions',
      dataIndex: 'acrions',
      align: 'center',
      render: (record, index) => {
        return (
          <Row noGutters>
            <Col onClick={() => props.formModalToggle('View', index, 'operator')}>
              <i className="ti ti-eye pointer"></i>
            </Col>
            <Col onClick={() => props.formModalToggle('Edit', index, 'operator')}>
              <i className="ti ti-pencil-alt pointer"></i>
            </Col>
          </Row>
        )
      },
    },
  ]

  useEffect(() => {
    if (props.activeTab == '3') {
      // console.log('activeTab',props,selectedOperator);
      getData();
  
    }
  }, [props.activeTab])

  const getData = () =>{

    getOperator({
      variables : {
        where : {
          contact : {
            // refCode: {equals: ((selectedOperator?.role == 'SUPERADMIN') ? null : selectedOperator?.contact.organisationRefCode)}
            ...((selectedOperator?.role != 'SUPERADMIN') && {organisationRefCode: {equals: selectedOperator?.contact.organisationRefCode}})
            // organisationRefCode: {equals: selectedOperator?.contact.organisationRefCode}
          }

        },
        take: 10,
        skip:pageDetail.pageNumber == 1
            ? pageDetail.pageNumber * 1 - 1
            : (pageDetail.pageNumber * 1 - 1) * 10,
      }

    });

    getConditionOperatorCount({
      variables : {
        where : {
          contact : {
            ...((selectedOperator?.role != 'SUPERADMIN') && {organisationRefCode: {equals: selectedOperator?.contact.organisationRefCode}})
          }
        },
      }
      
      

    });

  }
  const handleTableChange = e => {
    var set = pageDetail;
    set.pageNumber = e.current;
    // set.totalPage =((props.type == 'LogActivityDetail') ? activitylogcount?.operatorActivityCount?.total : assignlog?.operatorMembers.length);
    setPage(set);
    getData();
    
// console.log('Operators?.operators',Operators?.operators);
      
  }


  return (
    <Table
      columns={columns}
      dataSource={Operators?.operators}
      bordered
      loading={loadingOps}
      pagination={{ position: [state.top, state.bottom], pageSize: 10,current: pageDetail.pageNumber , total: ConditionOperatorCounts?.operatorCount.total }}
      rowKey='id'
      onChange={e => handleTableChange(e)}
    />
  )
}

export default ChannelTable
