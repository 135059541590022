import React from 'react'
import { Table, Pagination } from 'antd'
import { Badge } from 'react-bootstrap'
import moment from 'moment'
import 'moment/locale/th'

moment.locale('th')

function DepositTable(props) {
  const columns = [
    {
      title: '#',
      key: 'key',
      render: (value, row, index) => {
        return <span>{index + 1 + props.pageSize}</span>
      },
    },
    {
      title: 'BetAt',
      dataIndex: 'betAt',
      key: 'betAt',
      sorter: true,
      width: '104px',
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.betAt) - new Date(b.betAt),
      render: betAt => {
        return (
          <p className="mb-0">
            <span>{moment(betAt).format('ll')}</span> <br />
            <span className="text-secondary">{moment(betAt).format('LTS')} น.</span>
          </p>
        )
      },
    },
    {
      title: 'Provider/Game',
      dataIndex: 'providerShortName',
      key: 'providerShortName',
      width: '30%',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.providerShortName.length - b.providerShortName.length,
      render: (value, row, index) => {
        return (
          <p>
            <Badge variant="primary">{row.providerShortName}</Badge>
            {' : '}
            <Badge variant="info">{row.gameType}</Badge>
          </p>
        )
      },
    },
    {
      title: 'Bet',
      dataIndex: 'stake',
      key: 'stake',
      sorter: true,
      sorter: (a, b) => a.stake - b.stake,
      align: 'right',
      render: stake => {
        return <Badge variant="success">{Number(stake).toLocaleString('th-TH', 'decimal')}</Badge>
      },
    },
    {
      title: 'W/L',
      dataIndex: 'winLoss',
      key: 'winLoss',
      sorter: true,
      sorter: (a, b) => a.winLoss - b.winLoss,
      align: 'right',
      render: winLoss => {
        return (
          <>
            {winLoss ? (
              <Badge variant={winLoss > 0 ? 'success' : winLoss === 0 ? 'secondary' : 'danger'}>
                {Number(winLoss).toLocaleString('th-TH', 'decimal')}
              </Badge>
            ) : (
              <Badge variant="secondary">
                {Number(winLoss).toLocaleString('th-TH', 'decimal')}
              </Badge>
            )}
          </>
        )
      },
    },
  ]

  return (
    <>
      <Table
        dataSource={props.turnOverData}
        columns={columns}
        size="small"
        loading={props.loadTurnOver}
        pagination={false}
        // pagination={{ position: [state.top, state.bottom], pageSize: 5 }}
      />

      <br />

      {props.turnOverData.length > 0 && (
        <Pagination
          className="mb-2"
          showSizeChanger
          onChange={props.handleTake}
          defaultCurrent={1}
          defaultPageSize={100}
          size="small"
          total={props.count}
          pageSizeOptions={['5', '10', '100']}
        />
      )}
    </>
  )
}

export default DepositTable
