import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Button, Col } from 'react-bootstrap'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'

import { ORGANISATIONS,Organisations } from '../../../../api/query/organisations'

import { CREATE_OPERATOR, UPDATE_ONE_OPERATOR } from '../../../../api/mutation/operator'
import { organisationListAction } from '../../../../Store/Actions/Organisation'

function FormChannel(props) {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [validated, setValidated] = useState(false)

  const [organization, setOrganization] = useState(null)
  const [account, setAccount] = useState(null)
  const [password, setPassword] = useState('')
  const [status, setStatus] = useState(true)
  const [firstname, setFirstname] = useState('')
  const [nickname, setNickname] = useState('')
  const [lastname, setLastname] = useState('')

  const [mobile, setMobile] = useState('')
  const [line, setLine] = useState('')
  const [email, setEmail] = useState('')

  const [role, setRole] = useState('ADMIN')

  const { selectedOrganisation, editOperatorReducer,selectedOperator } = useSelector(state => state)
  const { getConditionOrganisation, data: ConditionOrganisation } = Organisations();


const [validateList] = useState(['organization','statusUser','account','Password'])
const [validateError, setValidateError] = useState([])
  useEffect(() => {
    if (editOperatorReducer) {
      if (editOperatorReducer?.type === 'edit') {
        // console.log('editOperatorReducer',editOperatorReducer.data.contact);
        
        const res = editOperatorReducer.data;
        setOrganization(res.contact.organisation.id)
        setAccount(res.username)
        setStatus(res.isActive)
        setFirstname(res.contact.firstName)
        setNickname(res.contact.nickname)
        setLastname(res.contact.lastName)
        setMobile(res.contact.mobile)
        setLine(res.contact.lineID)
        setEmail(res.contact.email)
        setRole(res.role)
      }
    }
 
    // console.log('editOperatorReducer',editOperatorReducer);
    // setValidateError([]);
    return () => {
      setOrganization(null)
      setAccount(null)
      setFirstname('')
      setNickname('')
      setLastname('')
      setMobile('')
      setLine('')
      setEmail('')
      setPassword('');
      setRole('ADMIN')
    }
  }, [editOperatorReducer])

  useEffect(() => {
    if (props.formTempModalOpen && editOperatorReducer?.type !== 'view' && props.activeTab === '3') {
      console.log('editOperatorReducer formTempModalOpen',(props.formTempModalOpen && editOperatorReducer?.type !== 'view' && props.activeTab === '3'));

      getConditionOrganisation({
        variables : {
          where : {
            ...((selectedOperator?.role != 'SUPERADMIN') && {refCode: {equals: selectedOperator?.contact.organisationRefCode}})
          },
        }
      });

      setValidateError([]);
      setValidated(false);
      if (!editOperatorReducer) {
        setOrganization(null)
        setAccount(null)
        setFirstname('')
        setNickname('')
        setLastname('')
        setMobile('')
        setLine('')
        setEmail('')
        setPassword('');
        setRole('ADMIN')
      }
     
      // dispatch(organisationListAction(res))
      // setData();
    }
  },[(props.formTempModalOpen && editOperatorReducer?.type !== 'view' && props.activeTab === '3')])

  // const setData = () => {
  //   console.log('ADMIN 222',selectedOperator,ConditionOrganisation);
  // };

  // useQuery(ORGANISATIONS, {
  //   fetchPolicy: 'no-cache',
  //   onCompleted: data => {
  //     const res = data.organisations
  //     dispatch(organisationListAction(res))
  //   },
  // })

  

  const [createOperator] = useMutation(CREATE_OPERATOR, {
    onCompleted: data => {
      const res = data.createOperator
      setIsLoading(false)
      if (res?.success) {
        Swal.fire({
          icon: 'success',
          title: res?.success.message,
        })
        setValidated(false)
        props.formModalToggle()
      } else if (res?.error) {
        Swal.fire({
          icon: 'error',
          title: res?.error?.message,
        })
        var error = [];
        if (res?.error?.message == 'The user already exists.') {
          error.push('account');
        }
        setValidateError(error);
      }
    },
  })



  const handleSubmit = event => {
    setIsLoading(true)
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()

        
      var error = [];
      
      validateList.forEach(element => {
        if (document.getElementById(element).value == '') {
          if (editOperatorReducer?.type !== 'edit') {
            error.push(element);
          }
          // error.push(element);
        }
      });
      setValidateError(error);
      console.log('ValidateError',error,props);
      setIsLoading(false)
    } else {
      event.preventDefault()
      event.stopPropagation()
      setValidated(true)
      const data = {
        orgId: Number(organization),
        username: account,
        password: password,
        status: 'OFFLINE',
        firstName: firstname,
        lastName: lastname,
        lineID: line,
        nickname: nickname,
        email: email,
        role: role,
        mobile:mobile,
        isActive:(status == "true" ? true : false)
      }

      try {
        createOperator({
          variables: {
            data: data,
          },
        })
      } catch (error) {
        console.log('error: ', error)
      }
    }
  }

  const [updateOperator] = useMutation(UPDATE_ONE_OPERATOR, {
    onCompleted: () => {
      setIsLoading(false)
      Swal.fire({
        icon: 'success',
        title: 'Update operator done.',
      })
      setValidated(false)
      props.formModalToggle()
    },
  })

  const handleUpdate = event => {
    event.preventDefault()
    event.stopPropagation()
    try {
      updateOperator({
        variables: {
          where: {
            id: Number(editOperatorReducer?.data?.id),
          },
          data: {
            ...(password && {password: { set: password }}),
            ...(status && {isActive: { set: (status == "true" ? true : false) }}),
            ...(role && {role:{ set:role }}),
            contact: {
              update: {
                ...(nickname && {nickname: { set: nickname }}),
                ...(firstname && {firstName: { set: firstname }}),
                ...(lastname && {lastName: { set: lastname }}),
                ...(mobile && {mobile: { set: mobile }}),
                ...(line && {lineID: { set: line }}),
              },
            },
          },
        },
      })
    } catch (err) {
      console.log('Err: ', err)
    }
  }

  return (
    <Modal
      isOpen={
        props.formTempModalOpen && editOperatorReducer?.type !== 'view' && props.activeTab === '3'
      }
      toggle={() => props.formModalToggle('')}
      centered
      className="modal-dialog-zoom"
      size="xl"
    >
      <ModalHeader toggle={() => props.formModalToggle('')}>
        <i className="ti ti-write"></i>
        {props.formHeader} Operator
      </ModalHeader>

      <ModalBody>
        <Form
          noValidate
          validated={validated}
          id={'FormUserOperation'}
          onSubmit={!editOperatorReducer ? handleSubmit : handleUpdate}
        >
          <Form.Row>
            {editOperatorReducer?.refCode && (
              <Form.Group as={Col} lg={12} controlId="refCode">
                <Form.Label>RefCode</Form.Label>
                <Form.Control
                  required
                  type="text"
                  disabled
                  defaultValue={editOperatorReducer?.refCode}
                  
                />
              </Form.Group>
            )}

            <Form.Group as={Col} lg={12} controlId="organization">
              <Form.Label>Organisation <span className="text-danger">*</span></Form.Label>
              <Form.Control
                disabled={editOperatorReducer?.type === 'edit' ? true : false}
                required
                as="select"
                defaultValue={organization ? organization : ''}
                onChange={e => setOrganization(e.target.value)}
                isInvalid={validateError.filter(e => e === 'organization').length == 1}
              >
                <option  value={''}>Choose...</option>
                {ConditionOrganisation?.organisations.map((ch, i) => (
                  <option value={ch.id} key={i}>
                    {ch.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {/* isInvalid={(validateError.filter(e => e === 'organization').length == 1)} */}
            <Form.Group as={Col} lg={4} controlId="account">
              <Form.Label>Account <span className="text-danger">*</span></Form.Label>
              <Form.Control
                disabled={editOperatorReducer?.type === 'edit' ? true : false}
                required
                type="text"
                placeholder="Enter account"
                defaultValue={account}
                onChange={e => setAccount(e.target.value)}
                isValid={editOperatorReducer?.type === 'edit' ? false : account && account >= 6}
                isInvalid={editOperatorReducer?.type === 'edit' ? false : (validateError.filter(e => e === 'account').length == 1) && account < 6}
              />
              {/* {console.log(validateError.filter(e => e === 'account').length,(validateError.filter(e => e === 'account').length == 1))} */}
              <Form.Control.Feedback type="invalid">
                กรุณาระบุ account ไม่น้อยกว่า 6 ตัวอักษร
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={4} controlId="Password">
              <Form.Label>Password <span className="text-danger">*</span></Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Enter password"
                defaultValue={password}
                onChange={e => setPassword(e.target.value)}
                isInvalid={validateError.filter(e => e === 'Password').length == 1}
              />
              <Form.Control.Feedback type="invalid">กรุณาระบุ password</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={2} controlId="statusUser">
              <Form.Label>Status <span className="text-danger">*</span></Form.Label>
              <Form.Control
                as="select"
                defaultValue={editOperatorReducer?.data ? status : ''}
                onChange={e => setStatus(e.target.value)}
                required
                // disabled={props.formHeader === 'View' ? true : false}
                isInvalid={validateError.filter(e => e === 'statusUser').length == 1}
              >
                <option  value={''}>Choose...</option>
                <option value={true}>Active</option>
                <option value={false}>Deactive</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} lg={2} controlId="role">
              <Form.Label>Role <span className="text-danger">*</span></Form.Label>
              <Form.Control
                as="select"
                defaultValue={editOperatorReducer?.data ? role : 'Role...'}
                onChange={e => setRole(e.target.value)}
                // disabled={props.formHeader === 'View' ? true : false}
              >
                {/* <option>Role...</option> */}
                <option value={'ADMIN'}>ADMIN</option>
                <option value={'STAFF'}>STAFF</option>
                <option value={'SUPERADMIN'} style={{display:(selectedOperator?.role != 'SUPERADMIN' ? 'none' : 'block')}}>SUPERADMIN</option>

              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} lg={4} controlId="firstname">
              <Form.Label>Firstname</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter firstname"
                defaultValue={firstname}
                onChange={e => setFirstname(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">กรุณาระบุ firstname</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={4} controlId="nickname">
              <Form.Label>Nickname</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter nickname"
                defaultValue={nickname}
                onChange={e => setNickname(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">กรุณาระบุ nickname</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={4} controlId="lastname">
              <Form.Label>Lastname</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter lastname"
                defaultValue={lastname}
                onChange={e => setLastname(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">กรุณาระบุ lastname</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={4} controlId="mobile">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter mobile"
                defaultValue={mobile}
                onChange={e => setMobile(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">กรุณาระบุ mobile</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={4} controlId="line">
              <Form.Label>Line</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter line"
                defaultValue={line}
                onChange={e => setLine(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">กรุณาระบุ line</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={4} controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter email"
                defaultValue={email}
                onChange={e => setEmail(e.target.value)}
                disabled={props.formHeader === 'View' ? true : false}
              />
              <Form.Control.Feedback type="invalid">กรุณาระบุ email</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Button
            variant={props.formHeader === 'View' ? 'secondary' : 'primary'}
            type="submit"
            block
          >
            {isLoading ? 'กำลังบันทึกข้อมูล...' : 'บันทึกข้อมูล'}
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default FormChannel
