export const organisationListReducer = (state = [], action) => {
  switch (action.type) {
    case 'ORGANISATION_LIST':
      return action.list
    default:
      return state
  }
}

export const editOrganisationReducer = (state = null, action) => {
  switch (action.type) {
    case 'ORGANISATION_EDIT':
      return action.edit
    default:
      return state
  }
}
