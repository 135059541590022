import { gql } from 'apollo-boost'
import { useLazyQuery } from '@apollo/client'

export const TEMPLATES = gql`
  query templates($where: TemplateWhereInput, $take: Int, $skip: Int) {
    templates (where:$where, take: $take, skip: $skip, orderBy: { updatedAt: desc })
  {
      id
      key
      refCode
      name
      channel {
        id
        refCode
        name
      }
      templateItems {
        id
        refCode
        tempId
        templateItemType
        body
        sequences
        createdAt
        updatedAt
        isActive
      }
      createdAt
      updatedAt
      isActive
    }
  }
`
export const TEMPLATE_COUNT = gql`
  query templateCount($where: TemplateWhereInput) {
    templateCount(where: $where)
}
`

export const Templates = () => {
  const [getConditionTemplates, { data, loading }] = useLazyQuery(
    TEMPLATES,
    {
      fetchPolicy: 'no-cache',
    },
  )
  return {
    getConditionTemplates,
    data,
    loading,
  }
}

export const ConditionTemplatesCount = () => {
  const [getConditionTemplatesCount, { data, loading }] = useLazyQuery(
    TEMPLATE_COUNT,
    {
      fetchPolicy: 'no-cache',
    },
  )
  return {
    getConditionTemplatesCount,
    data,
    loading,
  }
}