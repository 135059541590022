import styled from 'styled-components'
import { MEDIA_MEDIUM_UP } from './theme'

export const Box = styled.div`
  width: fit-content;
  background-color: ${props =>
    props.status === 'on' ? '#0d6efd' : props.status !== 'offline' ? '#ffc107' : '#6c757d'};
  color: ${props =>
    props.status === 'on' ? '#fff' : props.status !== 'offline' ? '#000' : '#fff'};
  display: ${props =>
    props.status === 'on' ? 'block' : props.status !== 'offline' ? 'block' : 'none'};
  padding: 2px 16px;
  border-radius: 6px;
  font-size: 0.7rem;
  white-space: nowrap;

  /* @media ${MEDIA_MEDIUM_UP} {
    position: absolute;
    bottom: 0px;
    right: 0;
    width: 100%;
    max-width: 840px;
    background-color: transparent;
    text-align: -webkit-left;
  } */
`
