import { gql } from 'apollo-boost'

export const ON_OPERATORS = gql`
  subscription onOperatorOnline($data: inputJoinOperatorOnline) {
    onOperatorOnline(data: $data) {
      id
      key
      username
      status
    }
  }
`
