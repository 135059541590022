import React from 'react'
import { useSelector } from 'react-redux'

import {
  Modal,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  ModalHeader,
} from 'reactstrap'
import { Button } from 'react-bootstrap'
import classnames from 'classnames'

import TemplateList from './settings/Template/TemplateTable.jsx'
import ChannelList from './settings/Channel/ChannelTable.jsx'
import UserList from './settings/User/UserListTable.jsx'
import Orgranization from './settings/Organisation'

function SettingsModal(props) {
  const mess = 'You don’t have permission for access!'
  const toggle = tab => {
    if (props.activeTab !== tab) props.setActiveTab(tab)
  }

  const { selectedUser,selectedOperator } = useSelector(state => state)

  const checkPermision = () => {
    // var check = selectedUser?.nickname.includes('admin')
    // console.log('checkPermision',selectedOperator);
    var check = selectedOperator?.role;
    if (check == 'ADMIN' || check == 'SUPERADMIN') {
      return true
    } else {
      return false
    }
  }

  return (
    <Modal
      isOpen={props.modal}
      toggle={props.toggle}
      centered
      className="modal-dialog-zoom"
      size="xl"
    >
      <ModalHeader toggle={props.toggle}>
        <i className="ti ti-settings"></i> Settings
      </ModalHeader>

      <ModalBody>
        <Button
          className="float-right"
          style={{ marginTop: 2 }}
          variant="outline-success"
          onClick={() =>
            props.formModalToggle(
              'New',
              null,
              props.activeTab === '1'
                ? 'template'
                : props.activeTab === '2'
                ? 'channel'
                : props.activeTab === '3'
                ? 'operator'
                : props.activeTab === '4' && 'organization',
            )
          }
        >
          เพิ่ม
        </Button>

        <Nav tabs>
          <NavItem>
            <NavLink
              href="#/"
              className={classnames({ active: props.activeTab === '1' })}
              onClick={() => {
                toggle('1')
              }}
            >
              Template
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              href="#/"
              className={classnames({ active: props.activeTab === '2' })}
              onClick={() => {
                toggle('2')
              }}
            >
              Channel
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              href="#/"
              className={classnames({ active: props.activeTab === '3' })}
              onClick={() => {
                toggle('3')
              }}
            >
              Usermanagement
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              href="#/"
              className={classnames({ active: props.activeTab === '4' })}
              onClick={() => {
                toggle('4')
              }}
            >
              Organization
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={props.activeTab}>
          <TabPane tabId="1">
            {checkPermision() ? (
              <TemplateList formModalToggle={props.formModalToggle} activeTab={props.activeTab}/>
            ) : (
              <h2 className="text-center text-secondary">{mess}</h2>
            )}
          </TabPane>

          <TabPane tabId="2">
            {checkPermision() ? (
              <ChannelList formModalToggle={props.formModalToggle} activeTab={props.activeTab}/>
            ) : (
              <h2 className="text-center text-secondary">{mess}</h2>
            )}
          </TabPane>

          <TabPane tabId="3">
            {checkPermision() ? (
              <UserList formModalToggle={props.formModalToggle} activeTab={props.activeTab}/>
            ) : (
              <h2 className="text-center text-secondary">{mess}</h2>
            )}
          </TabPane>

          <TabPane tabId="4">
            {checkPermision() ? (
              <Orgranization formModalToggle={props.formModalToggle} activeTab={props.activeTab}/>
            ) : (
              <h2 className="text-center text-secondary">{mess}</h2>
            )}
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  )
}

export default SettingsModal
