export const selectedChatAction = channel => ({
  type: 'SELECTED_CHANNEL',
  channel,
})

export const channelRefCodeAction = (refCode, id, name) => ({
  type: 'CHANNEL_REF_CODE',
  refCode,
  id,
  name,
})

export const editChannelAction = edit_channel => ({
  type: 'EDIT_CHANNEL',
  edit_channel,
})
