import React, { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery, useSubscription } from '@apollo/react-hooks'

import FormChannel from './Modals/settings/Channel/FormChannel.jsx'
import FormOperator from './Modals/settings/User/FormOperator.jsx'
import FormOrg from './Modals/settings/Organisation/FormOrg'
import FormTemplate from './Modals/settings/Template/FormTemplate.jsx'
import SettingsModal from './Modals/SettingsModal.jsx'
import Status from './Modals/Status'
import UserProfile from './Modals/settings/User/UserProfile'
import SlideBar from './Sidebars/SlideMenu'
import Online from './Sidebars/Online'

import { CHANNELS, ON_CHANNEL } from '../api/query/channeles'
import { GetOperator } from '../api/cli/Operator'
import { aboutAction } from '../Store/Actions/aboutAction'
import { editOperatorAction, operatorAction } from '../Store/Actions/Operator'
import { editOrganisationAction } from '../Store/Actions/Organisation'
import { mobileSidebarAction } from '../Store/Actions/Mobile'
import { profileAction } from '../Store/Actions/Profile/index'
import { selectedChatAction, editChannelAction } from '../Store/Actions/Channel'
import { selectedMemberToChatAction } from '../Store/Actions/Members/index'
import { sidebarAction } from '../Store/Actions/Sidebar'
import { templateItemAction } from '../Store/Actions/Template'
import { userAction } from '../Store/Actions/userAction'

function Navigation() {
  const dispatch = useDispatch()

  const { selectedChannel, selectedUser, selectedOperator } = useSelector(state => state)

  const [formHeader, setFormHeader] = useState('') // Modal-popup
  const [settingsModalOpen, setSettingsModalOpen] = useState(false) // Modal-popup
  const [formTempModalOpen, setFormTempModalOpen] = useState(false) // Modal-popup
  const [togle, setTogle] = useState(false) // Modal-popup
  const [show, setShow] = useState(false) // Modal-popup
  const [activeTab, setActiveTab] = useState('1')
  const [channelRooms, setChannelRooms] = useState('')

  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0()
  const { getOps, userId } = GetOperator()

  useEffect(() => {
    getOps({
      variables: {
        data: {
          sub: user?.sub,
        },
      },
    })
    dispatch(userAction(user))
  }, [!selectedUser])

  const settingsModalToggle = () => setSettingsModalOpen(!settingsModalOpen)

  const formModalToggle = (header, index, type) => {
    setFormTempModalOpen(!formTempModalOpen)
    setFormHeader(header)
    settingsModalToggle()
    if (type === 'template') {
      switch (header) {
        case 'New':
          dispatch(templateItemAction(null))
          break
        case 'View':
          dispatch(templateItemAction(index))
          break
        case 'Edit':
          dispatch(templateItemAction(index))
          break
        default:
          dispatch(templateItemAction(null))
          break
      }
    } else if (type === 'channel') {
      switch (header) {
        case 'New':
          dispatch(editChannelAction(null))
          break
        case 'View':
          dispatch(editChannelAction(index))
          break
        case 'Edit':
          dispatch(editChannelAction(index))
          break
        default:
          dispatch(editChannelAction(null))
          break
      }
    } else if (type === 'operator') {
      let data = {
        type: '',
        data: undefined,
      }
      switch (header) {
        case 'New':
          data.type = 'new'
          data.data = null
          dispatch(editOperatorAction(null))
          break
        case 'View':
          data.type = 'view'
          data.data = index
          dispatch(editOperatorAction(data))
          break
        case 'Edit':
          data.type = 'edit'
          data.data = index
          dispatch(editOperatorAction(data))
          break
        default:
          data.type = ''
          data.data = null
          dispatch(editOperatorAction(null))
          break
      }
    } else if (type === 'organization') {
      switch (header) {
        case 'New':
          dispatch(editOrganisationAction(null))
          break
        case 'View':
          dispatch(editOrganisationAction(index))
          break
        case 'Edit':
          dispatch(editOrganisationAction(index))
          break
        default:
          dispatch(editOrganisationAction(null))
          break
      }
    }
  }

  if (!isAuthenticated) {
    loginWithRedirect()
  }

  const { data } = useQuery(CHANNELS, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        organisationRefCode: {
          equals:
            selectedOperator?.role == 'SUPERADMIN'
              ? null
              : selectedOperator.contact.organisationRefCode,
        },
      },
    },
    onCompleted: res => {
      const channels = res.channels
      let rooms
      if (channels && channels.length > 0) {
        rooms = channels.map(channel => `channel_${channel.id}_for_${userId}`)
        channels.forEach(channel => {
          rooms.push(`channel_${channel.id}`)
        })
        setChannelRooms(rooms)
      }
    },
  })

  const { data: onChannel } = useSubscription(ON_CHANNEL, {
    fetchPolicy: 'network-only',
    context: {
      headers: `Bearer ${localStorage.getItem('token')}`,
      operaterId: '2',
    },
    onError: err => {
      console.log('Err=> ON_CHANNEL: ', err)
    },
    variables: {
      data: {
        channelRooms,
      },
    },
  })

  const setData = async () => {
    const res = await onChannel?.onChannel
    const val = await selectedChannel.filter(e => e.id !== res.id)
    const arr = []
    for (let i = 0; i < val.length; i++) {
      const obj = val[i]
      arr.push(Object.assign({}, obj))
    }
    arr.push(Object.assign({}, res))
    dispatch(selectedChatAction(arr))
  }

  useEffect(() => {
    if (onChannel) {
      setData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChannel])

  useEffect(() => {
    if (data) {
      const res = data?.channels
      dispatch(selectedChatAction(res))
      if (res.length != 0) {
        linkDispatch('Chats', 'Chats', res[0])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const linkDispatch = (name, chanel, item) => {
    // e.preventDefault()
    dispatch(sidebarAction(name, chanel))
    dispatch(mobileSidebarAction(true))
    dispatch(selectedMemberToChatAction(null))
    dispatch(aboutAction(null))
    dispatch(profileAction(false))
  }

  const showModalMenu = (name) => {
    console.log(name);
    if (name == 'setting') {
      settingsModalToggle();
    }else{
      setShow(!show);
    }
  }

  return (
    <nav className="navigation">
      <Status show={show} setShow={setShow} />

      <SettingsModal
        modal={settingsModalOpen}
        toggle={settingsModalToggle}
        formModalToggle={formModalToggle}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      <FormTemplate
        activeTab={activeTab}
        formHeader={formHeader}
        formModalToggle={formModalToggle}
        formTempModalOpen={formTempModalOpen}
      />

      <FormChannel
        activeTab={activeTab}
        formHeader={formHeader}
        formModalToggle={formModalToggle}
        formTempModalOpen={formTempModalOpen}
      />

      <FormOperator
        activeTab={activeTab}
        formHeader={formHeader}
        formModalToggle={formModalToggle}
        formTempModalOpen={formTempModalOpen}
      />

      <UserProfile
        activeTab={activeTab}
        formHeader={formHeader}
        formModalToggle={formModalToggle}
        formTempModalOpen={formTempModalOpen}
      />

      <FormOrg
        activeTab={activeTab}
        formHeader={formHeader}
        formModalToggle={formModalToggle}
        formTempModalOpen={formTempModalOpen}
      />

      <div className="nav-group  ShadowBorder px-2 w-100">
        <div className="d-flex justify-content-around">
          <h1 className="fs1-4 pt-2 text-light">Chat Support</h1>
          <Online />
        </div>

        <h1 className="fs1-4 pt-2 text-light">
          <i className="fa fa-user-circle-o mr-2" aria-hidden="true"></i>
          {selectedOperator.username.toUpperCase()}{' '}
          <b className="fs0-8 pb-3">({selectedOperator.role.toUpperCase()})</b>
          <i
            className={`mx-3 fa fa-${togle ? 'times' : 'bars'}`}
            onClick={() => setTogle(!togle)}
            aria-hidden="true"
          ></i>
        </h1>
      </div>

      <SlideBar togle={togle} showModalMenu={showModalMenu} />
    </nav>
  )
}

export default Navigation
