import React from 'react'
import { Button } from 'react-bootstrap'
import { Row, Col } from 'antd'
import DatePicker, { registerLocale } from 'react-datepicker'
import { th } from 'date-fns/locale'
import moment from 'moment'

registerLocale('th', th)

function FormSearch(props) {
  const reset = () => {
    props.setStartDate(null)
    props.setEndDate(null)
  }

  return (
    <Row className="pr-3 pl-3 mb-2 mt-2" id="form-search" gutter={[8, 4]} style={{ width: '100%' }}>
      <Col sm={18} lg={18} className="mb-2">
        <DatePicker
          locale="th"
          selected={props.startDate}
          onChange={date => props.setStartDate(date)}
          disabledKeyboardNavigation
          dateFormat="dd MMMM yyyy"
          className="thCalendar border-0 mb-2"
          maxDate={moment().toDate()}
          minDate={moment('1940-01-02').toDate()}
          showYearDropdown={true}
          scrollableYearDropdown={true}
          yearDropdownItemNumber={80}
          placeholderText="Start date"
          block
        />

        <DatePicker
          locale="th"
          selected={props.endDate}
          onChange={date => props.setEndDate(date)}
          disabledKeyboardNavigation
          dateFormat="dd MMMM yyyy"
          className="thCalendar border-0"
          maxDate={moment().toDate()}
          minDate={moment('1940-01-02').toDate()}
          showYearDropdown={true}
          scrollableYearDropdown={true}
          yearDropdownItemNumber={80}
          placeholderText="End date"
          block
        />
      </Col>

      <Col sm={6} lg={6} className="mb-2">
        <Button
          variant="info"
          size="sm"
          block
          disabled={props.startDate && props.endDate ? false : true}
          onClick={props.nowSelectTab === 'turn-over' ? props.searchTurnOver : props.refreshDepWith}
        >
          <span>Search</span>
        </Button>
        <Button
          variant="outline-info"
          size="sm"
          block
          disabled={props.startDate && props.endDate ? false : true}
          onClick={reset}
        >
          <span>Reset</span>
        </Button>
      </Col>
    </Row>
  )
}

export default FormSearch
