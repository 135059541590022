import { gql } from 'apollo-boost'
import { useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/client'

import { memberNewListAction } from '../../Store/Actions/Members'
import { memberLoadingAction } from '../../Store/Actions/Loading/index'

const MEMBERS = gql`
  query members($where: MemberWhereInput, $take: Int) {
    members(
      where: $where
      take: $take
      # orderBy: { updatedAt: desc }
    ) {
      id
      assignType
      case
      displayName
      operatorId
      unreadChatMessage
      operator
      operatorsRead
      contact {
        id
        username
        firstName
        lastName
        mobile
        lineID
        email
        bankAccId
        organisation {
          id
          name
        }
      }
      note {
        topic
        detail
        colorCode
        createdAt
        updatedAt
        isActive
      }
      channelRefCode
      lineUserId
      profileImage
      contactId
      lastChatMessage
      lastChatTime
      unreadChatMessage
      channel {
        id
        name
      }
      groupRefCode
      createdAt
      updatedAt
      botStatus
      isActive
      block
    }
  }
`

export const GetMembers = () => {
  const dispatch = useDispatch()
  const [getMembers, { data }] = useLazyQuery(MEMBERS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const res = data.members
      dispatch(memberNewListAction(res))
      dispatch(memberLoadingAction(false))
    },
    onError: err => {
      // console.log('Members-Err: ', err);
    },
  })
  return { getMembers, data }
}
