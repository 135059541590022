import React, { useEffect, useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import { Form, Button, Col, Row } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { useQuery, useMutation } from '@apollo/react-hooks'

import classnames from 'classnames'
import Navigation from '../Navigation'
import { TEMPLATES, Templates } from '../../api/query/templates'
import { CHANNELS } from '../../api/query/channeles'
import { SENT_BROADCAST } from '../../api/mutation/sentBroadCast'
import { ConditionOperator, ConditionOperatorCount } from '../../api/query/operators'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { Table, Tag } from 'antd'
import Detail from './Detail'
import { useSelector } from 'react-redux'

function Management() {
  const {
    getConditionOperator,
    data: ConditionOperatorDetail,
    loading: loadingConditionOperator,
  } = ConditionOperator()
  const { getConditionOperatorCount, data: ConditionOperatorCountDetail } = ConditionOperatorCount()

  const { selectedOperator } = useSelector(state => state)

  console.log('selectedOperator: ', selectedOperator)

  const [activeTab, setActiveTab] = useState('')
  const [channelId, setChannel] = useState('')
  const [templateId, setTemplate] = useState('')
  const [validated, setValidated] = useState([])

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [pageDetail, setPage] = useState({
    pageNumber: 1,
    totalPage: 0,
    searchText: '',
    role: '',
    status: '',
    startDate: new Date(),
    endDate: new Date(),
    pageName: 'LogActivity', //Detail
  })
  const [Details, setDetails] = useState({
    operators: null,
    option: null,
  })

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const [postBroadcast, { loading: loadingPostBroadcast }] = useMutation(SENT_BROADCAST, {
    onCompleted: data => {
      if (data.sendBroadCast === null) {
        var res = []
        if (channelId === '') {
          res.push('channelId')
        }
        if (templateId === '') {
          res.push('templateId')
        }
        setValidated(res)
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Success',
        })
        resetData()
      }
    },
  })

  const addBroadcast = () => {
    postBroadcast({
      variables: {
        data: {
          channelId: channelId * 1,
          templateId: templateId * 1,
        },
      },
    })
  }

  const resetData = () => {
    switch (activeTab) {
      case '1':
        setChannel('')
        setTemplate('')
        setValidated([])
        document.getElementsByTagName('form')[0].reset()
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (activeTab === '') {
      setActiveTab('1')
      handleTableChange({ current: 1, total: 29, pageSize: 10 })
    }
  }, [])

  const setData = () => {
    var set = {}
    if (endDate !== null && startDate !== null) {
      set['updatedAt'] = {
        gte: startDate,
        lte: endDate,
      }
    } else {
      if (endDate === null && startDate !== null) {
        setEndDate(startDate)
        set['updatedAt'] = {
          gte: startDate,
          lte: startDate,
        }
      }
    }
    if (pageDetail.searchText !== '') {
      set['username'] = { contains: pageDetail.searchText }
    }

    if (pageDetail.status !== '') {
      set['status'] = { equals: pageDetail.status }
    }

    if (pageDetail.role !== '') {
      set['role'] = { equals: pageDetail.role }
    }
    return set
  }

  const setSearch = e => {
    var set = pageDetail
    if (e?.target.id === 'btnSearch') {
      handleTableChange({
        current: 1,
        total: ConditionOperatorCountDetail?.operatorCount?.total,
        pageSize: 10,
      })
    } else {
      set[e.target.id] = e.target.value
    }

    setPage(set)
  }

  const columns = () => {
    switch (activeTab) {
      case '2':
        return [
          {
            title: 'No.',
            key: 'key',
            align: 'center',
            render: (value, row, index) => (
              <span>
                {(pageDetail.pageNumber === 1
                  ? pageDetail.pageNumber * 1 - 1
                  : (pageDetail.pageNumber * 1 - 1) * 10) +
                  (index + 1)}
              </span>
            ),
          },
          {
            title: 'Admin',
            dataIndex: 'username',
          },
          {
            title: 'Organisation',
            render: (value, row, index) => <span>{value.contact?.organisation?.name}</span>,
          },
          {
            title: 'Role',
            render: (value, row, index) => <span>{value.role}</span>,
          },
          {
            title: 'Assign',
            render: (value, row, index) => <span>{value.members.length}</span>,
          },
          {
            title: 'Point',
            render: (value, row, index) => <span>{value.points}</span>,
          },
          {
            title: 'Status Update',
            render: (value, row, index) => (
              <Tag color={value.status === 'ONLINE' ? 'green' : 'yellow'}>{value.status}</Tag>
            ),
          },
          {
            title: 'Last Status Update',
            render: (value, row, index) => (
              <span> {moment(value.updatedAt).format('DD/MM/YYYY, HH:mm:ss')}</span>
            ),
          },
          {
            title: 'Activity',
            align: 'center',
            render: (record, index) => {
              return (
                <Row onClick={e => ChangePage('LogActivityDetail', record)}>
                  <Col>
                    <i className="ti ti-eye pointer"></i>
                  </Col>
                </Row>
              )
            },
          },
          {
            title: 'Log Assign',
            align: 'center',
            render: (record, index) => {
              return (
                <Row>
                  <Col onClick={e => ChangePage('LogAssignDetail', record)}>
                    <i className="ti ti-eye pointer"></i>
                  </Col>
                </Row>
              )
            },
          },
        ]
      default:
        break
    }
  }

  const { data: channel } = useQuery(CHANNELS, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        // ...((selectedOperator?.role == 'SUPERADMIN') && { organisationRefCode: { "equals": selectedOperator.contact.organisationRefCode }}),
        ...(pageDetail.role != 'SUPERADMIN' && {
          organisationRefCode: { equals: selectedOperator.contact.organisationRefCode },
        }),
      },
    },
    onError: err => {
      Swal.fire('Cannot delete is CHANNELS!' + err, '', 'error')
    },
    onCompleted: data => {
      var set = Details
      if (set.option) {
        set.option.channels = data.channels
      } else {
        set.option = data.channels
      }
      setDetails(set)
    },
  })

  const { data: templates } = useQuery(TEMPLATES, {
    fetchPolicy: 'no-cache',
    onError: err => {
      Swal.fire('Cannot delete is template!' + err, '', 'error')
    },
    variables: {
      take: null,
      skip: 0,
    },
  })

  const handleTableChange = e => {
    var set = pageDetail
    set.pageNumber = e.current
    setPage(set)
    getConditionOperator({
      variables: {
        where: setData(),
        take: 10,
        skip:
          pageDetail.pageNumber === 1
            ? pageDetail.pageNumber * 1 - 1
            : (pageDetail.pageNumber * 1 - 1) * 10,
      },
    })
    getConditionOperatorCount({
      variables: {
        where: setData(),
      },
    })
  }

  const ChangePage = (pagename, detail) => {
    var set = pageDetail
    set.pageName = pagename
    setPage(set)
    if (pagename !== 'LogActivity') {
      toggle('1')
      var d = Details
      d.operators = detail
      setDetails(d)
    } else {
      toggle('2')
    }
  }

  const PageManagement = props => {
    return (
      <div style={{ display: props.display ? 'block' : 'none' }}>
        <Nav tabs>
          <NavItem>
            <NavLink
              href="#/"
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1')
              }}
            >
              Broadcast
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="#/"
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggle('2')
              }}
            >
              Log Activity
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div style={{ marginTop: '3rem', marginLeft: '1rem' }}>
              <h3>
                <b> Create Broadcast</b>
              </h3>
            </div>

            <div>
              <Form id="broadcast">
                {/* <br /> */}
                <Form.Row style={{ marginTop: '3rem', marginLeft: '6rem' }}>
                  <Form.Group as={Col} lg={10} controlId="channelId">
                    <Form.Label>
                      <b>Select Channel</b>
                    </Form.Label>
                    <Form.Control
                      required
                      isInvalid={validated.filter(e => e === 'templateId').length === 1}
                      as="select"
                      defaultValue={channelId ? channelId : 'Choose...'}
                      // onChange={e => handleChangeTemplates(e.target.value)}
                    >
                      <option value="">Select Channel</option>
                      {channel?.channels.map((ch, i) => (
                        <option value={ch.id + '_and_' + ch.organisation.refCode} key={i}>
                          {ch.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>

                <Form.Row style={{ marginTop: '1rem', marginLeft: '6rem' }}>
                  <Form.Group as={Col} lg={10} controlId="templateId">
                    <Form.Label>
                      <b>Select Template</b>
                    </Form.Label>
                    <Form.Control
                      required
                      isInvalid={validated.filter(e => e === 'channelId').length === 1}
                      as="select"
                      defaultValue={templateId ? templateId : 'Choose...'}
                      onChange={e => setTemplate(e.target.value)}
                    >
                      <option value="">Select Template</option>
                      {templates?.templates.map((ch, i) => (
                        <option value={ch.id} key={i}>
                          {ch.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>

                <Form.Row style={{ marginTop: '1rem', marginLeft: '6rem' }}>
                  <Form.Group as={Col} lg={10} controlId="Channel">
                    <Row className="justify-content-md-center">
                      <Col xs lg="2">
                        <Button
                          variant="secondary"
                          block
                          size="lg"
                          onClick={resetData}
                          type="reface"
                        >
                          ยกเลิก
                        </Button>
                      </Col>
                      <Col xs lg="2">
                        <Button variant="success" block size="lg" onClick={addBroadcast}>
                          {loadingPostBroadcast ? 'Now loading...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group>
                </Form.Row>
              </Form>
            </div>
          </TabPane>

          <TabPane tabId="2">
            <Form style={{ marginTop: '2rem' }}>
              <Form.Row>
                <Form.Group as={Col} lg={2} controlId="searchText">
                  <Form.Control
                    required
                    type="text"
                    defaultValue={pageDetail?.searchText}
                    onChange={e => setSearch(e)}
                    placeholder="Search: Name"
                  />
                </Form.Group>
                <Form.Group as={Col} lg={2} controlId="role">
                  <Form.Control
                    as="select"
                    defaultValue={pageDetail?.role ? pageDetail?.role : 'Role...'}
                    onChange={e => setSearch(e)}
                  >
                    <option value={''}> All Role </option>
                    <option value={'ADMIN'}>ADMIN</option>
                    <option value={'STAFF'}>STAFF</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} lg={2} controlId="status">
                  <Form.Control
                    as="select"
                    defaultValue={pageDetail?.status ? pageDetail?.status : 'status...'}
                    onChange={e => setSearch(e)}
                  >
                    <option value={''}> All Status </option>
                    <option value={'ONLINE'}>ONLINE</option>
                    <option value={'OFFLINE'}>OFFLINE</option>
                    <option value={'BREAK'}>BREAK</option>
                    <option value={'BE_RIGHT_BACK'}>BE_RIGHT_BACK</option>
                    <option value={'PAUSE'}>PAUSE</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} lg={2} controlId="startDate">
                  <DatePicker
                    locale="th"
                    selected={startDate}
                    onChange={e => setStartDate(e)}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    placeholderText="Start Date"
                    block
                  />
                </Form.Group>
                <Form.Group as={Col} lg={2} controlId="status">
                  <DatePicker
                    locale="th"
                    selected={startDate > endDate ? startDate : endDate}
                    onChange={date => setEndDate(date)}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    minDate={startDate}
                    placeholderText="End date"
                    block
                  />
                </Form.Group>

                <Form.Group as={Col} lg={2}>
                  <Button variant="primarys" size="md" onClick={e => setSearch(e)} id="btnSearch">
                    Search
                  </Button>
                </Form.Group>
              </Form.Row>
            </Form>

            <Row>
              <Col>
                <Table
                  columns={columns()}
                  dataSource={ConditionOperatorDetail?.operators}
                  loading={loadingConditionOperator}
                  pagination={{
                    current: pageDetail.pageNumber,
                    total: ConditionOperatorCountDetail?.operatorCount?.total,
                    pageSize: 10,
                  }}
                  onChange={e => handleTableChange(e)}
                  key="id"
                />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    )
  }

  return (
    <>
    <Navigation />
    <div className="layout">

      <div className="content" style={{ overflowY: 'auto', display: 'table-column' ,background: 'white',}}>
        <div

          style={{
            width: '100%',
            background: 'white',
            // border: '1px solid white',
            // borderRadius: 30,
          }}
        >
          <div style={{ padding: '3rem' }}>
            <h3>
              <b>Management</b>
            </h3>
            {pageDetail.pageName === 'LogActivity' ? null : (
              <Row>
                <Col lg={12}>
                  <span className="pointer text-h" onClick={e => ChangePage('LogActivity')}>
                    {' '}
                    Log Activity{' '}
                  </span>{' '}
                  /{' '}
                  <span>
                    {' '}
                    {pageDetail.pageName === 'LogActivityDetail'
                      ? 'Activity Detail'
                      : 'Log Assign Detail'}{' '}
                  </span>
                </Col>
              </Row>
            )}
            <PageManagement display={pageDetail.pageName === 'LogActivity'} />
            <Detail
              type={pageDetail.pageName}
              display={pageDetail.pageName !== 'LogActivity'}
              data={Details}
            />
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Management
