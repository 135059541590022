import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Button, Col } from 'react-bootstrap'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'

import { ORGANISATIONS } from '../../../../api/query/organisations'
import { CREATE_ORGANISATION, UPDATE_ORGANISATION } from '../../../../api/mutation/organisation'
import { organisationListAction } from '../../../../Store/Actions/Organisation'

function FormChannel(props) {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [validated, setValidated] = useState(false)

  const [id, setId] = useState(null)
  const [refCode, setRefCode] = useState('')
  const [name, setName] = useState('')
  const [status, setStatus] = useState(true)
  const [description, setDescription] = useState('')

  const { editOrganisation } = useSelector(state => state)

  useEffect(() => {
    if (editOrganisation) {
      // console.log('editOrganisation',editOrganisation);
      const res = editOrganisation
      setRefCode(res.refCode)
      setName(res.name)
      setStatus(res.isActive)
      setDescription(res.description)
      setId(res.id)
    }
    return () => {
      setName('')
      setRefCode('')
      setStatus(true)
      setDescription('')
      setId(null)
    }
  }, [editOrganisation])

  const [getOrg] = useLazyQuery(ORGANISATIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const res = data.organisations
      dispatch(organisationListAction(res))
      setIsLoading(false)
      Swal.fire({
        icon: 'success',
        title: 'Success',
      })
      props.formModalToggle()
    },
  })

  const [createOrganisation] = useMutation(CREATE_ORGANISATION, {
    onCompleted: data => {
      const res = data.createOneOrganisation
      getOrg()
    },
    onError: err => {
      setIsLoading(false)
      const _name = String(err).includes('name')
      const _refCode = String(err).includes('refCode')
      if (_refCode) {
        Swal.fire({
          icon: 'error',
          title: 'Refcode: ' + refCode + ' มีอยู่ในระบบแล้ว กรุณาเปลี่ยนชื่อใหม่',
        })
        return
      } else if (_name) {
        Swal.fire({
          icon: 'error',
          title: 'ชื่อ: ' + name + ' มีอยู่ในระบบแล้ว กรุณาเปลี่ยนชื่อใหม่',
        })
        return
      }
    },
  })

  const handleSubmit = event => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      setValidated(false)
    } else {
      event.preventDefault()
      event.stopPropagation()
      setValidated(true)
      setIsLoading(true)

      const data = {
        refCode: refCode,
        name: name,
        description: description,
        isActive: status,
      }
      try {
        createOrganisation({
          variables: {
            data: data,
          },
        })
      } catch (error) {
        // console.log('error: ', error)
      }
    }
  }

  const [updateOrg] = useMutation(UPDATE_ORGANISATION, {
    onCompleted: data => {
      getOrg()
    },
  })

  const handleUpdate = event => {
    setIsLoading(true)

    event.preventDefault()
    event.stopPropagation()
    try {
      updateOrg({
        variables: {
          where: {
            id: Number(id),
          },
          data: {
            refCode: {
              set: refCode,
            },
            name: {
              set: name,
            },
            description: {
              set: description,
            },
            isActive: {
              set: status,
            },
          },
        },
      })
    } catch (err) {
      // console.log('Err: ', err)
    }
  }

  const handleStatus = event => {
    event.preventDefault()
    event.stopPropagation()
    switch (event.target.value) {
      case 'false':
        setStatus(false)
        break
      case 'true':
        setStatus(true)
        break
    }
  }

  return (
    <Modal
      isOpen={props.formTempModalOpen && props.activeTab === '4'}
      toggle={() => props.formModalToggle('')}
      centered
      className="modal-dialog-zoom"
      size="xl"
    >
      <ModalHeader toggle={() => props.formModalToggle('')}>
        <i className="ti ti-write"></i>
        {props.formHeader} Organization
      </ModalHeader>

      <ModalBody>
        <Form
          noValidate
          validated={validated}
          onSubmit={!editOrganisation ? handleSubmit : handleUpdate}
        >
          <Form.Row>
            <Form.Group as={Col} lg={4} controlId="refCode">
              <Form.Label>RefCode</Form.Label>
              <Form.Control
                required
                type="text"
                defaultValue={refCode}
                placeholder="Enter refcode"
                onChange={e => setRefCode(e.target.value)}
                disabled={props.formHeader === 'View' ? true : false}
                isValid={!!refCode && !!refCode.length > 5}
                isInvalid={refCode.length < 5}
              />
              <Form.Control.Feedback type="invalid">กรุณาระบุชื่อ RefCode</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={4} controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter channel name"
                defaultValue={name}
                onChange={e => setName(e.target.value)}
                disabled={props.formHeader === 'View' ? true : false}
                isValid={!!name && !!name.length > 5}
                isInvalid={name.length < 5}
              />
              <Form.Control.Feedback type="invalid">
                กรุณาระบุชื่อ Organisation อย่างน้อย 5 ตัวอักษร
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={4} controlId="status">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                defaultValue={status}
                onChange={handleStatus}
                disabled={props.formHeader === 'View' ? true : false}
              >
                <option value={true}>Active</option>
                <option value={false}>Deactive</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} lg={12} controlId="channelSecret">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                placeholder="Enter description"
                defaultValue={description}
                onChange={e => setDescription(e.target.value)}
                disabled={props.formHeader === 'View' ? true : false}
              />
              {/* <Form.Control.Feedback type="invalid">กรุณาระบุ channel secret</Form.Control.Feedback> */}
            </Form.Group>
          </Form.Row>

          <Button
            variant={props.formHeader === 'View' ? 'secondary' : 'primary'}
            type="submit"
            block
            disabled={props.formHeader === 'View' ? true : false}
          >
            {isLoading ? 'กำลังบันทึกข้อมูล...' : 'บันทึก Channel'}
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default FormChannel
