import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'

// import AutoQue from './AutoQue/index'
import NewChatAll from './NewChatAll'
import imgErr from '../../../utils/imgErr'

import { BtnTab } from '../../../assets/styles/btn'
// import { GetTypeTemplates } from '../../../api/cli/Templates'
import { GetMembers } from '../../../api/cli/Members'
import { MEMBERS } from '../../../api/query/members'
import { READ_MESSAGE } from '../../../api/mutation/readMessage'
import { aboutAction } from '../../../Store/Actions/aboutAction'
import { channelRefCodeAction } from '../../../Store/Actions/Channel/'
import { loadState, saveState } from '../../../utils/localStorage'
import {
  memberListDoneAction,
  memberIdAction,
  selectedMemberToChatAction,
} from '../../../Store/Actions/Members'
import { mobileProfileAction } from '../../../Store/Actions/mobileProfileAction'
// import { mobileSidebarAction } from '../../../Store/Actions/Mobile'
import { oldRead, checkScrollAction } from '../../../Store/Actions/Message'
import { profileAction } from '../../../Store/Actions/Profile'
import { sidebarAction } from '../../../Store/Actions/Sidebar'

import { togleTemplateAction } from '../../../Store/Actions/Template/index'

function MemberListChats() {
  const dispatch = useDispatch()
  let history = useHistory()
  const inputRef = useRef()

  const { getMembers } = GetMembers()

  const {
    myCaseList,
    memberNewList,
    selectedOldRead,
    selectedChannelRefCode,
    selectedChat,
    selectedChannel,
  } = useSelector(state => state)

  const [search, setSearch] = useState('')
  const [searchChannel, setSearchChannel] = useState('')
  const [operatorNull, setOperatorNull] = useState(false)
  const [tab, setTab] = useState(1)
  const [data, setData] = useState([])

  const togleTab = (t, n) => {
    dispatch(sidebarAction('Chats', ''))
    dispatch(selectedMemberToChatAction(null))
    dispatch(profileAction(false))
    dispatch(mobileProfileAction(false))
    setTab(t)
    setOperatorNull(n)
    if (t === 1) {
      history.push({
        pathname: '',
        search: '?chats=active',
      })
    } else {
      dispatch(togleTemplateAction(false))
      getMembersDone({
        variables: {
          where: {
            channel: { refCode: { equals: selectedChannelRefCode.refCode } },
            case: {
              equals: 'CLOSE',
            },
          },
        },
      })
      history.push({
        pathname: '',
        search: '?chats=done',
      })
    }
  }

  useEffect(() => {
    history.push({
      pathname: '',
      search: '?chats=active',
    })
  }, [])

  //Members user ที่ปิดเคส
  const [getMembersDone] = useLazyQuery(MEMBERS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const res = data.members
      dispatch(memberListDoneAction(res))
    },
  })

  const [readMess] = useMutation(READ_MESSAGE)

  // const onMessToPin = async res => {
  //   if (data.length > 0) {
  //     const pin = await data.filter(ee => ee.id !== res?.id)
  //     const pin_macth = await data.filter(ee => ee.id === res?.id)
  //     if (pin_macth.length > 0) {
  //       await pin.push(Object.assign({}, res))
  //       saveState(pin)
  //       setData(pin)
  //     }
  //   }
  // }

  const readedMessage = id => {
    dispatch(memberIdAction(id))
    try {
      readMess({
        variables: {
          data: {
            memberId: id,
            oldMemberId: selectedOldRead,
          },
        },
      })
    } catch (e) {
      console.log('Err-read: ', e)
    }
  }

  const chatSelectHandle = async member => {
    dispatch(checkScrollAction(true))

    if (selectedChat) {
      if (member.id !== selectedChat.id) {
        await readedMessage(member.id)
        await dispatch(oldRead(member.id))
        await dispatch(aboutAction(member))
        await dispatch(memberIdAction(member.id))
        await dispatch(selectedMemberToChatAction(member))
        // await dispatch(mobileSidebarAction(false))
      }
    } else {
      await readedMessage(member.id)
      await dispatch(oldRead(member.id))
      await dispatch(aboutAction(member))
      await dispatch(memberIdAction(member.id))
      await dispatch(selectedMemberToChatAction(member))
      // await dispatch(mobileSidebarAction(false))
    }
  }

  useEffect(() => {
    if (data.length === 0) {
      setData(loadState())
    }
  }, [])

  const pinMember = member => {
    if (data.length === 0) {
      saveState([member])
      setData([member])
    } else {
      const dup = data.filter(e => e.id === member.id)
      if (dup.length === 0) {
        setData([...data, member])
        saveState([...data, member])
      }
    }
  }

  const remMember = member => {
    const unRem = data.filter(e => e.channelRefCode === member.channelRefCode && e.id !== member.id)
    saveState(unRem)
    setData(unRem)
  }

  const memberListLine = () => {
    const res = myCaseList.filter(isMem => {
      return (
        data.filter(isPin => {
          return isPin.id == isMem.id
        }).length == 0
      )
    })
    return res
  }

  const memberNewListLine = () => {
    const res = memberNewList.filter(isMem => {
      return (
        data
          .sort((a, b) => moment(a.lastChatTime) - moment(b.lastChatTime))
          .filter(isPin => {
            return isPin.id == isMem.id
          }).length == 0
      )
    })
    return res
  }

  const convertDate = d => {
    var momente = require('moment')
    return momente(d).format('lll')
  }

  const MemberListView = props => {
    const member = props
    return (
      <li className={'list-group-item ' + (member?.id === selectedChat?.id ? 'open-chat' : '')}>
        <div
          className="d-flex"
          style={{ cursor: 'pointer' }}
          onClick={() => chatSelectHandle(member)}
        >
          <figure className="avatar">
            {member?.profileImage && (
              <img
                src={member.profileImage}
                onError={imgErr}
                alt="profile"
                width="100%"
                height="100%"
                className="rounded-circle"
                loading="lazy"
              />
            )}
          </figure>
          <div className="users-list-body">
            <h6 className="over-txt2">{member?.displayName}</h6>
            {member.lastChatMessage === 'Image' ? (
              <i className="fa fa-camera mr-1 mt-1 text-secondary">
                <span> ส่งรูปภาพ</span>
              </i>
            ) : (
              <>
                {member.lastChatMessage === 'Sticker' ? (
                  <i className="ti ti-face-smile mr-1 mt-1 text-secondary">
                    <span> ส่งสติ๊กเกอร์</span>
                  </i>
                ) : (
                  <>
                    <p className="over-txts">{member.lastChatMessage}</p>
                  </>
                )}
              </>
            )}
            <div>
              <span className="badge badge-pill badge-dark">{member?.channel.name}</span>{' '}
              <span className="badge badge-pill badge-primary ">{member?.operator?.username}</span>
            </div>
            <div className="users-list-body">
              <p>{convertDate(member?.lastChatTime)}</p>
            </div>
            <div className="users-list-action action-toggle">
              {member.unreadChatMessage ? (
                <div className="rounded-circle text-center count-unread-small bg-success">
                  {member.unreadChatMessage < 100 ? member.unreadChatMessage : '99+'}
                </div>
              ) : (
                <div
                  className={
                    member.unreadChatMessage > 0
                      ? 'rounded-circle text-center text-light count-unread-small'
                      : 'rounded-circle text-center text-light'
                  }
                >
                  <span className="text-light">
                    {member.unreadChatMessage > 0 ? member.unreadChatMessage : ''}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </li>
    )
  }

  const handleSelectChannel = async code => {
    const nowChannel = await selectedChannel?.find(e => e.refCode === code)
    if (nowChannel) {
      dispatch(channelRefCodeAction(nowChannel.refCode, nowChannel.id, nowChannel.name))
      getMembers({
        variables: {
          where: {
            channelRefCode: {
              equals: String(code),
            },
            case: { in: 'OPEN' },
          },
          take: 100,
        },
      })
    } else {
      getMembers({
        variables: {
          where: {
            case: { in: 'OPEN' },
          },
          take: 100,
        },
      })
      dispatch(channelRefCodeAction('', 0, ''))
    }
  }

  return (
    <div className="sidebar active ShadowBorder mr-2" style={{ minWidth: '340px' }}>
      <header className="d-block pb-0 mb-0 pt-0">
        <ul className="list-inline mt-2">
          <li className="d-flex justify-content-center mb-0 pb-0">
            <BtnTab
              className={tab === 1 ? 'bg-success' : 'bg-light'}
              size="sm"
              block
              onClick={() => togleTab(1, false)}
            >
              New Case
            </BtnTab>
            <BtnTab
              className={tab === 2 ? 'bg-success' : 'bg-light'}
              size="sm"
              block
              onClick={() => togleTab(2, true)}
            >
              Close Case
            </BtnTab>
          </li>
        </ul>
      </header>

      <div
        className="mb-0"
        style={{
          paddingLeft: '1rem',
          paddingRight: '1rem',
          paddingBottom: '0.5rem',
          paddingTop: '0.5rem',
        }}
      >
        <input
          onChange={e => setSearch(e.target.value)}
          type="text"
          className="form-control"
          placeholder="Search member"
          ref={inputRef}
        />

        <select className="form-control mt-2" onChange={e => handleSelectChannel(e.target.value)}>
          <option value="">ทั้งหมด</option>
          {selectedChannel.map((item, index) => (
            <option value={item.refCode} key={index}>
              {item.name}
            </option>
          ))}
        </select>
      </div>

      <NewChatAll
        MemberListView={MemberListView}
        tab={tab}
        myMemberListLine={memberListLine}
        memberListLine={memberNewListLine}
        search={search}
        searchChannel={searchChannel}
        chatSelectHandle={chatSelectHandle}
        pinMember={pinMember}
        data={data}
        setData={setData}
        remMember={remMember}
      />
    </div>
  )
}

export default MemberListChats
