export const templateitemsReducer = (state = null, action) => {
  switch (action.type) {
    case 'TEMPLATE_ITEM':
      return action.template_items
    default:
      return state
  }
}

export const selectedTemplateReducer = (state = [], action) => {
  switch (action.type) {
    case 'SELECTED_TEMPLATE':
      return action.template
    default:
      return state
  }
}

export const itemsListReducer = (
  state = {
    key: Number,
    index: 0,
    text: '',
    type: '',
    body: '',
  },
  action,
) => {
  switch (action.type) {
    case 'ITEMS_LIST':
      return action.items
    default:
      return state
  }
}

export const togleTemplateReducer = (state = false, action) => {
  switch (action.type) {
    case 'TOGLE_TEMPLATE':
      return action.status
    default:
      return state
  }
}
