import { gql } from 'apollo-boost'

export const CREATE_ONE_TEMPLATE = gql`
  mutation createOneTemplate($data: TemplateCreateInput!) {
    createOneTemplate(data: $data) {
      id
    }
  }
`

export const UPDATE_ONE_TEMPLATE = gql`
  mutation updateOneTemplate($where: TemplateWhereUniqueInput!, $data: TemplateUpdateInput!) {
    updateOneTemplate(where: $where, data: $data) {
      id
    }
  }
`

