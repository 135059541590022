import { createStore, combineReducers } from 'redux'

import aboutReducer from './Reducers/aboutReducer'
import mobileProfileSidebarReducer from './Reducers/mobileProfileSidebarReducer'
import userReducer from './Reducers/userReducer'
import { mobileSidebarReducer } from './Reducers/Mobile'
import { profileReducer } from './Reducers/Profile'
import { sidebarReducer } from './Reducers/Sidebar'
import { memberLoadingReducer, myMemberLoadingReducer } from './Reducers/Loading/index'

import {
  itemsListReducer,
  selectedTemplateReducer,
  templateitemsReducer,
  togleTemplateReducer,
} from './Reducers/Template/'
import { organisationListReducer, editOrganisationReducer } from './Reducers/Organisation'
import {
  showListMessageReducer,
  lastMessageReducer,
  olderReadReducer,
  checkHandleReducer,
  checkScrollReducer,
} from './Reducers/Message'
import {
  operatorReducer,
  editOperatorReducer,
  operatorStatusReducer,
  operatorOnlineReducer,
} from './Reducers/Operator'
import {
  myCaseReducer,
  memberNewListReducer,
  memberListDoneReducer,
  selectedChatReducer,
  memberIdReducer,
  selectedProfileReducer,
} from './Reducers/Members'
import { noteReducer } from './Reducers/Note'
import {
  selectedChannelReducer,
  editChannelReducer,
  channelRefCodeReducer,
} from './Reducers/Channel'
import { pinListReducer } from './Reducers/Pin'

import { loadState, saveState } from '../utils/localStorage'

const reducers = combineReducers({
  selectedOperator: operatorReducer,
  editChannelReducer: editChannelReducer,
  editOperatorReducer: editOperatorReducer,
  editOrganisation: editOrganisationReducer,
  lastMessage: lastMessageReducer,
  memberListDone: memberListDoneReducer,
  memberNewList: memberNewListReducer,
  mobileProfileSidebar: mobileProfileSidebarReducer,
  mobileSidebar: mobileSidebarReducer,
  myCaseList: myCaseReducer,
  operatorStatus: operatorStatusReducer,
  pinList: pinListReducer,
  profile: selectedProfileReducer,
  profileSidebar: profileReducer,
  selectedAbout: aboutReducer,
  selectedChannel: selectedChannelReducer,
  selectedChannelRefCode: channelRefCodeReducer,
  selectedChat: selectedChatReducer,
  selectedItems: itemsListReducer,
  selectedMemberId: memberIdReducer,
  selectedNote: noteReducer,
  selectedOldRead: olderReadReducer,
  selectedOrganisation: organisationListReducer,
  selectedSidebar: sidebarReducer,
  selectedTemplate: selectedTemplateReducer,
  selectedTemplateItem: templateitemsReducer,
  selectedUser: userReducer,
  showListMessage: showListMessageReducer,
  checkHandle: checkHandleReducer,
  memberLoading: memberLoadingReducer,
  myMemberLoading: myMemberLoadingReducer,
  togleTemplate: togleTemplateReducer,
  operatorOnline: operatorOnlineReducer,
  checkScroll: checkScrollReducer,
})

const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
)

store.subscribe(() => {
  saveState(store.getState().pinList)
})

export default store
