import React from 'react'
import { useSelector } from 'react-redux'

import imgErr from '../../../../utils/imgErr'

const PinChat = props => {
  const { selectedChat } = useSelector(state => state)
  const convertDate = d => {
    var momente = require('moment')
    return momente(d).format('lll')
  }
  return (
    <ul className="list-group list-group-flush">
      {props.data &&
        props.data?.length > 0 &&
        props.data
          .sort((a, b) => new Date(b.lastChatTime) - new Date(a.lastChatTime))
          .map((e, i) => (
            <li
              key={i}
              style={{ backgroundColor: '#b8b8b8', margin: '1px 0px' }}
              className={'list-group-item ' + (e?.id === selectedChat?.id ? 'open-chat' : '')}
            >
              <div
                className="d-flex"
                style={{ cursor: 'pointer' }}
                onClick={() => props.chatSelectHandle(e)}
              >
                <figure className="avatar">
                  {e?.profileImage && (
                    <img
                      src={e.profileImage}
                      alt="profile"
                      onError={imgErr}
                      width="100%"
                      height="100%"
                      className="rounded-circle"
                      loading="lazy"
                    />
                  )}
                </figure>
                <div className="users-list-body">
                  <h6 className="over-txt2">{e.displayName}</h6>

                  {e.lastChatMessage === 'Image' ? (
                    <i className="fa fa-camera mr-1 mt-1 text-secondary">
                      <span> ส่งรูปภาพ</span>
                    </i>
                  ) : (
                    <>
                      {e.lastChatMessage === 'Sticker' ? (
                        <i className="ti ti-face-smile mr-1 mt-1 text-secondary">
                          <span> ส่งสติ๊กเกอร์</span>
                        </i>
                      ) : (
                        <>
                          <p className="over-txts mb-0">{e.lastChatMessage}</p>
                        </>
                      )}
                    </>
                  )}

                  <div>
                    <span className="badge badge-pill badge-dark">{e?.channel.name}</span>{' '}
                    <span className="badge badge-pill badge-primary ">{e?.operator?.username}</span>
                  </div>
                  <div className="users-list-body">
                    <p>{convertDate(e?.lastChatTime)}</p>
                  </div>

                  <div className="users-list-action action-toggle">
                    {e.unreadChatMessage ? (
                      <div className="rounded-circle text-center count-unread-small bg-success">
                        {e.unreadChatMessage < 100 ? e.unreadChatMessage : '99+'}
                      </div>
                    ) : (
                      <div
                        className={
                          e.unreadChatMessage > 0
                            ? 'rounded-circle text-center text-light count-unread'
                            : 'rounded-circle text-center text-light'
                        }
                      >
                        <span className="text-light">
                          {e.unreadChatMessage > 0 ? e.unreadChatMessage : null}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <i
                className="ti ti-pin-alt btn animate__animated animate__fadeIn pt-1"
                style={{
                  color: '#3db16b',
                  fontWeight: 'bolder',
                }}
                onClick={() => props.remMember(e)}
              />
            </li>
          ))}
    </ul>
  )
}

export default PinChat
