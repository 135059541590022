export const operatorAction = data => ({
  type: 'OPERATOR',
  data,
})

export const editOperatorAction = edit_operator => ({
  type: 'EDIT_OPERATOR',
  edit_operator,
})

export const operatorStatusAction = status => ({
  type: 'STATUS_OPERATOR',
  status,
})

export const operatorOnlineAction = data => ({
  type: 'OPERATOR_ONLINE',
  data,
})
