import React, { useEffect } from 'react'

import SidebarIndex from './Sidebars/index.jsx'
import Navigation from './Navigation.jsx'
import Profile from './Sidebars/Chats/Profile/index.jsx'
import Chat from './Partials/Chat.jsx'
import FavoriteTemplate from './Partials/FavoriteTemplate'
// import DisconnectedModal from './Modals/DisconnectedModal'

import { useHistory } from 'react-router-dom'
import { load } from '../utils/localStorage'

import { GetTemplates } from '../api/cli/Templates'

function Layout() {
  let history = useHistory()
  const { loading } = GetTemplates()

  useEffect(() => {
    if (load('opst')) {
      history.push('/lock-screen')
    }
  }, [])

  useEffect(() => {
    document.querySelector('*').addEventListener('click', e => {
      if (document.body.classList.contains('navigation-open') && e.target.nodeName === 'BODY') {
        document.body.classList.remove('navigation-open')
      }
    })
  }, [])

  return (
    <>
      <Navigation />

      <div className="layout">
        <div className="content">
          <SidebarIndex />
          <Chat />
          <FavoriteTemplate />
          <Profile />
          {/* <DisconnectedModal /> */}
        </div>
      </div>
    </>
  )
}

export default Layout
