export const editOperatorReducer = (state = null, action) => {
  switch (action.type) {
    case 'EDIT_OPERATOR':
      return action.edit_operator
    default:
      return state
  }
}

export const operatorReducer = (
  state = {
    contact: {
      bankAccId: null,
      createdAt: '',
      email: null,
      firstName: '',
      id: null,
      isActive: true,
      lastName: null,
      lineID: null,
      mobile: null,
      nickname: null,
      organisationRefCode: '',
      updatedAt: '',
      username: '',
    },
    contactId: null,
    createdAt: '',
    id: null,
    isActive: true,
    password: '',
    refCode: '',
    role: '',
    status: '',
    updatedAt: '',
    username: '',
  },
  action,
) => {
  switch (action.type) {
    case 'OPERATOR':
      return action.data
    default:
      return state
  }
}

export const operatorStatusReducer = (state = '', action) => {
  switch (action.type) {
    case 'STATUS_OPERATOR':
      return action.status
    default:
      return state
  }
}

export const operatorOnlineReducer = (state = [], action) => {
  switch (action.type) {
    case 'OPERATOR_ONLINE':
      return action.data
    default:
      return state
  }
}
