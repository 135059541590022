export const templateItemAction = template_items => ({
  type: 'TEMPLATE_ITEM',
  template_items,
})

export const selectedTemplateAction = template => ({
  type: 'SELECTED_TEMPLATE',
  template,
})

export const itemsAction = items => ({
  type: 'ITEMS_LIST',
  items,
})

export const togleTemplateAction = status => ({
  type: 'TOGLE_TEMPLATE',
  status,
})
