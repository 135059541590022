import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Row, Col } from 'react-bootstrap'
import { Table, Tag } from 'antd'
import { useMutation } from '@apollo/client'
import Swal from 'sweetalert2'

import { DELETE_TEMPLATE } from '../../../../api/mutation/deleteTemplate'
import { selectedTemplateAction } from '../../../../Store/Actions/Template'

import { Channels, ConditionChannelsCount } from '../../../../api/query/channeles'

function ChannelTable(props) {
  const dispatch = useDispatch()
  const { selectedTemplate, selectedChannel, selectedOperator } = useSelector(state => state)
  const [state] = useState({
    top: 'bottomCenter',
    bottom: 'bottomCenter',
  })
  const {
    getConditionChannelsCount,
    data: ChannelsCount,
    loading: loadingConditionChannelsCount,
  } = ConditionChannelsCount()
  const { getConditionChannels, data: Channel, loading: loadingChannel } = Channels()
  const [pageDetail, setPage] = useState({
    pageNumber: 1,
    totalPage: 0,
  })

  // const [delTemplate] = useMutation(DELETE_TEMPLATE, {
  //   onCompleted: data => {
  //     const res = data.deleteOneTemplate
  //     const val = selectedTemplate.filter(e => e.id !== res.id)
  //     dispatch(selectedTemplateAction(val))
  //     Swal.fire('Delete is done!', '', 'success')
  //   },
  //   onError: err => {
  //     Swal.fire('Cannot delete is template!' + err, '', 'error')
  //   },
  // })

  // const deleteTemp = e => {
  //   // e.preventDefault()
  //   Swal.fire({
  //     icon: 'question',
  //     title: 'Do you want to delete the changes?',
  //     showCancelButton: true,
  //     confirmButtonText: `Delete`,
  //   }).then(result => {
  //     if (result.isConfirmed) {
  //       delTemplate({
  //         variables: {
  //           where: {
  //             id: e.id,
  //           },
  //         },
  //       })
  //     }
  //   })
  // }

  const columns = [
    // {
    //   title: 'No.',
    //   key: 'key',
    //   render: (value, row, index) => <span>{index + 1}</span>,
    //   align: 'center',
    // },
    {
      title: 'No.',
      key: 'key',
      align: 'center',
      render: (value, row, index) => (
        <span>
          {(pageDetail.pageNumber == 1
            ? pageDetail.pageNumber * 1 - 1
            : (pageDetail.pageNumber * 1 - 1) * 10) +
            (index + 1)}
        </span>
      ),
    },

    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Ogranization Name',
      render: (value, row, index) => <span key={index}> {value.organisation.name}</span>,
    },
    {
      title: 'basicId',
      dataIndex: 'basicId',
      key: 'basicId',
      align: 'center',
      render: basicId => <span> {basicId}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      render: isActive => {
        let color = isActive ? 'green' : 'yellow'
        let status = isActive ? 'Active' : 'Deactive'
        return <Tag color={color}>{status}</Tag>
      },
    },
    {
      title: 'Last update',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      responsive: ['sm'],
      align: 'center',
      render: updatedAt => {
        return <span>{moment(updatedAt).format('DD/MM/YYYY')}</span>
      },
    },
    {
      title: 'Actions',
      dataIndex: 'acrions',
      align: 'center',
      render: (record, index) => {
        return (
          <Row noGutters>
            <Col onClick={() => props.formModalToggle('View', index, 'channel')}>
              <i className="ti ti-eye pointer"></i>
            </Col>
            <Col onClick={() => props.formModalToggle('Edit', index, 'channel')}>
              <i className="ti ti-pencil-alt pointer"></i>
            </Col>
            {/* <Col onClick={() => deleteTemp(index)}>
              <i className="ti ti-trash pointer"></i>
            </Col> */}
          </Row>
        )
      },
    },
  ]

  useEffect(() => {
    if (props.activeTab == '2') {
      // console.log('activeTab',props,selectedOperator);
      getData()
    }
  }, [props.activeTab])

  const getData = () => {
    getConditionChannels({
      variables: {
        where: {
          // contact : {
          // refCode: {equals: ((selectedOperator?.role == 'SUPERADMIN') ? null : selectedOperator?.contact.organisationRefCode)}
          ...(selectedOperator?.role != 'SUPERADMIN' && {
            organisationRefCode: { equals: selectedOperator?.contact.organisationRefCode },
          }),
          // organisationRefCode: {equals: selectedOperator?.contact.organisationRefCode}
          // }
        },
        take: 10,
        skip:
          pageDetail.pageNumber == 1
            ? pageDetail.pageNumber * 1 - 1
            : (pageDetail.pageNumber * 1 - 1) * 10,
      },
    })

    getConditionChannelsCount({
      variables: {
        where: {
          // contact : {
          ...(selectedOperator?.role != 'SUPERADMIN' && {
            organisationRefCode: { equals: selectedOperator?.contact.organisationRefCode },
          }),
          // }
        },
      },
    })
  }
  const handleTableChange = e => {
    var set = pageDetail
    set.pageNumber = e.current
    // set.totalPage =((props.type == 'LogActivityDetail') ? activitylogcount?.operatorActivityCount?.total : assignlog?.operatorMembers.length);
    setPage(set)
    getData()

    // console.log('Operators?.operators',Operators?.operators);
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={Channel?.channels}
        loading={loadingChannel}
        bordered
        pagination={{
          position: [state.top, state.bottom],
          pageSize: 10,
          current: pageDetail.pageNumber,
          total: ChannelsCount?.channelCount?.total,
        }}
        rowKey="id"
        onChange={e => handleTableChange(e)}
      />
    </>
  )
}

export default ChannelTable
