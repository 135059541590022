const loadState = () => {
  try {
    const serializedState = localStorage.getItem('pin')
    if (serializedState === null) {
      return undefined
    } else {
      return JSON.parse(serializedState)
    }
  } catch (error) {
    return undefined
  }
}

const saveState = state => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('pin', serializedState)
  } catch (error) {
    console.log(error.message)
  }
}

const load = name => {
  try {
    const serializedState = localStorage.getItem(name)
    if (serializedState === null) {
      return undefined
    } else {
      return JSON.parse(serializedState)
    }
  } catch (error) {
    return undefined
  }
}

const save = (name, state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(name, serializedState)
  } catch (error) {
    console.log(error.message)
  }
}

export { loadState, saveState, load, save }
