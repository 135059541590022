import { gql } from 'apollo-boost'

export const CREATE_ONE_MESSAGE = gql`
  mutation createOneMessage($data: MessageCreateInput!) {
    createOneMessage(data: $data) {
      id
      message
      messageFrom
      messageTime
      messageType
      operatorId
      memberId
      # channelId
    }
  }
`
