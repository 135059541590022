import { gql } from 'apollo-boost'
import { useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/client'

import { myCase } from '../../Store/Actions/Members'
import { myMemberLoadingAction } from '../../Store/Actions/Loading/index'

const MEMBERS = gql`
  query members($where: MemberWhereInput, $take: Int) {
    members(
      where: $where
      take: $take # orderBy: { updatedAt: desc }
      orderBy: { lastChatTime: asc }
    ) {
      id
      assignType
      case
      displayName
      operatorId
      unreadChatMessage
      operator
      operatorsRead
      contact {
        id
        username
        firstName
        lastName
        mobile
        lineID
        email
        bankAccId
        organisation {
          id
          name
        }
      }
      note {
        topic
        detail
        colorCode
        createdAt
        updatedAt
        isActive
      }
      channelRefCode
      lineUserId
      profileImage
      contactId
      lastChatMessage
      lastChatTime
      unreadChatMessage
      channel {
        id
        name
      }
      groupRefCode
      createdAt
      updatedAt
      botStatus
      isActive
      block
    }
  }
`

export const GetMyMembers = () => {
  const dispatch = useDispatch()
  const [getMyMembers, { data, loading: loadingM }] = useLazyQuery(MEMBERS, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const res = data.members
      dispatch(myCase(res))
      dispatch(myMemberLoadingAction(false))
      // console.log('Members-res: ', res)
    },
    onError: err => {
      // console.log('Members-Err: ', err);
    },
  })

  return {
    getMyMembers,
    data,
    loadingM,
  }
}
