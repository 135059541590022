import { gql } from 'apollo-boost'
import { useLazyQuery } from '@apollo/client'

export const ORGANISATIONS = gql`
  query organisations($where: OrganisationWhereInput, $take: Int, $skip: Int) {
    organisations(where:$where, take: $take, skip: $skip, orderBy: { updatedAt: desc }) {
      id
      key
      refCode
      name
      description
      createdAt
      updatedAt
      isActive
    }
  }
`
export const ORGANISATION_COUNT = gql`
  query organisationCount($where: OrganisationWhereInput) {
    organisationCount(where: $where)
  }
`
export const Organisations = () => {
  const [getConditionOrganisation, { data, loading }] = useLazyQuery(
    ORGANISATIONS,
    {
      fetchPolicy: 'no-cache',
    },
  )
  return {
    getConditionOrganisation,
    data,
    loading,
  }
}

export const ConditionOrganisationCount = () => {
  const [getConditionOrganisationCount, { data, loading }] = useLazyQuery(
    ORGANISATION_COUNT,
    {
      fetchPolicy: 'no-cache',
    },
  )
  return {
    getConditionOrganisationCount,
    data,
    loading,
  }
}