import { gql } from 'apollo-boost'
import { useLazyQuery } from '@apollo/client'

const OPERATOR_MEMBER = gql`
  query operatorMembers($data: operatorMembers) {
    operatorMembers(data: $data)
  }
`

export const AssignLog = () => {
  const [getAssignLog, { data, loading }] = useLazyQuery(
    OPERATOR_MEMBER,
    {
      fetchPolicy: 'no-cache',
 
    },
  )

  return {
    getAssignLog,
    data,
    loading,
  }
}
