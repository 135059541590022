import { gql } from 'apollo-boost'

export const CREATE_ORGANISATION = gql`
  mutation createOneOrganisation($data: OrganisationCreateInput!) {
    createOneOrganisation(data: $data) {
      id
      name
    }
  }
`

export const UPDATE_ORGANISATION = gql`
  mutation updateOneOrganisation($where: OrganisationWhereUniqueInput!, $data: OrganisationUpdateInput!) {
    updateOneOrganisation(where: $where, data: $data) {
      id
    }
  }
`


