import React, { useCallback, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { Badge } from 'reactstrap'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap'
import { Row, Col } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'

import { CREATE_ONE_MESSAGE_POINT } from '../../api/mutation/createOneMessagePoint'
import NoteModal from '../Modals/NoteModal'
import ModalForward from '../Modals/Forwarding/index'
import OperatorRead from '../Modals/Reads/index'

import { profileAction } from '../../Store/Actions/Profile'
import { mobileProfileAction } from '../../Store/Actions/mobileProfileAction'
import {
  selectedMemberToChatAction,
  myCase,
  memberNewListAction,
} from '../../Store/Actions/Members'
import { sidebarAction } from '../../Store/Actions/Sidebar'
import { noteAction } from '../../Store/Actions/Note'
import imgErr from '../../utils/imgErr'
import { NOTE } from '../../api/query/noted'
import { loadingCss } from '../../assets/styles/anime'
import { loadState, saveState } from '../../utils/localStorage'
// import { MANUAL_ASSIGN } from '../../api/mutation/ManualAssign'

function ChatHeader(props) {
  const dispatch = useDispatch()
  let history = useHistory()

  const [modalForword, setModalForword] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen(prevState => !prevState)

  const {
    myCaseList,
    memberNewList,
    selectedMemberId,
    profileSidebar,
    selectedOperator,
    selectedNote,
    selectedUser,
    selectedChat,
  } = useSelector(state => state)

  const [getNote, { loading: loadNote }] = useLazyQuery(NOTE, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const res = data.getMemberNote
      dispatch(noteAction(res))
    },
  })

  useEffect(() => {
    if (selectedMemberId) {
      getNote({
        variables: {
          data: {
            memberId: selectedMemberId,
          },
        },
      })
    }
  }, [selectedMemberId])

  // const [manualAssign] = useMutation(MANUAL_ASSIGN, {
  //   onCompleted: async data => {
  //     setShowHandle(true)
  //     const res = data.ManualAssign
  //     if (res?.id) {
  //       if (!myCaseList) {
  //         dispatch(myCase([selectedChat]))
  //       } else {
  //         if (myCaseList.length < 3) {
  //           const arr = await myCaseList.filter(e => e.id !== selectedChat.id)
  //           await arr.push(Object.assign({}, selectedChat))
  //           dispatch(myCase(arr))
  //         }
  //       }
  //     } else {
  //       setShowHandle(false)

  //       Swal.fire({
  //         title: res?.id ? `สำเร็จ` : `ไม่สามารถกดรับเคสได้ `,
  //         text: res?.id ? `เพิ่มเคส ${res?.displayName} เรียบร้อย` : res?.error?.message,
  //         icon: res?.id ? 'success' : 'warning',
  //         showCancelButton: false,
  //         confirmButtonText: `ปิด`,
  //       })
  //     }
  //   },
  // })

  const [jobDone] = useMutation(CREATE_ONE_MESSAGE_POINT, {
    onCompleted: data => {
      const err = data?.createOneMessagePoint?.error?.message
      Swal.fire({
        icon: err ? 'error' : 'success',
        title: err ? err : 'Good job!',
        confirmButtonText: 'Close',
      })
      dispatch(selectedMemberToChatAction(null))
      dispatch(profileAction(false))
      dispatch(mobileProfileAction(false))

      const myCastTotal = myCaseList.filter(e => e.id !== selectedMemberId)
      const newCastTotal = memberNewList.filter(e => e.id !== selectedMemberId)
      dispatch(myCase(myCastTotal))
      dispatch(memberNewListAction(newCastTotal))
      dispatch(sidebarAction('Chats', ''))
    },
    onError: err => {
      console.log('Err: ', err)
    },
  })

  const closeJob = async () => {
    // const myCastTotal = myCaseList.filter(e => e.id !== selectedMemberId)
    // const newCastTotal = memberNewList.filter(e => e.id !== selectedMemberId)
    // dispatch(myCase(myCastTotal))
    // dispatch(memberNewListAction(newCastTotal))
    // dispatch(sidebarAction('Chats', ''))

    // console.log(
    //   'Pin: ',
    //   loadState().filter(e => e.id !== selectedMemberId),
    // )

    try {
      jobDone({
        variables: {
          data: {
            memberId: selectedMemberId,
            operatorId: selectedOperator?.id,
          },
        },
      })

      const revPin = await loadState().filter(e => e.id !== selectedMemberId)
      saveState(revPin)
    } catch (err) {
      console.log('Err: ', err)
    }
  }

  const profileActions = () => {
    dispatch(profileAction(true))
    dispatch(mobileProfileAction(true))
  }

  useEffect(() => {
    dispatch(profileAction(true))
    dispatch(mobileProfileAction(true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const submitHandleCase = () => {
  //   try {
  //     manualAssign({
  //       variables: {
  //         data: {
  //           operatorId: selectedOperator?.id,
  //           memberId: selectedChat?.id,
  //         },
  //       },
  //     })
  //   } catch (error) {
  //     console.log('Err=>ManualAssignCase: ', error)
  //   }
  // }

  const renderBtnHandleCase = useCallback(() => {
    // console.log('HH: ', selectedChat?.operatorId === selectedOperator?.id)

    if (selectedChat?.operatorId || selectedChat?.operatorId === selectedOperator?.id) {
      return null
    } else {
      if (history.location.search === '?chats=active') {
        return (
          <li className="list-inline-item setPaddingBadgeProcess">
            <Badge
              color="warning"
              className="setButtomProcessMsg"
              pill
              onClick={props.submitHandleCase}
            >
              กดรับงาน
            </Badge>
          </li>
        )
      }
    }
  }, [selectedChat?.operatorId, selectedOperator?.id])

  useEffect(() => {
    renderBtnHandleCase()
  }, [renderBtnHandleCase])

  const renderBtnCloseCase = () => {
    // if (selectedChat?.operator?.refCode === selectedUser?.sub) {
    return (
      <li className="list-inline-item setPaddingBadgeProcess">
        <Badge color="success" className="setButtomProcessMsg" pill onClick={closeJob}>
          ปิดเคส
        </Badge>
      </li>
    )
    // }
  }

  // const convertDate = d => {
  //   var momente = require('moment')
  //   return momente(d).format('lll')
  // }

  return (
    <div className="nav-h ShadowBorder" style={{ width: '480px' }}>
      <Row>
        <Col lg={12} className="d-flex">
          <figure className="avatar mr-2">
            <img
              src={selectedChat?.profileImage}
              onError={imgErr}
              alt="profile"
              width="40px"
              height="40px"
              className="rounded-circle"
              style={{ width: 40 }}
              loading="lazy"
            />
          </figure>
          <div>
            <strong className="text-dark">{selectedChat?.displayName}</strong>
          </div>
        </Col>

        <Col lg={12}>
          <ul className="list-inline text-right">
            {renderBtnHandleCase()}

            <li className="list-inline-item setPaddingBadgeProcess">
              <NoteModal />
            </li>

            {renderBtnCloseCase()}

            <li
              className="list-inline-item setPaddingBadgeProcess"
              data-toggle="tooltip"
              title="Video call"
            >
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
                  <Badge color="dark" className="setButtomProcessMsg" pill>
                    <i className="ti ti-more"></i>
                  </Badge>
                </DropdownToggle>
                <DropdownMenu right>
                  {!profileSidebar.profile && (
                    <DropdownItem onClick={profileActions}>Profile</DropdownItem>
                  )}

                  <DropdownItem onClick={() => setModalForword(true)}>Chat-Forward</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </li>
          </ul>
        </Col>
      </Row>

      <Row>
        <Col className="d-flex" lg={24}>
          {loadNote ? (
            <p className={`ml-2 pt-0 mb-0 ${loadingCss}`}>Now loading...</p>
          ) : (
            <>
              {selectedNote?.colorCode && (
                <i
                  className={`pt-1 d-flex fa fa-circle ml-2 text-${
                    selectedNote?.colorCode ? selectedNote?.colorCode : 'success'
                  }`}
                  aria-hidden="true"
                >
                  &nbsp;<p className="note-topic mb-0">{selectedNote?.topic}</p>
                </i>
              )}
            </>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <OperatorRead />
        </Col>
      </Row>
      <ModalForward modalForword={modalForword} setModalForword={setModalForword} />
    </div>
  )
}

export default ChatHeader
