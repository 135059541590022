import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import { useAuth0 } from '@auth0/auth0-react'

import Layout from './App/Layout'
import LockScreen from './Pages/LockScreen'
import Management from './App/Management/Management'
import PhoneCode from './Pages/PhoneCode'
import ResetPassword from './Pages/ResetPassword'
import SignIn from './Pages/SignIn'
import SignUp from './Pages/SignUp'
import history from './utils/history'

import 'react-perfect-scrollbar/dist/css/styles.css'

function App() {
  const { isLoading, error } = useAuth0()
  if (error) {
    return <div>Oops... {error.message}</div>
  } else if (isLoading) {
    return (
      <div
        className="text-center"
        style={{ position: 'fixed', top: '35%', left: 0, right: 0, bottom: 0 }}
      >
        <Spinner animation="grow" variant="info" title="Loading..." />
      </div>
    )
  } else {
    return (
      <Router history={history}>
        <Switch>
          <Route path="/sign-in" component={SignIn} />
          <Route path="/sign-up" component={SignUp} />
          <Route path="/lock-screen" component={LockScreen} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/phone-code" component={PhoneCode} />
          <Route path="/management" component={Management} />
          <Route path="/" component={Layout} />
        </Switch>
      </Router>
    )
  }
}

export default App
