import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'antd'

import { CTemplate } from '../../assets/styles/chat'

function ChatTemplate(props) {
  const { selectedTemplate, selectedChannelRefCode } = useSelector(state => state)

  return (
    <>
      {selectedTemplate && props.inputMsg !== '' && (
        <div style={{ position: 'relative' }}>
          <CTemplate>
            <Row gutter={[8, 0]} className="row-temp">
              {selectedTemplate
                ?.filter(temp => temp.channel.refCode === selectedChannelRefCode.refCode) // ถ้าต้องการปิด Filter ตัว Template by
                .filter(temp => temp.name.includes(props.inputMsg))
                .sort((a, b) => a.name.length - b.name.length)
                .map((temp, i) => (
                  <Col key={i} onClick={e => props.selectTemplate(e, temp)} className="m-1">
                    <div className="over-txt-temp animate__animated animate__fadeIn">
                      {temp.name}
                    </div>
                  </Col>
                ))}
            </Row>
          </CTemplate>
        </div>
      )}
    </>
  )
}

export default ChatTemplate
