import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'react-bootstrap'

import { ReactComponent as Logo } from '../assets/img/logo.svg'
import { load, save } from '../utils/localStorage'

function LockScreen() {
  let history = useHistory()

  const [text, setText] = useState('')
  const [icon, setIcon] = useState('')

  const renderTxt = () => {
    switch (load('opst')) {
      case 'BE_RIGHT_BACK':
        setText('เข้าห้องน้ำ')
        setIcon('fa-male fa-female')
        break
      case 'BREAK':
        setText('พักเบรค')
        setIcon('fa-cutlery')
        break
      case 'PAUSE':
        setText('PAUSE')
        setIcon('fa-pause')
        break
    }
  }

  const backTo = () => document.body.classList.remove('form-membership')

  useEffect(() => document.body.classList.add('form-membership'), [])
  useEffect(() => {
    if (load('opst')) {
      renderTxt()
    } else {
      backTo()
      history.push('/')
    }
    return () => {
      setText('')
      setIcon('')
    }
  }, [load('opst')])

  const login = () => {
    backTo()
    save('opst', '')
    history.push('/')
  }

  return (
    <div className="form-wrapper">
      <div className="logo">
        <Logo />
      </div>
      <h1>
        <i className={`fa ${icon}`}> {text}</i>
      </h1>

      <Button onClick={login}>กลับสู่ระบบ</Button>
    </div>
  )
}

export default LockScreen
