import { gql } from 'apollo-boost'

export const MESSAGES = gql`
  query messages($where: MessageWhereInput) {
    messages(where: $where, orderBy: { messageTime: desc }, take: 100) {
      id
      message
      messageFrom
      messageTime
      messageType
      memberId
      operatorId
      operator {
        username
      }
    }
  }
`

export const ON_MESSAGES = gql`
  subscription  onMessage($data: inputJoinMessage ) {
    onMessage(data: $data ) {
      id
      message
      messageFrom
      messageTime
      messageType
      operatorId
      operator {
        username
      }
      memberId
      member {
        id
        displayName
      }
      operator {
        id
        username
      }
      channelId
      channel {
        id
        name
        refCode
      }
    }
  }
`
