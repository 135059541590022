import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button, Form, Spinner } from 'react-bootstrap'
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks'
import Swal from 'sweetalert2'

import { OPERATOR_ONLINE } from '../../../api/query/operators'
import { CHAT_FORWARDING } from '../../../api/mutation/operator'

import { myCase, memberNewListAction } from '../../../Store/Actions/Members'
import { selectedMemberToChatAction } from '../../../Store/Actions/Members'
import { sidebarAction } from '../../../Store/Actions/Sidebar/index'
import { aboutAction } from '../../../Store/Actions/aboutAction'
import { profileAction } from '../../../Store/Actions/Profile'

import { MyModal } from '../../../assets/styles/modal'

const ForwardChat = props => {
  const dispatch = useDispatch()
  const { selectedOperator, selectedChat, myCaseList, memberNewList } = useSelector(state => state)

  const [forward, setForward] = useState([])
  const [opsRec, setOpsRec] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const [getOpsOnline] = useLazyQuery(OPERATOR_ONLINE, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    variables: {
      where: {
        contact: {
          // organisationRefCode : {
          //   equals : selectedChat?.contact?.organisation.id
          // }
          organisation: {
            id: { equals: selectedChat?.contact?.organisation.id },
          },
        },
      },
    },
    onCompleted: data => {
      const res = data?.operatorsOnline
      const resFill = res.filter(e => e.id !== selectedOperator.id)
      setForward(resFill)
    },
  })

  useEffect(() => {
    getOpsOnline()

    if (!props.modalForword) {
      setForward([])
      setOpsRec(null)
      setIsLoading(false)
    } else {
      // console.log('selectedChat useEffect',props.modalForword,selectedChat);
    }
  }, [props.modalForword])

  const [postForward] = useMutation(CHAT_FORWARDING, {
    onCompleted: data => {
      const res = data?.tranferMember
      const arrMyCase = myCaseList.filter(e => e.id !== res.id)
      const arrNewCase = memberNewList.filter(e => e.id !== res.id)
      dispatch(myCase(arrMyCase))
      dispatch(memberNewListAction(arrNewCase))

      dispatch(sidebarAction('Chats', ''))
      dispatch(aboutAction(null))
      dispatch(profileAction(false))

      const remove = selectedChat.id === res.id
      if (remove) {
        dispatch(selectedMemberToChatAction(null))
      }

      Swal.fire('ระบบโอนแชทเรียบร้อย!', '', 'success')
      Swal.fire({
        title: `ระบบโอนแชทเรียบร้อย`,
        icon: 'success',
        showCancelButton: true,
        showCancelButton: false,
        confirmButtonText: `ปิด`,
      })
      setIsLoading(false)
      setForward([])
      setOpsRec(null)
    },
  })

  const handleSelect = e => {
    setOpsRec(JSON.parse(e.target.value))
  }

  const submitForward = () => {
    if (opsRec) {
      Swal.fire({
        title: `คุณต้องการที่จะโอนแชทไปยัง ${opsRec.username} ใช่ไหม?`,
        showCancelButton: true,
        confirmButtonText: `ตกลง`,
        cancelButtonText: `ยกเลิก`,
      }).then(result => {
        if (result.isConfirmed) {
          props.setModalForword(false)
          setIsLoading(true)
          postForward({
            variables: {
              data: {
                operatorId: opsRec?.id,
                memberId: selectedChat?.id,
              },
            },
          })
        } else {
          props.setModalForword(true)
        }
      })
    } else {
      Swal.fire({
        title: `กรุณาระบุเจ้าหน้าที่ที่ต้องการโอนแชท`,
        icon: 'warning',
        showCancelButton: true,
        showCancelButton: false,
        confirmButtonText: `ปิด`,
      })
    }
  }

  return (
    <>
      <Modal show={props.modalForword} onHide={props.setModalForword}>
        <Modal.Header closeButton>
          <Modal.Title>โอนแชท</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="operator">
              <Form.Control
                as="select"
                defaultValue="เจ้าหน้าที่อยู่ในระบบขณะนี้"
                onChange={handleSelect}
              >
                {/* <Form.Label>
                  {forward?.length === 0
                    ? 'ไม่มีเจ้าหน้าที่อยู่ในระบบขณะนี้'
                    : 'กรุณาระบุเจ้าหน้าที่รับแชท'}
                </Form.Label> */}
                <option disabled>เจ้าหน้าที่อยู่ในระบบขณะนี้</option>
                {forward?.map((e, i) => (
                  <option key={i} value={JSON.stringify(e)}>
                    {e.username}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {opsRec && (
            <Button variant="success" onClick={submitForward}>
              Confirm
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {isLoading && (
        <MyModal>
          <div style={{ textAlign: 'center', marginTop: '20%' }}>
            <Spinner animation="border" variant="info" />
            <h5 className="text-light pt-2">โปรดรอสักครู่! ระบบกำลังทำการโอนแชท.</h5>
          </div>
        </MyModal>
      )}
    </>
  )
}

export default ForwardChat
