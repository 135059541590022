export const memberIdReducer = (state = 'Member', action) => {
  switch (action.type) {
    case 'MEMBER_ID':
      return action.id
    default:
      return state
  }
}

export const myCaseReducer = (state = [], action) => {
  switch (action.type) {
    case 'MY_CASE':
      return action.list
    default:
      return state
  }
}

export const memberNewListReducer = (state = [], action) => {
  switch (action.type) {
    case 'MEMBER_NEW_LIST':
      return action.list
    default:
      return state
  }
}

export const memberListDoneReducer = (state = [], action) => {
  switch (action.type) {
    case 'MEMBER_LIST_DONE':
      return action.list
    default:
      return state
  }
}

export const selectedChatReducer = (state = [], action) => {
  switch (action.type) {
    case 'SELECTED_CHAT':
      return action.chat
    default:
      return state
  }
}

export const selectedProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case 'PROFILE_NAME':
      return action.profile
    default:
      return state
  }
}
