import { gql } from 'apollo-boost'

export const CREATE_ONE_OPERATOR = gql`
  mutation createOneOperator($data: OperatorCreateInput!) {
    createOneOperator(data: $data) {
      id
      username
    }
  }
`

export const CREATE_OPERATOR = gql`
  mutation createOperator($data: createOperator) {
    createOperator(data: $data)
  }
`

export const CHECK_USER_DUP = gql`
  mutation userDuplicate($data: userDuplicate) {
    userDuplicate(data: $data)
  }
`

export const UPDATE_ONE_OPERATOR = gql`
  mutation updateOneOperator($where: OperatorWhereUniqueInput!, $data: OperatorUpdateInput!) {
    updateOneOperator(where: $where, data: $data) {
      id
    }
  }
`

export const CHAT_FORWARDING = gql`
  mutation tranferMember($data: tranferMember) {
    tranferMember(data: $data)
  }
`