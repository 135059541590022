import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useLazyQuery, useSubscription, useMutation } from '@apollo/react-hooks'
import moment from 'moment-timezone'
import { Spinner, Button, Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { Image } from 'antd'

import ChatHeader from './ChatHeader.jsx'
import ChatFooter from './ChatFooter.jsx'
import { BoxChat, TxtChat } from '../../assets/styles/chat'

import { MESSAGES, ON_MESSAGES } from '../../api/query/messages'

import {
  showListMessageAction,
  lastMessageAction,
  checkHandle,
  checkScrollAction,
} from '../../Store/Actions/Message'
import { myCase } from '../../Store/Actions/Members'
import { MANUAL_ASSIGN } from '../../api/mutation/ManualAssign'
import { selectedMemberToChatAction } from '../../Store/Actions/Members/index'

function Chat() {
  const {
    selectedChat,
    selectedMemberId,
    showListMessage,
    selectedOldRead,
    myCaseList,
    selectedOperator,
    checkScroll,
  } = useSelector(state => state)

  const dispatch = useDispatch()
  const inputEl = useRef(null)
  const [isPreview, setIsPreview] = useState(false)
  const [inputMsg, setInputMsg] = useState('')
  const [scrollEl, setScrollEl] = useState()
  const [imgPreview, setImgPreview] = useState()

  const [getChatData, { data, loading }] = useLazyQuery(MESSAGES, {
    fetchPolicy: 'no-cache',
  })

  // const [postUnread] = useMutation(UNREAD)
  const messagesEndRef = useRef(null)
  const [isScroll, setIsScroll] = useState(true)

  const scrollToBottom = useCallback(() => {
    setTimeout(() => {
      if (checkScroll) {
        if (messagesEndRef?.current) {
          return messagesEndRef?.current.scrollIntoView({
            // block: 'end',
            block: 'nearest',
            inline: 'center',
            alignToTop: true,
          })
        }
      }
    }, 1)
  }, [checkScroll])

  const onScrollUp = e => {
    if (e) {
      // console.log('Up-A: ', isScroll)
      // setIsScroll(false)
      dispatch(checkScrollAction(false))
    } else {
      // console.log('Up-B: ', isScroll)
      // setIsScroll(true)
      dispatch(checkScrollAction(true))
    }
  }

  const onScrollDown = e => {
    // console.log('Down: ', isScroll)
    // dispatch(checkScrollAction(true))
    // setIsScroll(true)
    dispatch(checkScrollAction(true))
  }

  useEffect(() => {
    console.log('isScroll: ', isScroll)
    // scrollToBottom()
    // console.log('checkScroll: ', checkScroll)
    // setIsScroll(true)
    // dispatch(checkScrollAction(true))
    return () => {
      setIsScroll(false)
      // dispatch(checkScrollAction(true))
    }
  }, [isScroll])

  useSubscription(ON_MESSAGES, {
    fetchPolicy: 'network-only',
    variables: {
      data: {
        memberId: typeof selectedMemberId == 'number' ? selectedMemberId : undefined,
        oldMemberId: selectedOldRead ? selectedOldRead : undefined,
      },
    },
    onSubscriptionData: data => {
      const res = data?.subscriptionData?.data?.onMessage

      const checkId = showListMessage[0]?.memberId === res?.memberId
      if (checkId) {
        showListMessage.push(res)
        dispatch(lastMessageAction(res?.message?.text))
        // scrollToBottom()
      }
    },
  })

  useEffect(() => {
    if (data) {
      const res = data?.messages
      // setGetMessage(res)
      if (selectedChat) {
        dispatch(showListMessageAction(res))
        // scrollToBottom()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleChange = e => {
    setInputMsg(e.target.value)
    e.preventDefault()
    return false
  }

  const chatHandle = useCallback(() => {
    if (typeof selectedMemberId == 'number') {
      getChatData({
        variables: {
          where: {
            member: {
              id: {
                equals: selectedMemberId,
              },
            },
          },
        },
      })
    }
  }, [selectedMemberId, scrollEl])

  useEffect(() => {
    chatHandle()
  }, [chatHandle])

  const renderProImg = () => {
    if (isPreview) {
      return (
        <Modal show={isPreview} onHide={() => setIsPreview(!isPreview)}>
          <Modal.Body>
            <Image className="my-modal-img" width="100%" src={imgPreview} alt="pre" />
          </Modal.Body>
        </Modal>
      )
    } else {
      return null
    }
  }

  const MessagesView = props => {
    const { message } = props
    useEffect(() => {
      if (props) {
        scrollToBottom()
      }
    }, [props])

    return (
      <div>
        {message.messageFrom === 'MEMBER' ? (
          <div id="messagesEndRef">
            {message.message.type === 'image' && (
              <>
                <Image
                  className="my-modal-img"
                  width="40%"
                  src={message.message.previewImageUrl}
                  alt="pre"
                  loading="lazy"
                />
              </>
            )}

            {message.messageType === 'STICKER' && (
              <img width="180px" alt="sticker" src={message.message.url} />
            )}

            {message.messageType === 'TEXT' && (
              <BoxChat className="ChatMsgCus">
                <TxtChat className="m-2">{message.message.text}</TxtChat>
              </BoxChat>
            )}

            <p className="mt-2 mb-0" style={{ fontSize: '0.7rem' }}>
              {moment(message.messageTime).format('LT')}
            </p>
          </div>
        ) : (
          <div className="p-2" style={{ textAlign: '-webkit-right' }}>
            {message.messageType === 'TEMPLATE' && (
              <span className="m-2">
                <Button variant="outline-secondary" disabled>
                  {message.message?.text}
                </Button>
                <p className="mt-2 mb-0 mr-2" style={{ fontSize: '0.7rem' }}>
                  <b className="ml-1">{message?.operator?.username}: </b>
                  {moment(message.messageTime).format('LT')}
                </p>
              </span>
            )}

            {message.messageType === 'TEXT' && (
              <>
                <BoxChat className="bg-light ChatMsgOper">
                  <TxtChat>{message.message?.text}</TxtChat>
                </BoxChat>
                <p className="mt-2 mb-0 mr-2" style={{ fontSize: '0.7rem' }}>
                  <b className="ml-1">{message?.operator?.username}: </b>
                  {moment(message.messageTime).format('LT')}
                </p>
              </>
            )}

            {message.message.type === 'image' && (
              <>
                <Image
                  className="my-modal-img"
                  width="40%"
                  src={message.message.previewImageUrl}
                  alt="pre"
                />
                <p className="mt-2 mb-0 mr-2" style={{ fontSize: '0.7rem' }}>
                  <b className="ml-1">{message?.operator?.username}: </b>
                  {moment(message.messageTime).format('LT')}
                </p>
              </>
            )}
          </div>
        )}
      </div>
    )
  }

  const [manualAssign] = useMutation(MANUAL_ASSIGN, {
    onCompleted: async data => {
      dispatch(checkHandle(true))
      const res = data.ManualAssign
      const operatorId = res.operatorId
      if (res?.id) {
        if (!myCaseList) {
          dispatch(myCase([selectedChat]))
        } else {
          if (myCaseList.length < 5) {
            const arr = await myCaseList.filter(e => e.id !== selectedChat.id)
            await arr.push(Object.assign({}, selectedChat))
            dispatch(myCase(arr))
            dispatch(selectedMemberToChatAction({ ...selectedChat, operatorId }))
          }
        }
      } else {
        dispatch(checkHandle(false))
        Swal.fire({
          title: res?.id ? `สำเร็จ` : `ไม่สามารถกดรับเคสได้ `,
          text: res?.id ? `เพิ่มเคส ${res?.displayName} เรียบร้อย` : res?.error?.message,
          icon: res?.id ? 'success' : 'warning',
          showCancelButton: false,
          confirmButtonText: `ปิด`,
        })
      }
    },
  })

  const submitHandleCase = () => {
    try {
      manualAssign({
        variables: {
          data: {
            operatorId: selectedOperator?.id,
            memberId: selectedChat?.id,
          },
        },
      })
    } catch (error) {
      console.log('Err=>ManualAssignCase: ', error)
    }
  }

  return (
    <div className="chat" id="chat-view" style={{ width: '480px' }}>
      {selectedChat?.displayName ? (
        <React.Fragment>
          <ChatHeader submitHandleCase={submitHandleCase} />

          <PerfectScrollbar
            containerRef={ref => setScrollEl(ref)}
            className="chatBorder ShadowBorder"
            style={{ width: '480px' }}
            onScrollUp={onScrollUp}
            onScrollDown={onScrollDown}
            // onScrollY={onScrollY}
          >
            {loading ? (
              <div
                className="text-center"
                style={{ height: 'inherit', width: 'inherit', backgroundColor: 'rgba(0,0,0,0.3)' }}
              >
                <Spinner animation="grow" variant="info" style={{ marginTop: '30%' }} />
                <p className="text-light mt-3 animate__animated animate__flash animate__infinite	infinite">
                  กำลังโหลดข้อมูล...
                </p>
              </div>
            ) : (
              <div className="chat-body" style={{ padding: '1rem' }}>
                {showListMessage?.length > 0
                  ? showListMessage
                      ?.sort((a, b) => moment(a.messageTime) - moment(b.messageTime))
                      .map((message, i) => {
                        return <MessagesView message={message} key={i} />
                      })
                  : null}
              </div>
            )}

            <div ref={messagesEndRef} style={{ width: '480px' }} />
          </PerfectScrollbar>

          {renderProImg()}

          <ChatFooter
            onChange={handleChange}
            inputMsg={inputMsg}
            setInputMsg={setInputMsg}
            inputEl={inputEl}
            scrollToBottom={scrollToBottom}
            selectedChat={selectedChat}
          />
        </React.Fragment>
      ) : (
        <div className="chat-body no-message">
          <div className="no-message-container">
            <i className="fa fa-comments-o"></i>
            <p>Select a member to read messages</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default Chat
