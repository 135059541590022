import React from 'react'

const SlideMenuStyles = {
  position: 'absolute',
  right: 0,
  width: '260px',
  backgroundImage: 'linear-gradient(to right, #296e69, #182827)',
  textAlign: 'left',
  zIndex: 9,
}

// test

const SlideMenu = props => {
  return (
    <ul
      className={
        !props.togle
          ? 'animate__animated animate__slideOutRight'
          : 'animate__animated animate__slideInRight'
      }
      style={SlideMenuStyles}
    >

      <li>
        <a href="/">
          <b className="text-light">Chat</b>
        </a>
      </li>
      <li>
        <a href="/management">
          <b className="text-light">จัดการ</b>
        </a>
      </li>

      <li>
        <a href="#" onClick={() => props.showModalMenu('status')}>
          <b className="text-light">สถานะ</b>
        </a>
      </li>

      <li>
        <a href="#" onClick={() => props.showModalMenu('setting')}>
          <b className="text-light">ตั้งค่า</b>
        </a>
      </li>

      {/* <li style={{ padding: 10 }}>
        <a href="#" onClick={() => setShow(!show)}>
          <div
            className={
              window.location.pathname == '/status'
                ? 'mb-0 setMenuHover active'
                : 'mb-0 setMenuHover'
            }
          ></div>

          <p
            className="mb-0 "
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            <b>Status</b>
          </p>
        </a>
      </li> */}

      {/* <li className="text-center mb-3" style={{ cursor: 'pointer' }}>
        <h5 onClick={settingsModalToggle}>
          <i className="ti ti-settings"></i>
        </h5>
      </li> */}
    </ul>
  )
}

export default SlideMenu
