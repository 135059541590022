export const selectedChannelReducer = (state = [], action) => {
  switch (action.type) {
    case 'SELECTED_CHANNEL':
      return action.channel
    default:
      return state
  }
}

export const editChannelReducer = (state = null, action) => {
  switch (action.type) {
    case 'EDIT_CHANNEL':
      return action.edit_channel
    default:
      return state
  }
}

export const channelRefCodeReducer = (
  state = {
    id: 0,
    refCode: '',
    name: '',
  },
  action,
) => {
  switch (action.type) {
    case 'CHANNEL_REF_CODE':
      return action
    default:
      return state
  }
}
