export const showListMessageReducer = (state = [], action) => {
  switch (action.type) {
    case 'SHOW_LIST_MESSAGE':
      return action.message
    default:
      return state
  }
}

export const lastMessageReducer = (state = '', action) => {
  switch (action.type) {
    case 'LAST_MESSAGE':
      return action
    default:
      return state
  }
}

export const olderReadReducer = (state = null, action) => {
  switch (action.type) {
    case 'OLD_READ':
      return action.id
    default:
      return state
  }
}

export const checkHandleReducer = (state = false, action) => {
  switch (action.type) {
    case 'CHECK_HANDLE':
      return action.status
    default:
      return state
  }
}

export const checkScrollReducer = (state = true, action) => {
  switch (action.type) {
    case 'CHECK_SCROLL':
      return action.status
    default:
      return state
  }
}
