const aboutReducer = (state = 'Member', action) => {
  switch (action.type) {
    case 'ABOUT':
      return action
    default:
      return state
  }
}

export default aboutReducer
