import React, { useState } from 'react'
import { Table } from 'antd'
import { Badge } from 'react-bootstrap'
import moment from 'moment'
import 'moment/locale/th'

moment.locale('th')

function DepWithTable(props) {
  // const [isPageSize, setIsPageSize] = useState(null)
  const [state] = useState({
    top: 'bottomCenter',
    bottom: 'bottomCenter',
  })

  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      render: (value, row, index) => {
        return <span>{index + 1}</span>
        // return <span>{index + 1 + isPageSize}</span>
      },
    },
    {
      title: 'created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
      render: createAt => {
        return (
          <p className="mb-0">
            <span>{moment(createAt).format('ll')}</span> <br />
            <span className="text-secondary">{moment(createAt).format('LTS')} น.</span>
          </p>
        )
      },
    },
    {
      title: 'Bank / By',
      dataIndex: 'UserBankName',
      key: 'UserBankName',
      render: (value, row, index) => {
        return (
          <>
            {row.UserBankName ? (
              <span>{row.UserBankName}</span>
            ) : (
              <Badge pill variant="warning">
                <span style={{ fontSize: '0.8rem' }}>{row.createdBy}</span>
              </Badge>
            )}
          </>
        )
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => b.amount - a.amount,
      render: amount => {
        return <span>{Number(amount).toLocaleString('th-TH', 'decimal')}</span>
      },
    },
  ]

  // const logPag = e => {
  //   if (e.current > 1) {
  //     if (e.current === 2) {
  //       setIsPageSize(e.pageSize)
  //     } else {
  //       setIsPageSize(e.pageSize + e.current)
  //     }
  //   } else {
  //     setIsPageSize(0)
  //   }
  // }

  return (
    <div>
      <Table
        dataSource={props.withDepData}
        columns={columns}
        size="small"
        loading={props.loading}
        className="justify-content-center"
        pagination={{ position: [state.top, state.bottom], pageSize: 100 }}
        // onChange={logPag}
      />
    </div>
  )
}

export default DepWithTable
