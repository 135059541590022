import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { Badge } from 'reactstrap'
import { Form, Button, Col, Row } from 'react-bootstrap'
import { Table } from 'antd'
import { useDispatch } from 'react-redux'

import { AssignLog } from '../../api/cli/AssignLog'
import { ActivityLog, ActivityLogCount } from '../../api/query/operators'
import { myCase } from '../../Store/Actions/Members'

import ModalForward from '../Modals/Forwarding/index'

function Detail(props) {
  const dispatch = useDispatch()

  const { getAssignLog, data: assignlog, loading: loadingAssignLog } = AssignLog()
  const { getActivityLog, data: activitylog, loading: loadingActivityLogg } = ActivityLog()
  const { getActivityLogCount, data: activitylogcount } = ActivityLogCount()
  const [HeadTable, setHeadTable] = useState([])
  const [pageDetail, setPage] = useState({
    pageNumber: 1,
    totalPage: 0,
  })

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [modalForword, setModalForword] = useState(false)

  const setData = () => {
    var set = { operatorId: { equals: props.data?.operators?.id } }
    if (document.getElementById('activity').value != '') {
      set['activity'] = { equals: document.getElementById('activity').value }
    }

    if (endDate != null && startDate != null) {
      set['createdAt'] = {
        gte: startDate,
        lte: endDate,
      }
    } else {
      if (endDate == null && startDate != null) {
        setEndDate(startDate)
        set['createdAt'] = {
          gte: startDate,
          lte: startDate,
        }
      }
    }
    return set
  }

  const columns = () => {
    var head = []
    switch (props.type) {
      case 'LogActivityDetail':
        head = [
          {
            title: 'No.',
            key: 'key',
            align: 'center',
            render: (value, row, index) => (
              <span>
                {(pageDetail.pageNumber == 1
                  ? pageDetail.pageNumber * 1 - 1
                  : (pageDetail.pageNumber * 1 - 1) * 10) +
                  (index + 1)}
              </span>
            ),
          },
          {
            title: 'Last Update',
            render: (value, row, index) => (
              <span key={index}> {moment(value?.createdAt).format('DD/MM/YYYY, HH:mm:ss')}</span>
            ),
          },
          {
            title: 'Status Update',
            render: (value, row, index) => <span key={index}>{value?.activity}</span>,
          },
        ]
        break
      case 'LogAssignDetail':
        head = [
          {
            title: 'No.',
            key: 'key',
            align: 'center',
            render: (value, row, index) => (
              <span key={index}>
                {(pageDetail.pageNumber == 1
                  ? pageDetail.pageNumber * 1 - 1
                  : (pageDetail.pageNumber * 1 - 1) * 10) +
                  (index + 1)}
              </span>
            ),
          },
          {
            title: 'Name',
            render: (value, row, index) => <span key={index}> {value.displayName}</span>,
          },
          {
            title: 'Log Assign',
            align: 'center',
            render: (record, index) => {
              return (
                <Row key={index}>
                  <Col onClick={() => setChatForword(record)}>
                    {/* <i className="ti ti-eye pointer"></i> */}
                    <Badge color="dark" className="setButtomProcessMsg" pill>
                      <i className="ti ti-more"></i>
                    </Badge>
                  </Col>
                </Row>
              )
            },
          },
        ]
        break
      default:
        break
    }
    setHeadTable(head)
  }

  const setSearch = e => {
    switch (props.type) {
      case 'LogActivityDetail':
        var set = { operatorId: { equals: props.data?.operators?.id } }
        if (document.getElementById('activity').value != '') {
          set['activity'] = { equals: document.getElementById('activity').value }
        }

        if (endDate != null && startDate != null) {
          set['createdAt'] = {
            gte: startDate,
            lte: endDate,
          }
        } else {
          if (endDate == null && startDate != null) {
            setEndDate(startDate)
            set['createdAt'] = {
              gte: startDate,
              lte: startDate,
            }
          }
        }

        getActivityLog({
          variables: {
            where: setData(),
            take: 10,
            skip:
              pageDetail.pageNumber == 1
                ? pageDetail.pageNumber * 1 - 1
                : (pageDetail.pageNumber * 1 - 1) * 10,
          },
        })

        if (startDate == null && endDate != null) {
          setStartDate(endDate)
        }

        if (startDate != null && endDate == null) {
          setEndDate(startDate)
        }

        getActivityLogCount({
          variables: {
            where: set,
          },
        })

        handleTableChange({
          current: e?.target.id == 'btnSearch' ? 1 : pageDetail.pageNumber,
          total: activitylogcount?.operatorActivityCount?.total,
          pageSize: 10,
        })

        break

      case 'LogAssignDetail':
        handleTableChange({ current: 1, total: 10, pageSize: 10 })

        break

      default:
        return null
        break
    }
  }

  const handleTableChange = e => {
    var set = pageDetail
    set.pageNumber = e.current
    set.totalPage =
      props.type == 'LogActivityDetail'
        ? activitylogcount?.operatorActivityCount?.total
        : assignlog?.operatorMembers.length
    setPage(set)

    if (props.type != 'LogActivityDetail') {
      getAssignLog({
        variables: {
          data: {
            operatorId: props.data?.operators?.id,
          },
        },
      })
    } else {
      getActivityLog({
        variables: {
          where: setData(),
          take: 10,
          skip:
            pageDetail.pageNumber == 1
              ? pageDetail.pageNumber * 1 - 1
              : (pageDetail.pageNumber * 1 - 1) * 10,
        },
      })
    }
  }

  const setChatForword = val => {
    dispatch(myCase([val]))
    setModalForword(true)
  }

  useEffect(() => {
    if (props.display == true) {
      columns()
      setSearch(null)
    }
  }, [props.display])

  return (
    <div style={{ display: props.display ? 'block' : 'none' }}>
      <br />
      <Row>
        <Col lg={2}>
          <b>Name :</b>
          <span style={{ marginLeft: '5%' }} className={'text-h'}>
            {props.data?.operators?.username}
          </span>
        </Col>
        <Col lg={2} className={'text-right'}>
          <b>Organization :</b>
        </Col>
        <Col lg={2} className={'text-h'}>
          {props.data?.operators?.contact.organisation.name}
        </Col>
        <Col lg={2}>
          <b>Role :</b>
          <span style={{ marginLeft: '5%' }} className={'text-h'}>
            {props.data?.operators?.role}
          </span>
        </Col>
      </Row>
      <Row>
        <Col lg={2}>
          <b>Assign :</b>
          <span style={{ marginLeft: '5%' }} className={'text-h'}>
            {props.data?.operators?.members.length}
          </span>
        </Col>
        <Col lg={2} className={'text-right'}>
          <b>Point :</b>
        </Col>
        <Col lg={2} className={'text-h'}>
          {props.data?.operators?.points}
        </Col>
      </Row>

      <Form
        style={
          props.type == 'LogActivityDetail'
            ? { marginTop: '2rem' }
            : { marginTop: '2rem', display: 'none' }
        }
      >
        <Form.Row>
          <Form.Group as={Col} lg={2} controlId="activity">
            <Form.Control as="select">
              <option value=""> All Status </option>
              <option value={'AUTO_OFFLINE'}>AUTO_OFFLINE</option>
              <option value={'AUTO_ASSIGN'}>AUTO_ASSIGN</option>
              <option value={'MANAUL_ASSIGN'}>MANAUL_ASSIGN</option>
              <option value={'CLOSE_JOB'}>CLOSE_JOB</option>
              <option value={'TRANSFER'}>TRANSFER</option>
              <option value={'BREAK'}>BREAK</option>
              <option value={'PAUSE'}>PAUSE</option>
              <option value={'BE_RIGHT_BACK'}>BE_RIGHT_BACK</option>
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} lg={2} controlId="startDate">
            <DatePicker
              locale="th"
              selected={startDate == null && endDate != null ? endDate : startDate}
              onChange={e => setStartDate(e)}
              dateFormat="dd/MM/yyyy"
              className="form-control"
              placeholderText="Start Date"
              block
            />
          </Form.Group>
          <Form.Group as={Col} lg={2} controlId="status">
            <DatePicker
              locale="th"
              selected={startDate > endDate ? startDate : endDate}
              onChange={date => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
              className="form-control"
              minDate={startDate}
              placeholderText="End date"
              block
            />
          </Form.Group>

          <Form.Group as={Col} lg={2} controlId="status">
            <Button variant="primarys" size="md" onClick={e => setSearch(e)} id="btnSearch">
              Search
            </Button>
          </Form.Group>
        </Form.Row>
      </Form>

      {loadingAssignLog && <p>Loading...</p>}

      <Row>
        <Col>
          <Table
            columns={HeadTable}
            loading={props.type == 'LogActivityDetail' ? loadingActivityLogg : loadingAssignLog}
            dataSource={
              props.type == 'LogActivityDetail'
                ? activitylog?.operatorActivities
                : assignlog?.operatorMembers
            }
            pagination={{
              total:
                props.type == 'LogActivityDetail'
                  ? activitylogcount?.operatorActivityCount?.total
                  : assignlog?.operatorMembers.length,
              pageSize: 10,
              current: pageDetail.pageNumber,
            }}
            onChange={e => handleTableChange(e)}
          />
        </Col>
      </Row>
      <ModalForward modalForword={modalForword} setModalForword={setModalForword} />
    </div>
  )
}

export default Detail
