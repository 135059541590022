const scr = 'only screen'

const mediaRange = {
  small: { min: 0, max: 340 },
  medium: { min: 641, max: 1024 },
  large: { min: 1025, max: 9999 },
}
const minWidth = mediaRange => `and (min-width: ${mediaRange.min}px)`
// const maxWidth = mediaRange => `and (max-width: ${mediaRange.max}px)`

export const MEDIA_SMALL = `${scr} ${minWidth(mediaRange.small)}`
export const MEDIA_MEDIUM_UP = `${scr} ${minWidth(mediaRange.medium)}`
export const MEDIA_LARGE_UP = `${scr} ${minWidth(mediaRange.large)}`

export const colors = {
  p1: '#fff',
  btn1: '#051e76',
}
