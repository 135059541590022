import React from 'react'
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
  Legend,
} from 'recharts'

const CustomizedLabel = props => {
  const { x, y, stroke, value } = props

  return (
    <text
      x={x}
      y={y}
      dy={-4}
      fill={stroke}
      fontSize={10}
      textAnchor="middle"
      fontWeight="bolder"
      className="pb-3"
    >
      {value} point
    </text>
  )
}

function ChartUser(props) {
  return (
    <div>
      <ResponsiveContainer width="99%" aspect={3}>
        <AreaChart
          data={test}
          margin={{
            top: 16,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" interval="preserveStartEnd" padding={{ left: 30, right: 30 }} />
          <YAxis interval="preserveStartEnd" />
          <Tooltip />
          {/* <ReferenceLine x={max.month} stroke="red" /> */}
          {/* <ReferenceLine y={max.point} label="MAX" stroke="red" /> */}
          <Legend />

          <Area
            type="monotone"
            dataKey="point"
            name="Point"
            stroke="green"
            strokeWidth={2}
            // activeDot={{ r: 8 }}
            label={<CustomizedLabel />}
            fill="#4abb4a"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

const test = [
  {
    month: 'ม.ค.',
    point: 100,
  },
  {
    month: 'ก.พ.',
    point: 79,
  },
  {
    month: 'มี.ค.',
    point: 103,
  },
  {
    month: 'เม.ย.',
    point: 53,
  },
  {
    month: 'พ.ค',
    point: 0,
  },
  {
    month: 'มิ.ย.',
    point: 0,
  },
  {
    month: 'ก.ค.',
    point: 0,
  },
  {
    month: 'ส.ค.',
    point: 0,
  },
  {
    month: 'ก.ย.',
    point: 0,
  },
  {
    month: 'ต.ค.',
    point: 0,
  },
  {
    month: 'พ.ย.',
    point: 0,
  },
  {
    month: 'ธ.ค.',
    point: 0,
  },
]

export default ChartUser
