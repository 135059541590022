import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Table } from 'antd'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import { MenuOutlined } from '@ant-design/icons'
import arrayMove from 'array-move'

import { Alert, Button } from 'react-bootstrap'
import Swal from 'sweetalert2/dist/sweetalert2'

import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import {
  CREATE_ONE_TEMPLATE,
  UPDATE_ONE_TEMPLATE,
} from '../../../../api/mutation/createOneTemplate'
import { selectedTemplateAction } from '../../../../Store/Actions/Template'
import { TEMPLATES } from '../../../../api/query/templates.ts'
import { bool } from 'prop-types'

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
))

const SortableItem = sortableElement(props => <tr {...props} />)
const SortableContainer = sortableContainer(props => <tbody {...props} />)

const TemplateTableBody = props => {
  const dispatch = useDispatch()

  const { selectedItems, selectedTemplateItem } = useSelector(state => state)
  const [templateItems, setTemplateItems] = useState([])
  const [isWarning, setIsWarning] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [getTemplates] = useLazyQuery(TEMPLATES, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setIsLoading(false)
      const res = data.templates
      dispatch(selectedTemplateAction(res))

      props.formModalToggle('')
      props.clearForm()
    },
  })

  const [createTemp] = useMutation(CREATE_ONE_TEMPLATE, {
    onCompleted: data => {
      setIsLoading(false)
      getTemplates()
    },
    onError: err => {
      // console.log('Err: ', err)
      setIsLoading(false)
    },
  })

  const [updateTemp] = useMutation(UPDATE_ONE_TEMPLATE, {
    onCompleted: data => {
      setIsLoading(false)
      getTemplates()
    },
    onError: err => {
      // console.log('Err: ', err)
      setIsLoading(false)
    },
  })

  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 80,
      ellipsis: { showTitle: false },
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'Type',
      dataIndex: 'text',
      width: 120,
      ellipsis: false,
      className: 'drag-visible',
    },
    {
      title: 'Body',
      dataIndex: 'body',
      ellipsis: { showTitle: true },
      className: 'drag-visible',
      render: (body, val) => {
        return (
          <>
            {val.type === 'image' ? (
              <img src={body} alt="img" height={40} />
            ) : (
              <span className="over-txt2">{body}</span>
            )}
          </>
        )
      },
    },
    {
      title: 'Actions',
      width: 140,
      align: 'center',
      render: (a, b) => {
        return (
          <div className="d-flex justify-content-around">
            {props.header !== 'View' && (
              <i className="ti ti-trash pointer" onClick={() => removeItem(a)}></i>
            )}
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    if (selectedItems.text) {
      const i = templateItems.length === 0 ? templateItems.length + 1 : templateItems.length + 2
      const res = {
        key: i,
        index: i,
        text: selectedItems.text,
        body: selectedItems.body,
        type: selectedItems.type,
      }

      if (templateItems.length === 0) {
        setTemplateItems([res])
        setIsWarning(false)
      } else if (templateItems.length < 5) {
        setTemplateItems([...templateItems, res])
        setIsWarning(false)
      } else if (templateItems.length >= 5) {
        setIsWarning(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems])

  useEffect(() => {
    if (selectedTemplateItem) {
      const arr = []
      const items = selectedTemplateItem.templateItems

      for (let i = 0; i < items.length; i++) {
        const res = {
          id: items[i].id,
          key: i,
          index: i,
          text: items[i].templateItemType,
          body:
            items[i].templateItemType === 'IMAGE'
              ? items[i].body.previewImageUrl
              : items[i].body.text,
          type: items[i].body.type,
        }
        arr.push(Object.assign({}, res))
      }
      setTemplateItems(arr)
    }
  }, [selectedTemplateItem])

  const [removeItems, setRemoveItems] = useState([])

  const removeItem = e => {
    const res = templateItems.filter(items => items.key !== e.key)
    const id = { id: e.id }
    setRemoveItems([...removeItems, id])
    setTemplateItems(res)
  }

  const submitTemplate = () => {
    setIsLoading(true)

    const create = []
    if (props.name === '') {
      Swal.fire({
        title: 'Error!',
        text: 'กรุณาระบุชื่อ Template',
        icon: 'error',
        confirmButtonText: 'ตกลง',
      })
      return
    } else if (!props.channel) {
      Swal.fire({
        title: 'Error!',
        text: 'กรุณาระบุ channel',
        icon: 'error',
        confirmButtonText: 'ตกลง',
      })
      return
    } else if (templateItems.length > 0) {
      for (let i = 0; i < templateItems.length; i++) {
        const val = {
          // id: templateItems[i].id,
          templateItemType: templateItems[i].text,
          sequences: i + 1,
          body:
            templateItems[i].text === 'TEXT'
              ? {
                  type: templateItems[i].type,
                  text: templateItems[i].body,
                }
              : templateItems[i].body,
        }
        create.push(Object.assign({}, val))
      }
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'กรุณาเพิ่มข้อความหรือรูปภาพ',
        icon: 'error',
        confirmButtonText: 'ตกลง',
      })
    }

    if (props.header === 'Edit') {
      const arrDel = []
      const deleteItem = templateItems.filter(e => e.id)
      for (let i = 0; i < deleteItem.length; i++) {
        const itemId = { id: templateItems[i].id }
        arrDel.push(Object.assign({}, itemId))
      }

      for (let l = 0; l < removeItems.length; l++) {
        const itemId = { id: removeItems[l].id }
        arrDel.push(Object.assign({}, itemId))
      }

      try {
        updateTemp({
          variables: {
            where: {
              id: props.id,
            },
            data: {
              name: {
                set: props.name,
              },

              channel: {
                connect: {
                  id: Number(props.channel),
                },
              },
              isActive:{
                set: (props.status == "true" ? true : false)
              },

              templateItems: {
                update: [],
                create: create,
                delete: arrDel,
              },
            },
          },
        })
      } catch (err) {
        // console.log('Err: ', err)
      }
    } else {
      try {
        createTemp({
          variables: {
            data: {
              name: props.name,
              isActive: props.status === '' ? true : props.status,
              channel: { connect: { id: Number(props.channel) } },
              refCode: '',
              templateItems: {
                create: create,
              },
            },
          },
        })
      } catch (err) {
        // console.log('Err: ', err)
      }
    }
  }

  class SortableTable extends React.Component {
    state = {
      dataSource: templateItems,
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
      const { dataSource } = this.state
      if (oldIndex !== newIndex) {
        const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el)
        setTemplateItems(newData)
      }
    }

    DraggableContainer = props => (
      <SortableContainer
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={this.onSortEnd}
        {...props}
      />
    )

    DraggableBodyRow = ({ className, style, ...restProps }) => {
      const { dataSource } = this.state
      // function findIndex base on Table rowKey props and should always be a right array index
      const index = dataSource.findIndex(x => x.index === restProps['data-row-key'])
      return <SortableItem index={index} {...restProps} />
    }

    render() {
      const { dataSource } = this.state

      return (
        <Table
          pagination={false}
          dataSource={dataSource}
          columns={columns}
          rowKey="index"
          components={{
            body: {
              wrapper: this.DraggableContainer,
              row: this.DraggableBodyRow,
            },
          }}
        />
      )
    }
  }

  return (
    <>
      {isWarning && (
        <Alert key={1} variant="warning">
          ไม่สามารถเพิ่มเนื้อหาเกิน 5!
        </Alert>
      )}

      <SortableTable />

      <Button
        variant="primary"
        onClick={submitTemplate}
        block
        disabled={props.header === 'View' ? true : false}
      >
        {isLoading ? 'กำลังบันทึกข้อมูล...' : 'บันทึก Template'}
      </Button>
    </>
  )
}

export default TemplateTableBody
