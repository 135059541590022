import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useSubscription } from '@apollo/react-hooks'

import Loading from '../../Loading'
import { Box } from '../../../assets/styles/navbar'
import { ON_OPERATORS } from '../../../api/cli/onOperator'
import { Operators } from '../../../api/cli/Operators'

import { operatorOnlineAction } from '../../../Store/Actions/Operator/index'

const Online = () => {
  const dispatch = useDispatch()

  const { loading } = Operators()
  const { operatorOnline, selectedOperator } = useSelector(state => state)

  useSubscription(ON_OPERATORS, {
    fetchPolicy: 'no-cache',
    variables: {
      data: {
        orgId: selectedOperator?.contact?.organisation?.id,
      },
    },
    onSubscriptionData: async data => {
      const res = await data?.subscriptionData?.data?.onOperatorOnline
      const notRes = await operatorOnline.filter(e => e.id !== res.id)
      await notRes.push(Object.assign({}, res))
      dispatch(operatorOnlineAction(notRes))
    },
  })

  return (
    <>
      {loading ? (
        <Row>
          <Col>
            <Loading />
          </Col>
        </Row>
      ) : (
        <Row className="ml-3 overflow-scroll" style={{ marginTop: '12px' }}>
          {operatorOnline?.map((e, i) => (
            <Col key={i} className="px-1">
              <Box
                status={e.status === 'ONLINE' ? 'on' : e.status !== 'OFFLINE' ? 'pause' : 'offline'}
              >
                {e.username}
              </Box>
            </Col>
          ))}
        </Row>
      )}
    </>
  )
}

export default Online
