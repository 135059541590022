import { loadState } from '../../../utils/localStorage'

export const pinListReducer = (state = loadState() ? loadState() : [], action) => {
  switch (action.type) {
    case 'PIN_LIST':
      return action.list
    default:
      return state
  }
}
