import React, { useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import moment from 'moment-timezone'
import { useDispatch, useSelector } from 'react-redux'
import { useSubscription } from '@apollo/react-hooks'

import PinChat from '../Pin'

import imgErr from '../../../../utils/imgErr'
import { ON_MEMBER } from '../../../../api/query/members'
import { ON_MEMBER_ME } from '../../../../api/query/members'

import { myCase } from '../../../../Store/Actions/Members/index'
import { memberNewListAction } from '../../../../Store/Actions/Members/index'
import { saveState } from '../../../../utils/localStorage'

const NewChatAll = props => {
  const dispatch = useDispatch()
  const {
    myCaseList,
    memberListDone,
    selectedChat,
    memberNewList,
    memberLoading,
    myMemberLoading,
    selectedOperator,
  } = useSelector(state => state)

  //WSS get new case to list
  const { data: onMem } = useSubscription(ON_MEMBER, {
    fetchPolicy: 'network-only',
    variables: {
      data: {
        channelId: 0,
      },
    },
    onSubscriptionData: data => {
      const res = data?.subscriptionData?.data?.onMember
      const arr = memberNewList.filter(e => e.id !== res?.id)
      arr.push(Object.assign({}, res))
      // arr.sort((a, b) => moment(a.lastChatTime).valueOf() - moment(b.lastChatTime).valueOf())
      dispatch(memberNewListAction(arr))
    },
  })

  //WSS get myCase to list
  const { data: onMemMe } = useSubscription(ON_MEMBER_ME, {
    fetchPolicy: 'network-only',
    variables: {
      data: {
        channelId: 0,
        operatorId: selectedOperator?.id,
      },
    },
    onSubscriptionData: data => {
      const res = data?.subscriptionData?.data?.onMemberMe
      const arr = myCaseList.filter(e => e.id !== res?.id)
      arr.push(Object.assign({}, res))
      // arr.sort((a, b) => moment(b.lastChatTime).valueOf() - moment(a.lastChatTime).valueOf())
      dispatch(myCase(arr)) //add myCast to list
    },
  })

  // useEffect(() => {
  //   if (onMemMe) {
  //     const res = onMemMe?.onMemberMe
  //     const arr = myCaseList.filter(e => e.id !== res?.id)
  //     arr.push(Object.assign({}, res))
  //     arr.sort((a, b) => moment(b.lastChatTime).valueOf() - moment(a.lastChatTime).valueOf())
  //     dispatch(myCase(arr)) //add myCast to list
  //   }
  // }, [onMemMe])

  // useEffect(() => {
  //   if (onMem) {
  //     const res = onMem?.onMember
  //     const arr = memberNewList.filter(e => e.id !== res?.id)
  //     arr.push(Object.assign({}, res))
  //     arr.sort((a, b) => moment(a.lastChatTime).valueOf() - moment(b.lastChatTime).valueOf())
  //     dispatch(memberNewListAction(arr))
  //     // onMessToPin(res)
  //   }
  // }, [onMem])

  // const convertDate = d => {
  //   var momente = require('moment')
  //   return momente(d).format('lll')
  // }

  // const onMessToPin = async res => {
  //   if (props.data.length > 0) {
  //     const pin = await props.data.filter(ee => ee.id !== res?.id)
  //     const pin_macth = await props.data.filter(ee => ee.id === res?.id)
  //     if (pin_macth.length > 0) {
  //       await pin.push(Object.assign({}, res))
  //       saveState(pin)
  //       props.setData(pin)
  //     }
  //   }
  // }

  const renderMembers = (member, i) => {
    return (
      <li
        key={i}
        className={
          'cursor px-1 list-group-item ' + (member?.id === selectedChat?.id ? 'open-chat' : '')
        }
        onClick={() => props.chatSelectHandle(member)}
      >
        <div className="d-flex justify-content-between">
          <figure className="avatar">
            {member.profileImage && (
              <img
                src={member.profileImage}
                onError={imgErr}
                alt="profile"
                width="100%"
                height="100%"
                className="rounded-circle"
                loading="lazy"
              />
            )}
          </figure>

          <div className="users-list-body">
            <h6 className="over-txt2 mb-0">{member?.displayName}</h6>

            {member.lastChatMessage === 'Image' ? (
              <i className="fa fa-camera mr-1 mt-1 text-secondary">
                <span> ส่งรูปภาพ</span>
              </i>
            ) : (
              <>
                {member.lastChatMessage === 'Sticker' ? (
                  <i className="ti ti-face-smile mr-1 mt-1 text-secondary">
                    <span> ส่งสติ๊กเกอร์</span>
                  </i>
                ) : (
                  <>
                    <p className="over-txts">{member.lastChatMessage}</p>
                  </>
                )}
              </>
            )}

            <div className="users-list-action action-toggle">
              {member.unreadChatMessage ? (
                <div className="rounded-circle text-center count-unread-small bg-success">
                  {member.unreadChatMessage < 100 ? member.unreadChatMessage : '99+'}
                </div>
              ) : (
                <div
                  className={
                    member.unreadChatMessage > 0
                      ? 'rounded-circle text-center text-light count-unread-small'
                      : 'rounded-circle text-center text-light'
                  }
                >
                  <span className="text-light">
                    {member.unreadChatMessage > 0 ? member.unreadChatMessage : null}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="text-right w-100">
          <span className="badge badge-dark">{member?.channel.name}</span>
          <p className="text-dark mb-0 cut_txt">
            {moment(member?.lastChatTime).startOf('hour').fromNow()}
          </p>
          {/* <i
            className="ti ti-pin2 btn px-2"
            style={{ paddingTop: '4px' }}
            onClick={() => props.pinMember(member)}
          /> */}
        </div>
      </li>
    )
  }

  return (
    <PerfectScrollbar className="animate__animated animate__fadeIn">
      {props.tab === 1 ? (
        <ul className="list-group list-group-flush">
          <PinChat
            chatSelectHandle={props.chatSelectHandle}
            data={props.data}
            remMember={props.remMember}
          />

          {myMemberLoading ? (
            <div className="text-center mt-4">
              <h6 className="text-secondary animate__animated animate__heartBeat animate__infinite infinite">
                กำลังโหลดข้อมูลลูกค้าของท่าน...
              </h6>
            </div>
          ) : (
            <>
              {myCaseList?.length > 0 ? (
                myCaseList
                  ?.filter(mem =>
                    mem?.displayName.toLowerCase().includes(props.search.toLowerCase()),
                  )
                  ?.filter(mem =>
                    mem.channelRefCode.toLowerCase().includes(props.searchChannel.toLowerCase()),
                  )
                  ?.filter(mem => mem.operatorId === selectedOperator.id)
                  // ?.sort((a, b) => moment(b.lastChatTime) - moment(a.lastChatTime))
                  .map((member, i) => (
                    <div className="text-light" key={i} style={{ background: '#b2e9e2e1' }}>
                      {renderMembers(member, i)}
                    </div>
                  ))
              ) : (
                <div className="text-center mt-4 pb-3">
                  <h6 className="text-secondary">คุณยังไม่มีลูกค้าในเคส</h6>
                </div>
              )}
            </>
          )}

          {memberLoading ? (
            <div className="text-center mt-4">
              <h6 className="text-secondary animate__animated animate__heartBeat animate__infinite infinite">
                กำลังโหลดข้อมูลลูกค้าทั่วไป...
              </h6>
            </div>
          ) : (
            <>
              {memberNewList?.length > 0 ? (
                memberNewList
                  ?.filter(mem =>
                    mem?.displayName.toLowerCase().includes(props.search.toLowerCase()),
                  )
                  ?.filter(mem =>
                    mem.channelRefCode.toLowerCase().includes(props.searchChannel.toLowerCase()),
                  )
                  // ?.sort((a, b) => moment(a.lastChatTime) - moment(b.lastChatTime))
                  .map((member, i) => <div key={i}>{renderMembers(member, i)}</div>)
              ) : (
                <div className="text-center mt-4">
                  <h6 className="text-secondary">ไม่ข้อความจากลูกค้า</h6>
                </div>
              )}
            </>
          )}
        </ul>
      ) : (
        <ul className="list-group list-group-flush">
          {memberListDone?.length > 0 ? (
            memberListDone
              ?.filter(mem => mem.displayName.toLowerCase().includes(props.search.toLowerCase()))
              ?.filter(mem =>
                mem.channelRefCode.toLowerCase().includes(props.searchChannel.toLowerCase()),
              )
              .sort((a, b) => moment(a.lastChatTime) - moment(b.lastChatTime))
              .map((chat, i) => props.MemberListView(chat))
          ) : (
            <div className="text-center mt-4">
              <h6 className="text-secondary">ไม่พบข้อมูล</h6>
            </div>
          )}
        </ul>
      )}
    </PerfectScrollbar>
  )
}

export default NewChatAll
