const userReducer = (
  state = {
    email: '',
    email_verified: Boolean,
    name: '',
    nickname: '',
    picture: '',
    sub: '',
    updated_at: '',
  },
  action,
) => {
  switch (action.type) {
    case 'USER':
      return action.user
    default:
      return state
  }
}

export default userReducer
