// import { useEffect } from 'react'
import { gql } from 'apollo-boost'
// import { useDispatch  } from 'react-redux'
import { useLazyQuery } from '@apollo/client'

export const OPERATORS = gql`
  query operators($where: OperatorWhereInput, $take: Int, $skip: Int) {
    operators(where: $where, take: $take, skip: $skip, orderBy: { updatedAt: desc }) {
      id
      key
      username
      isActive
      role
      status
      updatedAt
      members {
        id
        displayName
        assignType
        assignTime
        channel {
          name
        }
      }
      contact {
        id
        username
        firstName
        nickname
        lastName
        mobile
        lineID
        email
        bankAccId
        bankAccount {
          id
          name
          number
          bankCode
          createdAt
          updatedAt
          isActive
        }
        organisation {
          id
          name
          isActive
        }
      }
      contactId
      points
    }
  }
`

export const OPERATORS_DATA = gql`
  query operatorData($data: operatorData) {
    operatorData(data: $data)
  }
`

export const CREATE_ONE_OPERATOR = gql`
  mutation createOneOperator($data: OperatorCreateInput!) {
    createOneOperator(data: $data) {
      id
      username
    }
  }
`

export const OPERATOR_ONLINE = gql`
  query operatorsOnline($where: OperatorWhereInput) {
    operatorsOnline(where: $where)
  }
`

export const OPERATOR_ACTIVITIES = gql`
  query operatorActivities($where: OperatorActivityWhereInput, $take: Int, $skip: Int) {
    operatorActivities(where: $where, take: $take, skip: $skip, orderBy: { updatedAt: desc }) {
      id
      memberId
      operatorId
      activity
      createdAt
    }
  }
`

export const OPERATOR_ACTIVITIES_COUNT = gql`
  query operatorActivityCount($where: OperatorActivityWhereInput) {
    operatorActivityCount(where: $where)
  }
`

export const OPERATOR_COUNT = gql`
  query operatorCount($where: OperatorWhereInput) {
    operatorCount(where: $where)
  }
`

export const OPERATOR_MEMBER = gql`
  query operatorMembers($data: operatorMembers) {
    operatorMembers(data: $data)
  }
`

// OPERATORS

export const ActivityLog = () => {
  const [getActivityLog, { data, loading }] = useLazyQuery(OPERATOR_ACTIVITIES, {
    fetchPolicy: 'no-cache',
  })
  return {
    getActivityLog,
    data,
    loading,
  }
}

export const ActivityLogCount = () => {
  const [getActivityLogCount, { data, loading }] = useLazyQuery(OPERATOR_ACTIVITIES_COUNT, {
    fetchPolicy: 'no-cache',
  })
  return {
    getActivityLogCount,
    data,
    loading,
  }
}

export const ConditionOperator = () => {
  const [getConditionOperator, { data, loading }] = useLazyQuery(OPERATORS, {
    fetchPolicy: 'no-cache',
  })
  return {
    getConditionOperator,
    data,
    loading,
  }
}

export const ConditionOperatorCount = () => {
  const [getConditionOperatorCount, { data, loading }] = useLazyQuery(OPERATOR_COUNT, {
    fetchPolicy: 'no-cache',
  })
  return {
    getConditionOperatorCount,
    data,
    loading,
  }
}

export const Operator = () => {
  const [getOperator, { data, loading }] = useLazyQuery(OPERATORS, {
    fetchPolicy: 'no-cache',
  })
  return {
    getOperator,
    data,
    loading,
  }
}
