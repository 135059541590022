import { gql } from 'apollo-boost'
import { useDispatch } from 'react-redux'
import { useLazyQuery, useQuery } from '@apollo/client'

import { selectedTemplateAction } from '../../Store/Actions/Template/index'

export const TEMPLATES = gql`
  query templates($where: TemplateWhereInput, $take: Int, $skip: Int) {
    templates(where: $where, orderBy: { updatedAt: desc }, take: $take, skip: $skip) {
      id
      key
      refCode
      name
      channel {
        id
        refCode
        name
      }
      templateItems {
        id
        refCode
        tempId
        templateItemType
        body
        sequences
        createdAt
        updatedAt
        isActive
      }
      createdAt
      updatedAt
      isActive
    }
  }
`

export const GetTemplates = () => {
  const dispatch = useDispatch()
  const { data, loading } = useQuery(TEMPLATES, {
    fetchPolicy: 'no-cache',
    variables: {
      take: 5,
      skip: 0,
    },
    onCompleted: data => {
      const res = data.templates
      dispatch(selectedTemplateAction(res))
    },
  })

  return {
    data,
    loading,
  }
}

export const GetTypeTemplates = () => {
  const dispatch = useDispatch()
  const [getTypeTemplate, { data, loading }] = useLazyQuery(TEMPLATES, {
    fetchPolicy: 'no-cache',
    variables: {
      // take: 5,
      // skip: 0,
    },
    // onCompleted: data => {
    //   const res = data.templates
    //   dispatch(selectedTemplateAction(res))
    // },
  })

  return {
    getTypeTemplate,
    data,
    loading,
  }
}
