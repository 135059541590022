import React from 'react'
import { Form, Input, Button } from 'antd'

function FormSearchMember(props) {
  // const [form] = Form.useForm()

  const checkMobileNumber = (rule, value) => {
    if (!value) {
      return Promise.reject('กรุณาระบุหมายเบอร์โทรศัพท์!')
    } else {
      const txt = value.match(/[^0-9]/gi)
      if (txt) {
        return Promise.reject('เบอร์โทรศัพท์ต้องเป็นตัวเลขเท่านั้น!')
      } else {
        if (value[0] !== '0') {
          return Promise.reject('เบอร์โทรศัพท์ต้องขึ้นต้นด้วย 0')
        } 
        // else if (value.length < 10) {
        //   return Promise.reject('เบอร์โทรศัพท์ต้องมีจำนวนไม่น้อยกว่า 10 ตัว!')
        // } else if (value.length > 10) {
        //   return Promise.reject('เบอร์โทรศัพท์ต้องมีจำนวนไม่เกิน 10 ตัว!')
        // } 
        else {
          props.setPhoneNo(value)
          return Promise.resolve()
        }
      }
    }
  }

  return (
    <div className="pl-3 pr-3">
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={props.syncProfile}
        // onFinishFailed={onFinishFailed}
      >

        {/* {console.log(props.phoneNo,'props.syncProfile')} */}
        <Form.Item name="mobile" rules={[{ required: true, validator: checkMobileNumber }]}>
          <div className="d-flex">
            <Input maxLength={10} placeholder="กรอกเบอร์โทรศัพท์ของลูกค้า" id="customer_tel" value={props.phoneNo}/>
            <Button type="primary" htmlType="submit">
              {props.syncLoad ? 'Loading...' : 'Sync'}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  )
}

export default FormSearchMember
