import * as React from 'react'
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, split } from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { setContext } from '@apollo/link-context'
import { useAuth0 } from '@auth0/auth0-react'
import { getMainDefinition } from '@apollo/client/utilities'

const APPSYNC_MAX_CONNECTION_TIMEOUT_MILLISECONDS = 30 * 1000

const ApolloProviderWithAuth0 = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0()
  const { REACT_APP_URI, REACT_APP_WSS } = process.env

  const httpLink = new HttpLink({
    uri: REACT_APP_URI,
    options: {
      reconnect: true,
    },
  })

  // const newWsLink = createAppSyncSubscriptionWebsocketLink({REACT_APP_WSS})

  const authLink = setContext(async (_, { headers, ...rest }) => {
    let token
    try {
      token = await getAccessTokenSilently()
      await localStorage.setItem('token', token)
    } catch (error) {
      console.log(error)
    }

    if (!token) return { headers, ...rest }

    return {
      ...rest,
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }
  })

  const wsLink = new WebSocketLink({
    uri: REACT_APP_WSS,
    options: {
      reconnect: true,
      timeout: APPSYNC_MAX_CONNECTION_TIMEOUT_MILLISECONDS,
      lazy: true,
      connectionParams: {
        headers: localStorage.getItem('token') ? 'Bearer ' + localStorage.getItem('token') : '',
      },
    },
  })

  const authWsLink = setContext(async (_, { headers, ...rest }) => {
    let token
    try {
      token = await getAccessTokenSilently()
      await localStorage.setItem('token', token)
    } catch (error) {
      console.log(error)
    }

    if (!token) return { headers, ...rest }

    return {
      ...rest,
      headers: {
        ...headers,
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      options: {
        reconnect: true,
        connectionParams: {
          headers: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    }
  })

  const client = React.useRef()
  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    // wsLink,
    authWsLink.concat(wsLink),
    // httpLink,
    authLink.concat(httpLink),
  )

  if (!client.current) {
    client.current = new ApolloClient({
      ssrMode: true,
      link,
      // link : authLink.concat(wsLink),
      cache: new InMemoryCache(),
      connectToDevTools: process.env.NODE_ENV === 'development',
    })
  }

  return <ApolloProvider client={client.current}>{children}</ApolloProvider>
}

export default ApolloProviderWithAuth0
