import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Row, Col, Badge } from 'react-bootstrap'
import { useSelector } from 'react-redux'

import ChartUser from './Chart'

function UserProfile(props) {
  const { editOperatorReducer } = useSelector(state => state)

  return (
    <Modal
      isOpen={
        props.formTempModalOpen && editOperatorReducer?.type === 'view' && props.activeTab === '3'
      }
      toggle={() => props.formModalToggle('')}
      centered
      className="modal-dialog-zoom"
      size="xl"
    >
      <ModalHeader toggle={() => props.formModalToggle('')}>
        <i className="ti ti-write"></i>
        {props.formHeader} Operator
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col lg={2} className="text-center">
            <img
              src="/icon/user.png"
              alt="profile"
              width="100px"
              height="100px"
              className="rounded-circle"
            />
          </Col>
          <Col>
            <Col lg={10} style={{ marginLeft: '8px' }} className="d-flex">
              <h3 className="mb-1">
                {editOperatorReducer?.data?.username.toUpperCase()}
                &nbsp; / &nbsp;
                {editOperatorReducer?.data?.contact?.organisation?.name.toUpperCase()}
              </h3>

              <h6 className="ml-2 mb-1">
                {editOperatorReducer?.data?.isActive ? (
                  <Badge pill variant="success">
                    ACTIVE
                  </Badge>
                ) : (
                  <Badge pill variant="danger">
                    DEACTIVE
                  </Badge>
                )}
              </h6>
            </Col>
            <Row className="ml-2 text-secondary">
              <Col lg={3}>
                <span>Name: </span>
                <span>
                  {editOperatorReducer?.data?.contact?.firstName
                    ? editOperatorReducer?.data?.contact?.firstName
                    : 'ไม่ระบุ'}
                </span>
              </Col>
              <Col lg={3}>
                <span>Nickname: </span>
                <span>
                  {editOperatorReducer?.data?.contact?.nickname
                    ? editOperatorReducer?.data?.contact?.nickname
                    : 'ไม่ระบุ'}
                </span>
              </Col>
              <Col lg={3}>
                <span>Lastname: </span>
                <span>
                  {editOperatorReducer?.data?.contact?.lastName
                    ? editOperatorReducer?.data?.contact?.lastName
                    : 'ไม่ระบุ'}
                </span>
              </Col>
            </Row>
            <Row className="ml-2 text-secondary">
              <Col lg={3}>
                <span>Mobile: </span>
                <span>
                  {editOperatorReducer?.data?.contact?.mobile
                    ? editOperatorReducer?.data?.contact?.mobile
                    : 'ไม่ระบุ'}
                </span>
              </Col>
              <Col lg={3}>
                <span>Email: </span>
                <span>
                  {editOperatorReducer?.data?.contact?.email
                    ? editOperatorReducer?.data?.contact?.email
                    : 'ไม่ระบุ'}
                </span>
              </Col>
              <Col lg={3}>
                <span>LineID: </span>
                <span>
                  {editOperatorReducer?.data?.contact?.lineID
                    ? editOperatorReducer?.data?.contact?.lineID
                    : 'ไม่ระบุ'}
                </span>
              </Col>
            </Row>
            <Row className="ml-2 text-secondary">
              <Col lg={3}>
                <span>BankAccId: </span>
                <span>
                  {editOperatorReducer?.data?.contact?.bankAccId
                    ? editOperatorReducer?.data?.contact?.bankAccId
                    : 'ไม่ระบุ'}
                </span>
              </Col>
              <Col lg={3}>
                <span>BankAccount: </span>
                <span>
                  {editOperatorReducer?.data?.contact?.bankAccount
                    ? editOperatorReducer?.data?.contact?.bankAccount
                    : 'ไม่ระบุ'}
                </span>
              </Col>
              <Col lg={3}></Col>
            </Row>
          </Col>
        </Row>
        <hr />

        <ChartUser />
      </ModalBody>
    </Modal>
  )
}

export default UserProfile
