export const memberLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case 'MEMBER_LOADING':
      return action.status
    default:
      return state
  }
}

export const myMemberLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case 'MY_MEMBER_LOADING':
      return action.status
    default:
      return state
  }
}
