import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Members from './Chats/index.jsx'

import { GetMembers } from '../../api/cli/Members'
import { GetMyMembers } from '../../api/cli/MyMembers'

import { memberLoadingAction, myMemberLoadingAction } from '../../Store/Actions/Loading/index'

function SlideBars() {
  const { selectedSidebar, mobileSidebar, selectedOperator } = useSelector(state => state)
  const { getMembers } = GetMembers()
  const { getMyMembers } = GetMyMembers()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(memberLoadingAction(true))
    dispatch(myMemberLoadingAction(true))

    if (selectedOperator?.id) {
      getMembers({
        variables: {
          where: {
            // channel: {
            //   organisationRefCode: {
            //     equals: selectedOperator?.contact?.organisationRefCode,
            //   },
            // },
            case: { equals: 'OPEN' },
          },
          take: 200,
        },
      })

      getMyMembers({
        variables: {
          where: {
            operatorId: {
              equals: selectedOperator?.id,
            },
            AND: {
              case: {
                equals: 'OPEN',
              },
            },
          },
          take: 5,
        },
      })
    }
  }, [selectedOperator])

  return (
    <div className={`sidebar-group ${mobileSidebar ? 'mobile-open' : ''}`}>
      {(() => {
        if (selectedSidebar.name === 'Chats') {
          return <Members />
        }
      })()}
    </div>
  )
}

export default SlideBars
