import { useState } from 'react'
import { gql } from 'apollo-boost'
import { useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/client'

import { operatorAction } from '../../Store/Actions/Operator/index'

export const OPERATORS_DATA = gql`
  query operatorData($data: operatorData) {
    operatorData(data: $data)
  }
`

export const GetOperator = () => {
  const dispatch = useDispatch()
  const [userId, setUserId] = useState(null)
  const [getOps] = useLazyQuery(OPERATORS_DATA, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const res = data?.operatorData
      dispatch(operatorAction(res))

      if (res?.id) {
        setUserId(res.id)
      }
    },
  })

  return { getOps, userId }
}

