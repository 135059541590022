import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Tabs, Tab, Spinner, Button } from 'react-bootstrap'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { Image } from 'antd'
import moment from 'moment'
import Swal from 'sweetalert2/dist/sweetalert2'

import { profileAction } from '../../../../Store/Actions/Profile'
import { selectedProfileAction } from '../../../../Store/Actions/Members'
import { mobileProfileAction } from '../../../../Store/Actions/mobileProfileAction'

import { USER } from '../../../../api/query/user'
import { WALLET } from '../../../../api/query/wallet'
import { WITH_DEP } from '../../../../api/query/withdep'
import { TURN_OVER } from '../../../../api/query/turnOver'
import { SYNC_PROFILE } from '../../../../api/mutation/syncProfile'

import DepWithTable from './DepWithTable'
import TurnOver from './TurnOver'
import FormSearch from './FormSearch'

import Balance from './Balance'
import FormSearchMember from './FormSearchMember'

import imgErr from '../../../../utils/imgErr'

function Profile() {
  const dispatch = useDispatch()
  const [withDepData, setWithDep] = useState([])
  const [turnOverData, setTurnOverData] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [phoneNo, setPhoneNo] = useState('')
  const [nowSelectTab, setNowSelectTab] = useState('deposit')
  const [count, setCount] = useState(0)
  const [pageSize, setPageSize] = useState(0)
  const [keepPageSize, setKeepPageSize] = useState(100)

  const { profileSidebar, selectedAbout, mobileProfileSidebar, selectedMemberId } = useSelector(
    state => state,
  )

  const [getWallet, { data: wallets, loading: loadWallet }] = useLazyQuery(WALLET, {
    fetchPolicy: 'no-cache',
  })
  const [getUserProfile, { data: user, loading: loadUser }] = useLazyQuery(USER, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const res = data.userForChat?.data
      dispatch(selectedProfileAction(res))
    },
  })
  const [getWithDep, { loading: loadWithDev }] = useLazyQuery(WITH_DEP, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const res = data?.userWitDepForChat?.data
      if (res) {
        setWithDep(res)
      }
    },
  })

  const [getTurnOver, { loading: loadTurnOver }] = useLazyQuery(TURN_OVER, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const res = data?.userTurnOver?.data.sort((a, b) => a.betAt - b.betAt)
      const cc = data?.userTurnOver?.count
      setTurnOverData(res)

      if (cc) {
        setCount(cc)
      }
    },
  })

  const refreshWallet = () => {
    getWallet({
      variables: {
        data: { memberId: selectedMemberId },
      },
    })
  }

  const refreshProfile = () => {
    refreshWallet()
    getUserProfile({
      variables: {
        data: { memberId: selectedMemberId },
      },
    })
  }

  const refreshDepWith = () => {
    getWithDep({
      variables: {
        data: {
          memberId: selectedMemberId,
          select: nowSelectTab,
          startDate: moment(startDate).utc().toDate(),
          endDate: moment(endDate).add(1, 'days').utc().toDate(),
        },
      },
    })
  }

  const profileActions = e => {
    e.preventDefault()
    dispatch(profileAction(false))
    dispatch(mobileProfileAction(false))
  }

  const [sync, { loading: syncLoad }] = useMutation(SYNC_PROFILE, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const res = data.syncProfile
      //ถ้าไม่เจอ user ใน Minions
      if (!res?.data) {
        Swal.fire({
          title: 'Error!',
          text: res?.errors[0].message,
          icon: 'error',
          confirmButtonText: 'ตกลง',
        })
      }
    },
    onError: err => {
      // console.log('B: ', err)
    },
  })

  const syncProfile = async () => {
    if (phoneNo) {
      try {
        const response = await sync({
          variables: {
            data: {
              memberId: selectedMemberId,
              mobile: phoneNo,
            },
          },
        })

        if (!response?.data?.syncProfile) {
          Swal.fire({
            title: 'Error!',
            text: 'ไม่พบข้อมูลเบอร์นี้',
            icon: 'error',
            confirmButtonText: 'ตกลง',
          })
        } else {
          refreshProfile()
          refreshWallet()
          setPhoneNo('')
        }
      } catch (error) {
        console.log('Err: ', error)
      }
    }
  }

  const userProfile = res => {
    return (
      <b style={{ fontSize: 16 }}>
        <div>
          <hr />
        </div>
        <div className="pl-3 pr-3 d-flex justify-content-between">
          <p className="mr-2 mb-1">Username : </p>
          <p className="mb-1">{res.username}</p>
        </div>
        <div className="pl-3 pr-3 d-flex justify-content-between">
          <p className="mr-2 mb-1">Fullname : </p>
          <p className="mb-1">{res.name}</p>
        </div>
        <div className="pl-3 pr-3 d-flex justify-content-between">
          <p className="mr-2 mb-1">Mobile : </p>
          <p className="mb-1">{res.contact?.mobile}</p>
        </div>
        <div className="pl-3 pr-3 d-flex justify-content-between">
          <p className="mr-2 mb-1">LineID : </p>
          <p className="mb-1">{res.contact?.lineID ? res.contact?.lineID : 'ยังไม่ได้ระบุ'}</p>
        </div>
        <div className="pl-3 pr-3 d-flex justify-content-between">
          <p className="mr-2 mb-1">Bank : </p>
          <p className="mb-1">{res.bankAccount?.bankName}</p>
        </div>

        <div className="pl-3 pr-3 d-flex justify-content-between">
          <p className="mr-2 mb-1">Account name : </p>
          <p className="mb-1">{res.bankAccount?.name}</p>
        </div>

        <div className="pl-3 pr-3 d-flex justify-content-between">
          <p className="mr-2 mb-1">Account id : </p>
          <p className="mb-1">{res.bankAccount?.number}</p>
        </div>
      </b>
    )
  }

  const selectTab = type => {
    switch (type) {
      case 'deposit':
        setNowSelectTab('deposit')
        getWithDep({
          variables: {
            data: {
              memberId: selectedMemberId,
              select: type,
              startDate: moment()
                .subtract(1, 'days')
                .utc()
                .set({ hour: 17, minute: 0, second: 0, millisecond: 0 })
                .toDate(),
              endDate: moment()
                .utc()
                .set({ hour: 16, minute: 59, second: 59, millisecond: 0 })
                .toDate(),
            },
          },
        })
        break
      case 'withdraw':
        setNowSelectTab('withdraw')
        getWithDep({
          variables: {
            data: {
              memberId: selectedMemberId,
              select: type,
              startDate: moment()
                .subtract(1, 'days')
                .utc()
                .set({ hour: 17, minute: 0, second: 0, millisecond: 0 })
                .toDate(),
              endDate: moment()
                .utc()
                .set({ hour: 16, minute: 59, second: 59, millisecond: 0 })
                .toDate(),
            },
          },
        })
        break
      case 'turn-over':
        setNowSelectTab('turn-over')
        getTurnOver({
          variables: {
            data: {
              memberId: selectedMemberId,
              startDate: moment()
                .subtract(1, 'days')
                .utc()
                .set({ hour: 17, minute: 0, second: 0, millisecond: 0 })
                .toDate(),
              endDate: moment()
                // .add(1, 'days')
                .utc()
                .set({ hour: 16, minute: 59, second: 59, millisecond: 0 })
                .toDate(),
              take: keepPageSize,
              skip: 0,
              count: true,
            },
          },
        })
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (typeof selectedMemberId == 'number') {
      dispatch(selectedProfileAction({ loading: true }))

      getUserProfile({
        variables: {
          data: { memberId: selectedMemberId },
        },
      })
      getWallet({
        variables: {
          data: { memberId: selectedMemberId },
        },
      })
      getWithDep({
        variables: {
          data: {
            memberId: selectedMemberId,
            select: 'deposit',
            startDate: moment()
              .subtract(1, 'days')
              .utc()
              .set({ hour: 17, minute: 0, second: 0, millisecond: 0 })
              .toDate(),
            endDate: moment()
              .utc()
              .set({ hour: 16, minute: 59, second: 59, millisecond: 0 })
              .toDate(),
          },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMemberId])

  const handleTake = (page, pageSize) => {
    const skip = page === 1 ? 0 : page * pageSize - pageSize

    setKeepPageSize(pageSize)

    if (page === 1) {
      setPageSize(0)
    } else if (page === 2) {
      setPageSize(pageSize)
    } else if (page > 2) {
      setPageSize(page * pageSize - pageSize)
    }

    const sDate = startDate
      ? moment(startDate).utc().set({ hour: 17, minute: 0, second: 0, millisecond: 0 }).toDate()
      : moment()
          .subtract(1, 'days')
          .utc()
          .set({ hour: 17, minute: 0, second: 0, millisecond: 0 })
          .toDate()

    const eDate = endDate
      ? moment(endDate)
          .add(1, 'days')
          .utc()
          .set({ hour: 11, minute: 59, second: 59, millisecond: 0 })
          .toDate()
      : moment().utc().set({ hour: 16, minute: 59, second: 59, millisecond: 0 }).toDate()

    getTurnOver({
      variables: {
        data: {
          memberId: selectedMemberId,
          startDate: sDate,
          endDate: eDate,
          take: pageSize ? pageSize : 5,
          skip: skip ? skip : 0,
          count: false,
        },
      },
    })
  }

  const searchTurnOver = () => {
    setPageSize(0)

    getTurnOver({
      variables: {
        data: {
          memberId: selectedMemberId,
          startDate: moment(startDate)
            .utc()
            .set({ hour: 17, minute: 0, second: 0, millisecond: 0 })
            .toDate(),
          endDate: moment(endDate)
            .add(1, 'days')
            .utc()
            .set({ hour: 16, minute: 59, second: 59, millisecond: 0 })
            .toDate(),
          take: 100,
          skip: 0,
          count: true,
        },
      },
    })
  }

  return (
    <div
      id="profile"
      className={`mr-2 sidebar-group ${mobileProfileSidebar ? 'mobile-open' : ''}`}
      style={{ width: '100%' }}
    >
      <div
        className={profileSidebar.profile ? 'sidebar active' : 'sidebar'}
        style={{ width: '100%' }}
      >
        <header>
          <div className="d-flex justify-content-start" style={{ width: '100%' }}>
            <figure
              className={selectedAbout?.member?.isActive ? 'avatar  avatar-md' : 'avatar avatar-md'}
            >
              {selectedAbout?.member?.profileImage && (
                <Image
                  src={selectedAbout?.member?.profileImage}
                  onError={imgErr}
                  className="rounded-circle pt-1"
                  alt="avatar"
                />
              )}
            </figure>
            <span className="username-header ml-2 w-100">{selectedAbout?.member?.displayName}</span>
          </div>

          <ul className="list-inline">
            <li className="list-inline-item">
              <a href="/#/" onClick={e => profileActions(e)} className="btn btn-light">
                <i className="ti ti-close"></i>
              </a>
            </li>
          </ul>
          <br />
        </header>

        <div style={{ marginTop: '-2rem', paddingBottom: '0.5rem', paddingLeft: '4rem' }}>
          <span className="badge badge-pill badge-dark">{selectedAbout?.member?.channel.name}</span>{' '}
          <span className="badge badge-pill badge-primary ">
            {selectedAbout?.member?.operator?.username}
          </span>
        </div>

        <div className="sidebar-body">
          <PerfectScrollbar>
            <div className="text-center">
              <FormSearchMember
                phoneNo={phoneNo}
                setPhoneNo={setPhoneNo}
                syncProfile={syncProfile}
                syncLoad={syncLoad}
                phoneNo={phoneNo}
              />

              <div className="all-center mb-2 mt-0">
                <Balance loadWallet={loadWallet} wallets={wallets} refreshWallet={refreshWallet} />
              </div>
            </div>

            {loadUser ? (
              <div className="text-center p-3">
                <Spinner animation="grow" variant="info" />
              </div>
            ) : (
              <>
                {user?.userForChat?.data ? (
                  <>
                    {userProfile(user?.userForChat?.data)}
                    <FormSearch
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                      refreshDepWith={refreshDepWith}
                      searchTurnOver={searchTurnOver}
                      nowSelectTab={nowSelectTab}
                    />

                    <Tabs defaultActiveKey="deposit" id="deposit-withdraw" onSelect={selectTab}>
                      <Tab eventKey="deposit" title="รายการฝาก" className="p-2 text-center">
                        <DepWithTable
                          loading={loadWithDev}
                          withDepData={withDepData?.depositRequest}
                          pageSize={pageSize}
                        />
                      </Tab>
                      <Tab eventKey="withdraw" title="รายการถอน" className="p-2 text-center">
                        <DepWithTable
                          loading={loadWithDev}
                          withDepData={withDepData?.withdrawRequest}
                          pageSize={pageSize}
                        />
                      </Tab>
                      <Tab eventKey="turn-over" title="TurnOver" className="p-2 text-center">
                        <TurnOver
                          loadTurnOver={loadTurnOver}
                          turnOverData={turnOverData}
                          handleTake={handleTake}
                          count={count}
                          pageSize={pageSize}
                        />
                      </Tab>
                    </Tabs>
                  </>
                ) : (
                  <div className="text-center p-3">
                    <p>ข้อมูลไม่ถูกต้อง...</p>
                    <Button variant="info" onClick={refreshProfile}>
                      โหลดใหม่อีกครั้ง
                    </Button>
                  </div>
                )}
              </>
            )}
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  )
}

export default Profile
