import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'antd'

function OperatorRead(props) {
  const { selectedChat } = useSelector(state => state)

  return (
    <Row
      gutter={[0, 0]}
      style={{ position: 'relative', top: 0, zIndex: 9, marginLeft: 0, justifyContent: 'flex-end' }}
    >
      {selectedChat?.operatorsRead &&
        selectedChat?.operatorsRead.map((e, i) => (
          <Col key={i} className="mr-2">
            <span className="who-read ">{e.username}</span>
          </Col>
        ))}
    </Row>
  )
}

export default OperatorRead
