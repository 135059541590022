import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalBody,
  TabContent,
  TabPane,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Col } from 'react-bootstrap'

import TemplateTableBody from './TemplateTableBody'
import { itemsAction } from '../../../../Store/Actions/Template'

function FormTemplate(props) {
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState('1')
  const { selectedTemplateItem, selectedChannel } = useSelector(state => state)

  const [id, setId] = useState(null)
  const [name, setName] = useState('')
  const [channel, setChannel] = useState(null)
  const [status, setStatus] = useState(true)

  const [text, setText] = useState({
    text: '',
    body: '',
    type: 'text',
  })
  const [image, setImage] = useState({
    text: '',
    body: '',
    type: 'image',
  })
  const [json, setJson] = useState({
    text: '',
    body: '',
    type: 'template',
  })

  const clearForm = () => {
    setName('')
    setText({
      text: '',
      body: '',
      type: 'text',
    })
    setImage({
      text: '',
      body: '',
      type: 'image',
    })
    setJson({
      text: '',
      body: '',
      type: 'template',
    })

    const reset = {
      key: Number,
      index: 0,
      text: '',
      type: '',
      body: '',
    }
    dispatch(itemsAction(reset))
  }

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const photoUpload = e => {
    e.preventDefault()
    const reader = new FileReader()
    const file = e.target.files[0]
    if (reader !== undefined && file !== undefined) {
      reader.onloadend = () => {
        setImage({
          text: 'IMAGE',
          type: 'image',
          body: reader.result,
        })
      }
      reader.readAsDataURL(file)
    }
  }

  const handleText = e => {
    e.preventDefault()
    if (text.body) {
      dispatch(itemsAction(text))
      setText({
        text: '',
        type: '',
        body: '',
      })
    }
  }

  const handleImage = () => {
    if (image.body) {
      dispatch(itemsAction(image))
      setText({
        text: '',
        type: '',
        body: '',
      })
    }
  }

  const handleJson = e => {
    e.preventDefault()
    if (json.body) {
      dispatch(itemsAction(json))
      setJson({
        text: '',
        type: '',
        body: {},
      })
    }
  }

  useEffect(() => {
    if (selectedTemplateItem) {
      setId(selectedTemplateItem.id)
      setName(selectedTemplateItem.name)
      setStatus(selectedTemplateItem.isActive)
      setChannel(selectedTemplateItem?.channel?.id)
    } else {
      setName('')
      setStatus('')
      setChannel(null)
    }
  }, [selectedTemplateItem])

  return (
    <Modal
      isOpen={props.formTempModalOpen && props.activeTab === '1'}
      toggle={() => props.formModalToggle('')}
      centered
      className="modal-dialog-zoom"
      size="xl"
    >
      <ModalHeader toggle={() => props.formModalToggle('')}>
        <i className="ti ti-write"></i>
        {props.formHeader} Template
      </ModalHeader>

      <ModalBody>
        <Form>
          <Form.Row>
            <Form.Group as={Col} controlId="name">
              <Form.Label>Template name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter template name"
                defaultValue={name}
                onChange={e => setName(e.target.value)}
                disabled={props.formHeader === 'View' ? true : false}
              />
              <Form.Control.Feedback type="invalid">กรุณาระบุชื่อ</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="Channel">
              <Form.Label>Channel</Form.Label>
              <Form.Control
                as="select"
                defaultValue={channel ? channel : 'Choose...'}
                onChange={e => setChannel(e.target.value)}
                disabled={props.formHeader === 'View' ? true : false}
              >
                <option>Choose...</option>

                {selectedChannel.map((ch, i) => (
                  <option value={ch.id} key={i}>
                    {ch.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="status">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                // defaultValue={true}
                defaultValue={status}
                onChange={e => setStatus(e.target.value)}
                disabled={props.formHeader === 'View' ? true : false}
              >
                <option value={true}>Active</option>
                <option value={false}>Deactive</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
        </Form>

        <Nav tabs>
          <NavItem>
            <NavLink
              href="#/"
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1')
              }}
            >
              Text
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="#/"
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggle('2')
              }}
            >
              Image
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="#/"
              className={classnames({ active: activeTab === '3' })}
              onClick={() => {
                toggle('3')
              }}
            >
              Json
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Form.Group controlId="template.text">
              <Form.Control
                required
                as="textarea"
                rows={3}
                placeholder="Enter template text"
                value={text.body}
                onChange={e => setText({ text: 'TEXT', body: e.target.value, type: 'text' })}
                disabled={props.formHeader === 'View' ? true : false}
              />
            </Form.Group>
            <Button variant="outline-success" block onClick={e => handleText(e)}>
              เพิ่มข้อความ
            </Button>
          </TabPane>

          <TabPane tabId="2">
            <input
              type="file"
              name="avatar"
              id="file"
              accept=".jpeg, .png, .jpg"
              onChange={photoUpload}
              src={image.body}
              disabled={props.formHeader === 'View' ? true : false}
            />
            <Button className="mt-3" variant="outline-success" block onClick={handleImage}>
              เพิ่มรูปภาพ
            </Button>
          </TabPane>

          <TabPane tabId="3">
            <Form.Group controlId="template.json">
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="{ Json format }"
                value={json.body}
                onChange={e => setJson({ text: 'TEMPLATE', body: e.target.value, type: 'json' })}
                disabled={props.formHeader === 'View' ? true : false}
              />
              <Form.Control.Feedback>Looks good</Form.Control.Feedback>
            </Form.Group>
            <Button variant="outline-success" block onClick={e => handleJson(e)}>
              เพิ่ม json
            </Button>
          </TabPane>
        </TabContent>

        <br />

        <TemplateTableBody
          id={id}
          name={name}
          status={status}
          formModalToggle={props.formModalToggle}
          channel={channel}
          clearForm={clearForm}
          header={props.formHeader}
          setActiveTab={setActiveTab}
        />
      </ModalBody>
    </Modal>
  )
}

export default FormTemplate
