import { gql } from 'apollo-boost'
import { useDispatch } from 'react-redux'
import { useQuery } from '@apollo/client'

import { operatorOnlineAction } from '../../Store/Actions/Operator/index'

export const OPERATORS = gql`
  query operators($where: OperatorWhereInput) {
    operators(where: $where) {
      id
      username
      status
    }
  }
`

export const Operators = () => {
  const dispatch = useDispatch()

  const { loading } = useQuery(OPERATORS, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        status: {
          equals: 'ONLINE',
        },
      },
    },
    onCompleted: data => {
      const res = data?.operators
      dispatch(operatorOnlineAction(res))
    },
  })

  return { loading }
}
