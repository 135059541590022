import React from 'react'
import { Spinner } from 'react-bootstrap'

const Loading = () => {
  return (
    <div
      className="text-center"
      style={{
        height: 'inherit',
        width: 'inherit',
        backgroundColor: 'transparent',
        paddingTop: '10px',
      }}
    >
      <p className="text-light ml-3 animate__animated animate__flash animate__infinite	infinite">
        กำลังโหลดข้อมูล...
      </p>
    </div>
  )
}

export default Loading
