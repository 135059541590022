import React from 'react'
import { render } from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'
import '@icon/themify-icons/themify-icons.css'
import './assets/scss/Index.scss'

import 'antd/dist/antd.css'
import 'sweetalert2/src/sweetalert2.scss'
import 'react-datepicker/dist/react-datepicker.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

import { Provider } from 'react-redux'
import store from './Store'

import { Auth0Provider } from '@auth0/auth0-react'
import ApolloProviderWithAuth0 from './utils/apollo'

import history from './utils/history'

const onRedirectCallback = appState => {
  history.replace({
    pathname: appState?.returnTo || window.location.pathname,
    search: '',
  })
}

const providerConfig = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  ...(process.env.REACT_APP_AUTH0_AUDIENCE
    ? { audience: process.env.REACT_APP_AUTH0_AUDIENCE }
    : null),
  redirectUri: window.location.origin,
  onRedirectCallback,
}

render(
  <Auth0Provider {...providerConfig}>
    <ApolloProviderWithAuth0>
      <Provider store={store}>
        <App />
      </Provider>
    </ApolloProviderWithAuth0>
  </Auth0Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
