import { gql } from 'apollo-boost'
import { useLazyQuery } from '@apollo/client'

export const CHANNELS = gql`
  query channels($where: ChannelWhereInput, $take: Int, $skip: Int) {
    channels (where:$where, take: $take, skip: $skip, orderBy: { updatedAt: desc }) {
      id
      key
      refCode
      name
      channelSecret
      channelAccessToken
      channelId
      basicId
      createdAt
      updatedAt
      isActive
      countMessage
      organisation {
        id
        refCode
        name
      }
    }
  }
`
export const CHANNEL_COUNT = gql`
  query channelCount($where: ChannelWhereInput){
    channelCount(where:$where)
  }
`

export const ON_CHANNEL = gql`
subscription($data: inputJoinChannel) {
  onChannel(data: $data) {
    id
    key
    refCode
    name
    channelSecret
    channelAccessToken
    channelId
    basicId
    createdAt
    updatedAt
    isActive
    countMessage
    organisation {
      id
      refCode
      name
    }
  }
}

`

export const Channels = () => {
  const [getConditionChannels, { data, loading }] = useLazyQuery(
    CHANNELS,
    {
      fetchPolicy: 'no-cache',
    },
  )
  return {
    getConditionChannels,
    data,
    loading,
  }
}

export const ConditionChannelsCount = () => {
  const [getConditionChannelsCount, { data, loading }] = useLazyQuery(
    CHANNEL_COUNT,
    {
      fetchPolicy: 'no-cache',
    },
  )
  return {
    getConditionChannelsCount,
    data,
    loading,
  }
}