import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import moment from 'moment-timezone'
import { useMutation } from '@apollo/react-hooks'

import { showListMessageAction } from '../../Store/Actions/Message'
import { CREATE_ONE_MESSAGE } from '../../api/mutation/sentMessage'
import { itemsAction } from '../../Store/Actions/Template'

import ChatTemplate from './ChatTemplate.jsx'
import set from 'date-fns/esm/fp/set/index.js'
import { togleTemplateAction } from '../../Store/Actions/Template/index'

function ChatFooter(props) {
  const dispatch = useDispatch()
  const {
    showListMessage,
    selectedMemberId,
    selectedUser,
    selectedChat,
    selectedOperator,
    togleTemplate,
  } = useSelector(state => state)

  const [isTemplate, setIsTemplate] = useState(false)
  const [inputMsg, setInputMsg] = useState('')
  const [image, setImage] = useState({
    text: '',
    body: '',
    type: 'image',
  })

  // const [text, setText] = useState({
  //   text: '',
  //   body: '',
  //   type: 'text',
  // })

  const [sent] = useMutation(CREATE_ONE_MESSAGE)

  const handleSubmit = (e, type, tempId, message) => {
    e.preventDefault()
    props.scrollToBottom()

    if (inputMsg) {
      const mess = showListMessage
      const newValue = {
        memberId: selectedMemberId,
        message: {
          text: type === 'TEMPLATE' ? message : inputMsg,
          type: type,
        },
        messageFrom: 'OPERATOR',
        messageTime: moment().utc().format(),
        messageType: type,
        operator: {
          username: selectedUser?.nickname,
        },
        __typename: 'Message',
      }

      // mess.push(Object.assign({}, newValue))
      // dispatch(showListMessageAction(mess))
      setInputMsg('')
      // console.log('condition',type, tempId, message);
      sentMessage(inputMsg, type, tempId, message)
    }

    // if (type === 'IMAGE') {
    // sentMessage(image, IMAGE, tempId, message);
    //   // setImage({
    //   //   text: '',
    //   //   body: '',
    //   //   type: 'image',
    //   // });
    // }
  }

  const sentMessage = async (text, type, tempId, message) => {
    try {
      sent({
        variables: {
          data: {
            message: {
              type: type,
              text: type === 'TEMPLATE' ? message : text,
            },
            member: { connect: { id: selectedMemberId } },
            channel: { connect: { id: selectedChat?.channel?.id } },
            operator: {
              connect: {
                id: selectedOperator?.id,
              },
            },
            messageType: type,
            messageFrom: 'OPERATOR',
            tempId: tempId,
            readMessage: true,
          },
        },
      })
    } catch (e) {
      console.log('Err-read: ', e)
    }
  }

  const handleChange = e => {
    e.preventDefault()
    setInputMsg(e.target.value)

    if (e.target.value) {
      setIsTemplate(true)
    } else {
      setIsTemplate(false)
    }
  }

  const selectTemplate = (e, temp) => {
    props.setInputMsg(temp.name)
    setIsTemplate(false)
    handleSubmit(e, 'TEMPLATE', temp.id, temp.name)
  }

  useEffect(() => {
    props.inputEl.current.focus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const handleImage = () => {
  //   if (image.body) {
  //     dispatch(itemsAction(image))
  //     setText({
  //       text: '',
  //       type: '',
  //       body: '',
  //     })
  //   }
  // }

  const photoUpload = e => {
    e.preventDefault()
    const reader = new FileReader()
    const file = e.target.files[0]
    if (reader !== undefined && file !== undefined) {
      reader.onloadend = () => {
        // setImage({
        //   type: 'IMAGE',
        //   // type: 'image',
        //   text: reader.result,
        // })
        sentMessage(reader.result, 'IMAGE', null, null)
      }
      reader.readAsDataURL(file)
      // sentMessage(image, IMAGE, tempId, message);
    }
  }

  const openTemplate = () => {
    dispatch(togleTemplateAction(!togleTemplate))
  }

  return (
    <>
      <ChatTemplate isTemplate={isTemplate} inputMsg={inputMsg} selectTemplate={selectTemplate} />
      <div className="chat-footer" style={{ width: '480px' }}>
        <form onSubmit={e => handleSubmit(e, 'TEXT', null, null)} className="ShadowBorder ">
          <input
            type="text"
            className="form-control"
            placeholder={
              selectedChat?.operatorId === selectedOperator?.id
                ? 'ระบุข้อความ.'
                : 'คุณไม่สามารถตอบกลับลูกค้าได้ กรุณากดรับงานก่อนตอบกลับข้อความหาลูกค้า!'
            }
            value={inputMsg}
            onChange={handleChange}
            ref={props.inputEl}
            disabled={selectedChat?.operatorId === selectedOperator?.id ? false : true}
          />

          <div className="form-buttons">
            <Button color="secondary" className="btn-floating" onClick={openTemplate}>
              <i className="fa fa-commenting"></i>
            </Button>

            <input
              type="file"
              className="novisible"
              id="file_img"
              accept=".jpeg, .png, .jpg"
              onChange={photoUpload}
              src={image.body}
            />
            <label
              htmlFor="file_img"
              className="btn-floating btn btn-warning"
              style={{ color: '#fff' }}
            >
              <i className="fa fa-picture-o"></i>
            </label>

            <Button color="info" className="btn-floating">
              <i className="fa fa-send"></i>
            </Button>
          </div>
        </form>
      </div>
    </>
  )
}

export default ChatFooter
