import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody, Tooltip } from 'reactstrap'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useMutation } from '@apollo/react-hooks'

import { noteAction } from '../../Store/Actions/Note'

import { UPDATE_NOTE } from '../../api/mutation/noted'

import { Badge } from 'reactstrap'

function VideoCallModal() {
  const dispatch = useDispatch()
  const { selectedNote } = useSelector(state => state)

  const [modal, setModal] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const [colorCode, setColorCode] = useState('')
  const [topic, setTopic] = useState('')
  const [detail, setDetail] = useState('')

  useEffect(() => {
    setTopic(selectedNote.topic)
    setDetail(selectedNote.detail)
    setColorCode(selectedNote.colorCode)

    return () => {
      setTopic('')
      setDetail('')
      setColorCode('')
    }
  }, [selectedNote])

  const [postNote, { loading: loadingPostNote }] = useMutation(UPDATE_NOTE, {
    onCompleted: data => {
      const res = data.updateMemberNote
      dispatch(noteAction(res))
      setModal(false)
    },
  })

  const addNoted = () => {
    postNote({
      variables: {
        data: {
          id: selectedNote.id,
          topic: topic,
          detail: detail,
          colorCode: colorCode,
        },
      },
    })
  }

  const handleCheck = e => {
    setColorCode(e.target.value)
  }

  return (
    <div>
      {/* <Button
        className="btn btn-info"
        size="sm"
        onClick={() => setModal(!modal)}
        id="Tooltip-Video-Call"
      >
        โน๊ต
      </Button> */}
      <Badge
        color="info"
        className="setButtomProcessMsg"
        pill
        onClick={() => setModal(!modal)}
        id="Tooltip-Video-Call"
      >
        โน๊ต
      </Badge>
      <Tooltip
        placement="bottom"
        isOpen={tooltipOpen}
        target={'Tooltip-Video-Call'}
        toggle={() => setTooltipOpen(!tooltipOpen)}
      >
        Note
      </Tooltip>

      <Modal isOpen={modal} toggle={() => setModal(!modal)} centered size="lg">
        <ModalBody>
          <Form>
            <Form.Group as={Row}>
              <Form.Label column md={12}>
                <b>Status:</b>
              </Form.Label>
              <Col className="d-flex" md={2} lg={2}>
                <i className="fa fa-circle text-success" aria-hidden="true" style={iStyle} />
                <Form.Check
                  type="radio"
                  label="Normal"
                  name="status"
                  id="status1"
                  value="success"
                  checked={colorCode === 'success' ? true : false}
                  onChange={handleCheck}
                />
              </Col>
              <Col className="d-flex" md={2} lg={2}>
                <i className="fa fa-circle text-warning" aria-hidden="true" style={iStyle} />
                <Form.Check
                  type="radio"
                  label="Warning"
                  name="status"
                  id="status2"
                  value="warning"
                  checked={colorCode === 'warning' ? true : false}
                  onChange={handleCheck}
                />
              </Col>
              <Col className="d-flex" md={2} lg={2}>
                <i className="fa fa-circle text-danger" aria-hidden="true" style={iStyle} />
                <Form.Check
                  type="radio"
                  label="Danger"
                  name="status"
                  id="status3"
                  value="danger"
                  checked={colorCode === 'danger' ? true : false}
                  onChange={handleCheck}
                />
              </Col>
            </Form.Group>

            <Form.Group controlId="topic">
              <Form.Label>
                <b>Topic:</b>
              </Form.Label>
              <Form.Control
                type="text"
                defaultValue={topic}
                onChange={e => setTopic(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="detail">
              <Form.Label>
                <b>Detail:</b>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                defaultValue={detail}
                onChange={e => setDetail(e.target.value)}
              />
            </Form.Group>

            <Button variant="info" block onClick={addNoted}>
              {loadingPostNote ? 'Now loading...' : 'Save'}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  )
}

const iStyle = {
  marginRight: '-15px',
  fontSize: '22px',
  marginTop: '1px !important',
}

export default VideoCallModal
