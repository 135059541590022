import React from 'react'
import { Spinner, Badge } from 'react-bootstrap'

function Balance(props) {
  const userWallet = () => {
    return (
      <>
        <div
          className="d-flex justify-content-between ml-3 mr-3"
          style={{ height: '24px', marginTop: '-8px' ,fontSize:'18px'}}
        >
          <b>เครดิต</b>

          {props.loadWallet ? (
            <Spinner animation="border" variant="info" size="sm" />
          ) : (
            <div>
              {/* <Badge
                variant="info"
                style={{
                  fontSize: '0.8rem',
                }}
              >
                {Number(props.wallets?.userWalletForChat?.data).toLocaleString()}
              </Badge> */}
              <span style={{color:'#17a2b8'}}>
                <b>{Number(props.wallets?.userWalletForChat?.data).toLocaleString()}</b>
              </span>
              {/* #17a2b8 */}
              <b> บาท</b>
              <i
                className="ti ti-reload ml-2"
                style={{ cursor: 'pointer', fontWeight: 'bolder' }}
                onClick={e => props.refreshWallet(e)}
              ></i>
            </div>
          )}

          {/* <div><hr /></div> */}
        </div>
      </>
    )
  }

  return <>{userWallet()}</>
}

export default Balance
