import styled from 'styled-components'
import { MEDIA_MEDIUM_UP } from './theme'

export const BtnTab = styled.button`
  background-color: ${props => props.bg && props.bg}; /* Green */
  color: ${props => props.color && props.color}; /* Green */
  position: relative;
  border: none;
  padding: 4px;
  width: 100%;
  text-align: center;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;

  :after {
    content: '';
    background: #90ee90;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px !important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s;
  }

  :active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
  }

  /* @media ${MEDIA_MEDIUM_UP} {
  } */
`
