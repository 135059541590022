export const showListMessageAction = message => ({
  type: 'SHOW_LIST_MESSAGE',
  message,
})

export const lastMessageAction = message => ({
  type: 'LAST_MESSAGE',
  message,
})

export const oldRead = id => ({
  type: 'OLD_READ',
  id,
})

export const checkHandle = status => ({
  type: 'CHECK_HANDLE',
  status,
})

export const checkScrollAction = status => ({
  type: 'CHECK_SCROLL',
  status,
})
