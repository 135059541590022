import React, { useState,useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { Table, Tag } from 'antd'
import moment from 'moment'
import {Organisations,ConditionOrganisationCount} from '../../../../api/query/organisations'

function Organisation(props) {
  const { selectedOrganisation,selectedOperator } = useSelector(state => state)
  const { getConditionOrganisation, data: ConditionOrganisation,loading: loadingConditionOrganisation } = Organisations();
  const { getConditionOrganisationCount, data: ConditionOrganisationsCount,loading: loadingConditionOrganisationCount } = ConditionOrganisationCount();

  const [pageDetail, setPage] = useState({
    pageNumber: 1,
    totalPage: 0,
  })

  const [state] = useState({
    top: 'bottomCenter',
    bottom: 'bottomCenter',
  })

  const columns = [
    // {
    //   title: 'No.',
    //   key: 'key',
    //   align: 'center',
    //   render: (value, row, index) => <span>{index + 1}</span>,
    // },
    {
      title: 'No.',
      key: 'key',
      align: 'center',
      render: (value, row, index) => (
        <span key={index}>
          {(pageDetail.pageNumber == 1
            ? pageDetail.pageNumber * 1 - 1
            : (pageDetail.pageNumber * 1 - 1) * 10) +
            (index + 1)}
        </span>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      render: (value, record) => {
        return <Tag color={value ? 'green' : 'yellow'}>{value ? 'Active' : 'Deactive'}</Tag>
      },
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Deactive',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.isActive === value,
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      responsive: ['sm'],
      align: 'center',
      sortDirections: ['descend', 'ascend'],
      // sorter: (a, b) => moment(a.createdAt).diff(moment(b.createdAt)),
      render: createdAt => {
        return <span>{moment(createdAt).format('DD/MM/YYYY')}</span>
      },
    },
    {
      title: 'Last update',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      responsive: ['sm'],
      align: 'center',
      sortDirections: ['descend', 'ascend'],
      // sorter: (a, b) => moment(a.updatedAt).diff(moment(b.updatedAt)),
      render: updatedAt => {
        return <span>{moment(updatedAt).format('DD/MM/YYYY')}</span>
      },
    },
    {
      title: 'Actions',
      dataIndex: 'acrions',
      align: 'center',
      render: (record, index) => {
        return (
          <Row noGutters>
            <Col onClick={() => props.formModalToggle('View', index, 'organization')}>
              <i className="ti ti-eye pointer"></i>
            </Col>
            {/* <Col onClick={() => props.formModalToggle('Edit', index, 'organization')}>
              <i className="ti ti-pencil-alt pointer"></i>
            </Col> */}
          </Row>
        )
      },
    },
  ]

  useEffect(() => {
    if (props.activeTab == '4') {
      // console.log('activeTab',props,selectedOperator);
      getData();
  
    }
  }, [props.activeTab])

  const getData = () =>{

    getConditionOrganisation({
      variables : {
        where : {
          refCode: {equals: ((selectedOperator?.role == 'SUPERADMIN') ? null : selectedOperator?.contact.organisationRefCode)}
          // refCode: {equals: selectedOperator?.contact.organisationRefCode}
        },
        take: 10,
        skip:pageDetail.pageNumber == 1
            ? pageDetail.pageNumber * 1 - 1
            : (pageDetail.pageNumber * 1 - 1) * 10,
      }

    });

    getConditionOrganisationCount({
      variables : {
        where : {
          refCode: {equals: ((selectedOperator?.role == 'SUPERADMIN') ? null : selectedOperator?.contact.organisationRefCode)}
        },
      }
      
      

    });

  }
  const handleTableChange = e => {
    var set = pageDetail;
    set.pageNumber = e.current;
    // set.totalPage =((props.type == 'LogActivityDetail') ? activitylogcount?.operatorActivityCount?.total : assignlog?.operatorMembers.length);
    setPage(set);
    getData();
    

      
  }

  return (
    <Table
      columns={columns}
      dataSource={ConditionOrganisation?.organisations}
      bordered
      loading={loadingConditionOrganisation}
      pagination={{ position: [state.top, state.bottom], pageSize: 10,current: pageDetail.pageNumber , total: ConditionOrganisationsCount?.organisationCount.total}}
      onChange={e => handleTableChange(e)}
    />
  )
}

export default Organisation
