import React, { useEffect, useState, useCallback } from 'react'
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'

import { CREATE_ONE_MESSAGE } from '../../api/mutation/sentMessage'
import { GetTypeTemplates } from '../../api/cli/Templates'
import { togleTemplateAction } from '../../Store/Actions/Template/index'

const FavoriteTemplate = () => {
  const dispatch = useDispatch()
  const { togleTemplate, selectedMemberId, selectedChat, selectedOperator } = useSelector(
    state => state,
  )
  const { getTypeTemplate, data, loading } = GetTypeTemplates()
  const [sent] = useMutation(CREATE_ONE_MESSAGE)

  const submitTemplate = (tempId, name) => {
    if (!selectedChat?.operatorId) {
      Swal.fire('คุณยังไม่ได้กดรับงาน กรุณากดรับงานก่อนส่งข้อความ')
    } else if (selectedChat?.operatorId !== selectedOperator?.id) {
      Swal.fire('คุณไม่สามารถส่งข้อความได้ เนื่องจากมีแอดมินท่านอื่นรับงานไปแล้ว')
    } else {
      try {
        sent({
          variables: {
            data: {
              message: {
                type: 'TEMPLATE',
                text: name,
              },
              member: { connect: { id: selectedMemberId } },
              channel: { connect: { id: selectedChat?.channel?.id } },
              operator: {
                connect: {
                  id: selectedOperator?.id,
                },
              },
              messageType: 'TEMPLATE',
              messageFrom: 'OPERATOR',
              tempId: tempId,
              readMessage: true,
            },
          },
        })
      } catch (e) {
        console.log('Err-read: ', e)
      }
    }
  }

  useEffect(() => {
    getTypeTemplate({
      variables: {
        where: {
          channelRefCode: {
            equals: selectedChat?.channelRefCode,
          },
          type: {
            equals: 'MEMBER',
          },
        },
      },
    })
  }, [selectedChat])

  return (
    <div className={togleTemplate ? 'chat temp-on' : 'temp-off'} id="chat-view">
      <div className="nav-h ShadowBorder">
        <div className="d-flex justify-content-between">
          <h1 className="fs1-2 mb-1">ข้อความอัตโนมัติ</h1>
          <i
            className="fa fa-times pr-2 cursor"
            onClick={() => dispatch(togleTemplateAction(false))}
            aria-hidden="true"
          ></i>
        </div>
        <hr />

        {loading ? (
          <h5 className="text-center text-light mt-3 animate__animated animate__flash animate__infinite	infinite">
            กำลังโหลดข้อมูล...
          </h5>
        ) : (
          <div>
            {data && data?.templates?.length > 0 ? (
              data?.templates.map((e, i) => (
                <div className="temp-box" key={i} onClick={() => submitTemplate(e.id, e.name)}>
                  <p>{e.name}</p>
                </div>
              ))
            ) : (
              <h5 className="text-light mt-3 text-dark text-center">
                ยังไม่มีตั้งค่าข้อความอัตโนมัติ...
              </h5>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default FavoriteTemplate
