import { gql } from 'apollo-boost'

export const CREATE_ONE_CHANNEL = gql`
  mutation createOneChannel($data: ChannelCreateInput!) {
    createOneChannel(data: $data) {
      id
      name
    }
  }
`

export const UPDATE_ONE_CHANNEL = gql`
  mutation updateOneChannel($where: ChannelWhereUniqueInput!, $data: ChannelUpdateInput!) {
    updateOneChannel(where: $where, data: $data) {
      id
      name
    }
  }
`
