import styled from 'styled-components'
import { MEDIA_MEDIUM_UP } from './theme'

export const CTemplate = styled.div`
  position: absolute;
  right: 0;
  bottom: 0px;
  background-color: transparent;
  text-align: -webkit-right;

  @media ${MEDIA_MEDIUM_UP} {
    position: absolute;
    bottom: 0px;
    right: 0;
    width: 100%;
    max-width: 840px;
    background-color: transparent;
    text-align: -webkit-left;
  }
`
export const CTempWord = styled.div`
  width: 100%;
  text-align: center;
  background-color: rgb(103 103 103);
  padding: 8px 16px;
  margin: 8px 0px;
  color: #fff;
  border-radius: 8px;
  filter: drop-shadow(0px 2px 1px black);
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  text-overflow: ellipsis;
  font-size: small;

  @media ${MEDIA_MEDIUM_UP} {
    width: fit-content;
    text-align: center;
    background-color: rgb(103 103 103);
    padding: 8px 16px;
    margin: 4px 0px;
    color: #fff;
    border-radius: 8px;
    filter: drop-shadow(0px 2px 1px black);
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    text-overflow: ellipsis;
    font-size: small;

    :focus {
      color: #fff;
      outline: none;
    }

    :hover {
      border-color: green;
      background-color: green;
    }

    :active {
      border-color: red;
      background-color: red;
    }
  }
`

export const BoxChat = styled.div`
  font-size: 1rem;
  background-color: #a5a5a5;
  padding: 8px;
  border-radius: 4px;
  width: fit-content;
  max-width: 240px;
  text-align: left;

  @media ${MEDIA_MEDIUM_UP} {
    font-size: 1rem;
    background-color: #a5a5a5;
    padding: 8px;
    border-radius: 4px;
    width: fit-content;
    max-width: 440px;
    text-align: left;
  }
`
export const TxtChat = styled.span`
  width: 11em;
  word-wrap: break-word;

  @media ${MEDIA_MEDIUM_UP} {
    width: 11em;
    word-wrap: break-word;
  }
`
