import React, { useState,useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Row, Col } from 'antd'
import { Table, Tag } from 'antd'
import { useMutation } from '@apollo/client'
import Swal from 'sweetalert2'

import { DELETE_TEMPLATE } from '../../../../api/mutation/deleteTemplate'
import { selectedTemplateAction } from '../../../../Store/Actions/Template'

import { GetTemplates } from '../../../../api/cli/Templates'

import { Templates,ConditionTemplatesCount } from '../../../../api/query/templates'

function TemplateTable(props) {
  const dispatch = useDispatch()
  const { selectedTemplate,selectedOperator } = useSelector(state => state)
  const { loading } = GetTemplates()
  const [state] = useState({
    top: 'bottomCenter',
    bottom: 'bottomCenter',
  })

  const { getConditionTemplates, data: Template, loading: loadingConditionTemplate } = Templates();
  const { getConditionTemplatesCount, data: ConditionTemplates, loading: loadingConditionTemplatesCount } = ConditionTemplatesCount();
  const [pageDetail, setPage] = useState({
    pageNumber: 1,
    totalPage: 0,
  })

  const [delTemplate] = useMutation(DELETE_TEMPLATE, {
    onCompleted: data => {
      const res = data.deleteOneTemplate
      const val = selectedTemplate.filter(e => e.id !== res.id)
      dispatch(selectedTemplateAction(val))
      Swal.fire('Delete is done!', '', 'success')
    },
    onError: err => {
      Swal.fire('Cannot delete is template!' + err, '', 'error')
    },
  })

  const deleteTemp = e => {
    // e.preventDefault()
    Swal.fire({
      icon: 'question',
      title: 'Do you want to delete the changes?',
      showCancelButton: true,
      confirmButtonText: `Delete`,
    }).then(result => {
      if (result.isConfirmed) {
        delTemplate({
          variables: {
            where: {
              id: e.id,
            },
          },
        })
      }
    })
  }

  const columns = [
    // {
    //   title: 'No.',
    //   key: 'key',
    //   render: (value, row, index) => <span>{index + 1}</span>,
    //   align: 'center',
    // },
    {
      title: 'No.',
      key: 'key',
      align: 'center',
      render: (value, row, index) => (
        <span>
          {(pageDetail.pageNumber == 1
            ? pageDetail.pageNumber * 1 - 1
            : (pageDetail.pageNumber * 1 - 1) * 10) +
            (index + 1)}
        </span>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Channel',
      dataIndex: 'channel',
      key: 'channel',
      align: 'center',
      render: channel => <span> {channel.name}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      render: isActive => {
        let color = isActive ? 'green' : 'yellow'
        let status = isActive ? 'Active' : 'Deactive'
        return <Tag color={color}>{status}</Tag>
      },
    },
    {
      title: 'Last update',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      responsive: ['sm'],
      align: 'center',
      defaultSortOrder: 'descend',

      sorter: (a, b) => moment(b.updatedAt).unix() - moment(a.updatedAt).unix(),
      render: updatedAt => {
        return <span>{moment(updatedAt).format('DD/MM/YYYY')}</span>
      },
    },
    {
      title: 'Actions',
      dataIndex: 'acrions',
      align: 'center',
      width: '120px',
      render: (record, index) => {
        return (
          <Row gutter={[16, 0]} justify="center">
            <Col onClick={() => props.formModalToggle('View', index, 'template')}>
              <i className="ti ti-eye pointer"></i>
            </Col>
            <Col onClick={() => props.formModalToggle('Edit', index, 'template')}>
              <i className="ti ti-pencil-alt pointer"></i>
            </Col>
            <Col onClick={() => deleteTemp(index)}>
              <i className="ti ti-trash pointer"></i>
            </Col>
          </Row>
        )
      },
    },
  ]

  useEffect(() => {
    if (props.activeTab == '1') {
      // console.log('activeTab',props,selectedOperator);
      getData();
  
    }
  }, [props.activeTab])

  const getData = () =>{

    getConditionTemplates({
      variables : {
        where : {
          channel : {
            // refCode: {equals: ((selectedOperator?.role == 'SUPERADMIN') ? null : selectedOperator?.contact.organisationRefCode)}
            ...((selectedOperator?.role != 'SUPERADMIN') && {organisationRefCode: {equals: selectedOperator?.contact.organisationRefCode}})
            // organisationRefCode: {equals: selectedOperator?.contact.organisationRefCode}
          }

        },
        take: 10,
        skip:pageDetail.pageNumber == 1
            ? pageDetail.pageNumber * 1 - 1
            : (pageDetail.pageNumber * 1 - 1) * 10,
      }

    });
    
    getConditionTemplatesCount({
      variables : {
        where : {
          channel : {
            ...((selectedOperator?.role != 'SUPERADMIN') && {organisationRefCode: {equals: selectedOperator?.contact.organisationRefCode}})
          }
        },
      }
      
      

    });

  }
  const handleTableChange = e => {
    var set = pageDetail;
    set.pageNumber = e.current;
    // set.totalPage =((props.type == 'LogActivityDetail') ? activitylogcount?.operatorActivityCount?.total : assignlog?.operatorMembers.length);
    setPage(set);
    getData();
    
// console.log('Operators?.operators',Operators?.operators);
      
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={Template?.templates}
        bordered
        loading={loadingConditionTemplate}
        pagination={{ position: [state.top, state.bottom], pageSize: 10 ,current: pageDetail.pageNumber , total: ConditionTemplates?.templateCount?.total}}
        rowKey='id'
        onChange={e => handleTableChange(e)}
      />
    </>
  )
}

export default TemplateTable
