import { gql } from 'apollo-boost'

export const MEMBERS = gql`
  query members($where: MemberWhereInput) {
    members(where: $where,
    orderBy: { updatedAt: desc }
    ) {
      id
      assignType
      case
      displayName
      operatorId
      unreadChatMessage
      operator
      operatorsRead
      case
      contact {
        id
        username
        firstName
        lastName
        mobile
        lineID
        email
        bankAccId
        organisation {
          id
          name
          refCode
        }
      }
      note {
        topic
        detail
        colorCode
        createdAt
        updatedAt
        isActive
      }
      channelRefCode
      lineUserId
      profileImage
      contactId
      lastChatMessage
      lastChatTime
      unreadChatMessage
      channel {
        name
      }
      groupRefCode
      createdAt
      updatedAt
      botStatus
      isActive
      block
    }
  }
`

//WSS OO_MEMBER
export const ON_MEMBER = gql`
  subscription onMember($data: inputJoinMember) {
    onMember(data: $data) {
      id
      assignType
      displayName
      operatorId
      operator
      operatorsRead
      messages(orderBy: { messageTime: desc }, take: 1) {
        id
        message
        message
        messageFrom
        messageType
        messageTime
      }
      contact {
        id
        username
        firstName
        lastName
        mobile
        lineID
        email
        bankAccId
        organisation {
          id
          name
          refCode
        }
      }
      note {
        topic
        detail
        colorCode
        createdAt
        updatedAt
        isActive
      }
      channelRefCode
      lineUserId
      profileImage
      contactId
      lastChatMessage
      lastChatTime
      unreadChatMessage
      channel {
        name
      }
      groupRefCode
      createdAt
      updatedAt
      botStatus
      isActive
      block
    }
  }
`
//WSS OO_MEMBER_ME
export const ON_MEMBER_ME = gql`
  subscription onMemberMe($data: inputJoinMemberMe) {
    onMemberMe(data: $data) {
      id
      assignType
      displayName
      operatorId
      operator
      operatorsRead
      messages(orderBy: { messageTime: desc }, take: 1) {
        id
        message
        message
        messageFrom
        messageType
        messageTime
      }
      contact {
        id
        username
        firstName
        lastName
        mobile
        lineID
        email
        bankAccId
        organisation {
          id
          name
          refCode
        }
      }
      note {
        topic
        detail
        colorCode
        createdAt
        updatedAt
        isActive
      }
      channelRefCode
      lineUserId
      profileImage
      contactId
      lastChatMessage
      lastChatTime
      unreadChatMessage
      channel {
        name
      }
      groupRefCode
      createdAt
      updatedAt
      botStatus
      isActive
      block
    }
  }
`

export const MY_MEMBER = gql`
  query ($where: MyMemberWhereInput) {
    myMembers(where: $where) {
      __typename
      ... on MyMemberOutputSuccess {
        members {
          id
          displayName
          operatorId
          operator
          operatorsRead
          contact {
            id
            username
            firstName
            lastName
            mobile
            lineID
            email
            bankAccId
            organisation {
              id
              name
              refCode
            }
          }
          note {
            topic
            detail
            colorCode
            createdAt
            updatedAt
            isActive
          }
          channelRefCode
          lineUserId
          profileImage
          contactId
          lastChatMessage
          lastChatTime
          unreadChatMessage
          channel {
            name
          }
          groupRefCode
          createdAt
          updatedAt
          botStatus
          isActive
          block
        }
      }
      ... on MyMemberOutputError {
        message
      }
    }
  }
`
