import { gql } from 'apollo-boost'

export const READ_MESSAGE = gql`
  mutation readMessage($data: readMessageInput) {
    readMessage(data: $data)
  }
`

export const UNREAD = gql`
  mutation operatorUnread($data: operatorUnread){
    operatorUnread(data: $data)
  }
`
