import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Button, Col } from 'react-bootstrap'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'

import { ORGANISATIONS, Organisations } from '../../../../api/query/organisations'
import { CREATE_ONE_CHANNEL, UPDATE_ONE_CHANNEL } from '../../../../api/mutation/createOneChannel'

import { organisationListAction } from '../../../../Store/Actions/Organisation'

function FormChannel(props) {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [validated, setValidated] = useState(false)
  const [organization, setOrganization] = useState(null)
  const [channelId, setChannelId] = useState(null)
  const [botBasicId, setBotBasicId] = useState('')
  const [channelName, setChannelName] = useState('')
  const [channelSecret, setChannelSecret] = useState('')
  const [channelAccessToken, setChannelAccessToken] = useState('')

  const { selectedOrganisation, editChannelReducer, selectedOperator } = useSelector(state => state)
  const { getConditionOrganisation, data: ConditionOrganisation } = Organisations()
  useEffect(() => {
    if (editChannelReducer) {
      const res = editChannelReducer
      // console.log('res.organisation.id',res.organisation.id,props.formHeader);
      setOrganization(res.organisation.id)
      setChannelId(res.channelId)
      setBotBasicId(res.basicId)
      setChannelName(res.name)
      setChannelSecret(res.channelSecret)
      setChannelAccessToken(res.channelAccessToken)
    }
    return () => {
      setOrganization(null)
      setChannelId(null)
      setBotBasicId('')
      setChannelName('')
      setChannelSecret('')
      setChannelAccessToken('')
    }
  }, [editChannelReducer])

  useQuery(ORGANISATIONS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      const res = data.organisations
      dispatch(organisationListAction(res))
    },
  })

  const [createChannel] = useMutation(CREATE_ONE_CHANNEL, {
    onCompleted: data => {
      const res = data.createOneChannel
      setIsLoading(false)
      Swal.fire({
        icon: 'success',
        title: 'Create channel ' + res.name + ' done.',
      })
      props.formModalToggle()
    },
  })

  const handleSubmit = event => {
    setIsLoading(true)
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    } else {
      event.preventDefault()
      event.stopPropagation()
      setValidated(true)
      const data = {
        name: channelName,
        channelId: Number(channelId),
        channelSecret: channelSecret,
        channelAccessToken: channelAccessToken,
        basicId: botBasicId,
        isActive: true,
        organisation: {
          connect: {
            id: Number(organization),
          },
        },
      }
      try {
        createChannel({
          variables: {
            data: data,
          },
        })
      } catch (error) {
        // console.log('error: ', error)
      }
    }
  }

  const [updateChannel] = useMutation(UPDATE_ONE_CHANNEL, {
    onCompleted: data => {
      setIsLoading(false)
      Swal.fire({
        icon: 'success',
        title: 'Update channel done.',
      })
      props.formModalToggle()
    },
  })

  const handleUpdate = event => {
    event.preventDefault()
    event.stopPropagation()
    try {
      updateChannel({
        variables: {
          where: { id: editChannelReducer.id },
          data: {
            refCode: {
              set: editChannelReducer.refCode,
            },
            name: {
              set: channelName,
            },
            channelId: {
              set: Number(channelId),
            },
            channelSecret: {
              set: channelSecret,
            },
            channelAccessToken: {
              set: channelAccessToken,
            },
            basicId: {
              set: botBasicId,
            },
            organisation: { connect: { id: Number(organization) } },
            isActive: {
              set: true,
            },
          },
        },
      })
    } catch (err) {
      // console.log('Err: ', err)
    }
  }

  useEffect(() => {
    if (props.formTempModalOpen && props.activeTab === '2') {
      getConditionOrganisation({
        variables: {
          where: {
            ...(selectedOperator?.role != 'SUPERADMIN' && {
              refCode: { equals: selectedOperator?.contact.organisationRefCode },
            }),
          },
        },
      })
    }
  }, [props.formTempModalOpen && props.activeTab === '2'])

  return (
    <Modal
      isOpen={props.formTempModalOpen && props.activeTab === '2'}
      toggle={() => props.formModalToggle('')}
      centered
      className="modal-dialog-zoom"
      size="xl"
    >
      <ModalHeader toggle={() => props.formModalToggle('')}>
        <i className="ti ti-write"></i>
        {props.formHeader} Channel
      </ModalHeader>

      <ModalBody>
        <Form
          noValidate
          validated={validated}
          onSubmit={!editChannelReducer ? handleSubmit : handleUpdate}
        >
          <Form.Row>
            {editChannelReducer?.refCode && (
              <Form.Group as={Col} lg={12} controlId="refCode">
                <Form.Label>RefCode</Form.Label>
                <Form.Control
                  required
                  type="text"
                  disabled
                  defaultValue={editChannelReducer?.refCode}
                />
              </Form.Group>
            )}

            <Form.Group as={Col} lg={3} controlId="organization">
              <Form.Label>Organization</Form.Label>
              <Form.Control
                as="select"
                value={organization ? organization : 'Choose...'}
                onChange={e => setOrganization(e.target.value)}
                disabled={props.formHeader === 'View' || props.formHeader === 'Edit' ? true : false}
              >
                <option
                  style={{ display: selectedOperator?.role == 'SUPERADMIN' ? 'block' : 'none' }}
                >
                  Choose...
                </option>
                {ConditionOrganisation?.organisations.map((ch, i) => (
                  <option value={ch.id} key={i}>
                    {ch.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} lg={3} controlId="channelId">
              <Form.Label>Channel Id</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Enter channel id"
                defaultValue={channelId}
                onChange={e => setChannelId(e.target.value)}
                disabled={props.formHeader === 'View' ? true : false}
              />
              <Form.Control.Feedback type="invalid">กรุณาระบุ Channel id</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg={3} controlId="basicId">
              <Form.Label>Bot Basic Id</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter basic id"
                defaultValue={botBasicId}
                onChange={e => setBotBasicId(e.target.value)}
                disabled={props.formHeader === 'View' ? true : false}
              />
              <Form.Control.Feedback type="invalid">กรุณาระบุ Bot basic id</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={3} controlId="channelName">
              <Form.Label>Channel name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter channel name"
                defaultValue={channelName}
                onChange={e => setChannelName(e.target.value)}
                disabled={props.formHeader === 'View' ? true : false}
              />
              <Form.Control.Feedback type="invalid">กรุณาระบุ channel name</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="channelSecret">
              <Form.Label>Channel Secret</Form.Label>
              <Form.Control
                required
                type="text"
                as="textarea"
                placeholder="Enter channel secret"
                defaultValue={channelSecret}
                onChange={e => setChannelSecret(e.target.value)}
                disabled={props.formHeader === 'View' ? true : false}
              />
              <Form.Control.Feedback type="invalid">กรุณาระบุ channel secret</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="token">
              <Form.Label>Channel Access Token</Form.Label>
              <Form.Control
                required
                type="text"
                as="textarea"
                placeholder="Enter channel access token"
                defaultValue={channelAccessToken}
                onChange={e => setChannelAccessToken(e.target.value)}
                disabled={props.formHeader === 'View' ? true : false}
              />
              <Form.Control.Feedback type="invalid">
                กรุณาระบุชื่อ Channel Access Token
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Button
            variant={props.formHeader === 'View' ? 'secondary' : 'primary'}
            type="submit"
            block
            disabled={props.formHeader === 'View' ? true : false}
          >
            {isLoading ? 'กำลังบันทึกข้อมูล...' : 'บันทึก Channel'}
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default FormChannel
