import styled from 'styled-components'
import { MEDIA_MEDIUM_UP } from './theme'

export const MyModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: -webkit-right;
  z-index: 999;

  /* @media ${MEDIA_MEDIUM_UP} {
    position: fixed;
    bottom: 0px;
    right: 0;
    width: 100%;
    max-width: 840px;
    background-color: transparent;
    text-align: -webkit-left;
  } */
`
